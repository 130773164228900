import api from "@modules/api/api";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import purchaseQueryKeys from "../purchaseQueryKeys";
import {
  VehicleFinancePricingRequest,
  VehicleFinancePricingResponse,
} from "../types/VehicleFinancePricingBreakdown";

const getPricingBreakdown = async (
  query: VehicleFinancePricingRequest
): Promise<VehicleFinancePricingResponse> => {
  const params = new URLSearchParams({
    sellingPrice: query.sellingPrice.toString(),
    paymentFrequencyId: query.paymentFrequencyId.toString(),
    termInMonths: query.termInMonths.toString(),
    vehicleYear: query.vehicleYear.toString(),
  });

  if (query.cashDownAmount != null) {
    params.append("cashDownAmount", query.cashDownAmount.toString());
  }

  if (query.buyerProvince) {
    params.append("buyerProvince", query.buyerProvince);
  }

  return api.get<VehicleFinancePricingResponse>(
    `api/purchases/finance-pricing-breakdown?${params.toString()}`
  );
};

export default function useVehicleFinancePricingBreakdown(
  query: VehicleFinancePricingRequest,
  enabled = true
) {
  const { data, isError, isLoading } = useQuery({
    queryKey: purchaseQueryKeys.financePricingBreakdown(query),
    queryFn: () => getPricingBreakdown(query),
    enabled: enabled && query.sellingPrice > 0,
    placeholderData: keepPreviousData,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  return {
    pricingBreakdown: data ?? null,
    isLoading,
    isError,
  };
}
