import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { ArrowRightIcon } from "@common/components/icons";
import { cleanParagraphTags } from "@common/helpers/cleanHtml";
import CloudflareImagesImage from "@common/components/CloudflareImagesImage";
import { FeatureBannerLayout } from "../types/FeatureBannerLayout";
import CmsButton from "./CmsButton";
import ActionButton from "../enum/ActionButtonEnum";
import CmsButtonModalContainer from "./ButtonModalContainer";
import AddOpacityToBackground from "../helpers/addOpacityToBackground";

type Props = {
  layout: FeatureBannerLayout;
};

const FeatureBanner = ({ layout }: Props) => {
  const [buttonModalState, setButtonModalState] = useState<{
    show: boolean;
    actionType: ActionButton;
  }>({ show: false, actionType: ActionButton.UnAssigned });

  const [isInView, setIsInView] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(entry.isIntersecting);
          observer.unobserve(entry.target);
        }
      },
      {
        threshold: 0.9, // 90% of the element is visible
      }
    );

    if (ref.current && !isInView) {
      observer.observe(ref.current);
    }

    const currentRef = ref.current;
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [isInView, ref]);

  const backgroundStyle = () => {
    if (layout.container.background.backgroundOption === "Solid") {
      return {
        backgroundColor: layout.container.background.color ?? "#000000",
      };
    }
    if (layout.container.background.backgroundOption === "Transparent") {
      return {
        backgroundColor: AddOpacityToBackground(
          layout.container.background.color ?? "#000000",
          layout.container.background.opacity ?? 0.55
        ),
      };
    }
    return {};
  };

  return (
    <div
      id="cms-feature-banner"
      ref={ref}
      className={classNames(
        "relative bg-white overflow-visible no-scrollbar transition-all duration-500 delay-150 mx-auto",
        {
          "scale-90 max-w-7xl": !isInView,
          "scale-100 max-w-full": isInView,
        }
      )}
    >
      <CloudflareImagesImage
        src={layout.backgroundImage.sourceUrl}
        alt={layout.backgroundImage.altText}
        fill
        className={classNames(
          "absolute inset-0 w-full h-full object-cover bg-center",
          {
            "rounded-xl": !isInView,
          }
        )}
      />
      <div
        className={classNames(
          "flex flex-row py-16 h-full justify-center px-2 md:px-36 text-center sm:text-left transition-all duration-500 delay-500",
          {
            "transform translate-y-0": isInView,
            "transform translate-y-10 opacity-0": !isInView,
            "md:justify-start": layout.container.position === "Left",
            "md:justify-end": layout.container.position === "Right",
          }
        )}
      >
        <div
          className="flex flex-col p-8 h-full w-fit max-w-sm rounded-xl"
          style={backgroundStyle()}
        >
          {layout.container.icon?.sourceUrl && (
            <div className="w-full flex justify-center mb-3">
              <div className="relative h-16 w-16">
                <CloudflareImagesImage
                  src={layout.container.icon.sourceUrl}
                  alt={layout.container.icon.altText}
                  fill
                />
              </div>
            </div>
          )}
          <div className="flex flex-col gap-y-2 pb-12">
            <h3
              className="text-4xl"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: cleanParagraphTags(layout.container.heading),
              }}
            />
            <div
              className="body-4"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: cleanParagraphTags(layout.container.body),
              }}
            />
          </div>
          {layout.container.button && !layout.container.button.isHidden && (
            <CmsButton
              button={layout.container.button}
              rightIcon={<ArrowRightIcon />}
              setButtonModalState={setButtonModalState}
              className="w-full"
            />
          )}
        </div>
      </div>
      {buttonModalState.show && (
        <CmsButtonModalContainer buttonModalState={buttonModalState} />
      )}
    </div>
  );
};

export default FeatureBanner;
