import api from "@modules/api/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import purchaseQueryKeys from "../purchaseQueryKeys";

export const applyPromoCode = (request: {
  dealId: string;
  promoCode: string;
}) => {
  const { dealId, promoCode } = request;
  return api.rawPost(`api/purchases/${dealId}/apply-promo-code/${promoCode}`);
};

export default function useAftermarketApplyPromoCodeMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: applyPromoCode,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: purchaseQueryKeys.activeAftermarketPurchase(),
      });
    },
  });
}
