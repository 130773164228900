import { gtmPushData } from "@common/helpers/gtm";
import Button from "@common/components/Button";
import LoadingSpinner from "@common/components/LoadingSpinner";
import { cleanParagraphTags } from "@common/helpers/cleanHtml";
import { FeaturedInventoryLayout } from "@modules/cms/types/FeaturedInventoryLayout";
import VehicleCard from "@modules/vehicle/components/VehicleCard";
import VehicleCardSetCarousel from "@modules/vehicle/components/VehicleCardSetCarousel";
import convertToFilterState from "@modules/vehicle/helpers/convertToFilterState";
import useGetVehicleFilterData from "@modules/vehicle/hooks/useVehicleFilterData";
import useVehicleList from "@modules/vehicle/hooks/useVehicleList";
import {
  FilterKeys,
  VehicleFilter,
} from "@modules/vehicle/types/vehicleFilter";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { ReactNode } from "react";
import { TranslationKeys } from "src/@types/i18next";
import getRelativeLink from "../helpers/getRelativeLink";
import GetBackgroundStyle from "../helpers/getBackgroundStyle";

type Props = {
  locale?: string;
  fields: FeaturedInventoryLayout;
};

const pageSize = 4;

const FeaturedInventory = ({ locale = "en", fields }: Props) => {
  const router = useRouter();
  const { t } = useTranslation(["common"]);
  const { vehicleFilters } = useGetVehicleFilterData();
  const sortByFilterData = vehicleFilters?.sortBy;
  const newestInventorySort = sortByFilterData?.find(
    (s) => s.id === "NewestInventory"
  );
  let filters: VehicleFilter[] = [];

  if (vehicleFilters !== null && fields.vlpFilter != null) {
    const vlpUrl = new URL(fields.vlpFilter);
    filters = convertToFilterState(
      Object.fromEntries(vlpUrl.searchParams),
      vehicleFilters
    );

    filters = [
      ...filters,
      {
        key: FilterKeys.SortBy,
        state: {
          id: newestInventorySort?.id || "",
          name:
            (newestInventorySort?.name as TranslationKeys["vehiclesFilters"]) ||
            "",
        },
      },
    ];
  }

  const { data, isFetching } = useVehicleList({
    pageSize,
    vehicleFilters: { filters, isDirty: false },
    locale,
    favouriteVehicles: [],
    enabled: true,
  });

  const vehicles = data?.pages.flatMap((p) => p.vehicles) || [];
  const totalVehicles = data?.pages.flatMap((p) => p.totalItems)[0] || 0;
  const isRightPaymentEligible =
    (data?.pages[0]?.hasPersonalTradeInValue ||
      data?.pages[0]?.hasPersonalInterestRate) ??
    false;

  const vehicleCardOverlay = (vehicleCard: ReactNode) => {
    return (
      <div className="relative" key="vehicle-card-overlay">
        <div className="absolute h-full w-full top-0 left-0 z-[4]">
          <div className="h-full w-full bg-white/90 rounded-lg" />
          <div
            className="absolute top-1/2 left-1/2"
            style={{
              transform: "translate(-50%, -50%)",
            }}
          >
            <Button
              className="whitespace-nowrap"
              onClick={() => {
                gtmPushData({
                  event: "Available-Vehicles",
                  element: "CTA",
                  descriptor: "View-All",
                });
                router.push(getRelativeLink(fields.vlpFilter));
              }}
            >
              {t("common:view_all_vehicles", { totalVehicles })}
            </Button>
          </div>
        </div>
        {vehicleCard}
      </div>
    );
  };

  return vehicles.length > 0 ? (
    <div
      id="cms-featured-inventory"
      className="bg-white overflow-visible no-scrollbar relative bg-cover bg-center"
      style={GetBackgroundStyle(fields.background)}
    >
      <div className="max-w-page mx-auto">
        <div className="w-full pt-8 px-5 pb-9 md:pb-10 lg:pt-14 lg:pb-16">
          {isFetching ? (
            <LoadingSpinner isLoading={isFetching} isOverlay={false} />
          ) : (
            <>
              <article>
                <div className="flex justify-center text-center">
                  <h3
                    className="text-text-light-100 text-2xl lg:text-4xl lg:tracking-tight"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: cleanParagraphTags(fields.title),
                    }}
                  />
                </div>
              </article>

              <div className="hidden sm:block lg:hidden">
                <VehicleCardSetCarousel
                  isRightPaymentEligible={isRightPaymentEligible}
                  vehicles={vehicles}
                  emblaCarouselStyle="tablet"
                  lastCardOverlay={vehicleCardOverlay}
                  eventTag="Available-Vehicles"
                />
              </div>

              <div className="block sm:hidden">
                <VehicleCardSetCarousel
                  isRightPaymentEligible={isRightPaymentEligible}
                  vehicles={vehicles}
                  emblaCarouselStyle="mobile"
                  lastCardOverlay={vehicleCardOverlay}
                  eventTag="Available-Vehicles"
                />
              </div>

              <div
                className={classNames(
                  "lg:grid grid-flow-col gap-2 mt-5 lg:mt-8 hidden",
                  {
                    "grid-cols-1": vehicles?.length === 1,
                    "grid-cols-2": vehicles?.length === 2,
                    "grid-cols-3": vehicles?.length === 3,
                    "grid-cols-4": vehicles?.length === 4,
                  }
                )}
              >
                {vehicles.map((v, i) => {
                  if (i === vehicles.length - 1 && vehicles.length > 1) {
                    return vehicleCardOverlay(
                      <VehicleCard
                        isRightPaymentEligible={isRightPaymentEligible}
                        key={v.id}
                        vehicle={v}
                        index={i}
                      />
                    );
                  }

                  return (
                    <VehicleCard
                      isRightPaymentEligible={isRightPaymentEligible}
                      key={v.id}
                      vehicle={v}
                      index={i}
                    />
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  ) : null;
};

export default FeaturedInventory;
