import { cleanParagraphTags } from "@common/helpers/cleanHtml";
import { IFrameLayout } from "../types/IFrameLayout";
import GetBackgroundStyle from "../helpers/getBackgroundStyle";

const IFrame = ({ title, iframeSrc, background }: IFrameLayout) => {
  return (
    <div
      id="cms-iframe"
      className="bg-white overflow-visible no-scrollbar relative bg-cover bg-center"
      style={GetBackgroundStyle(background)}
    >
      <div className="max-w-page mx-auto items-center gap-6 lg:gap-24 pt-10 pb-6 px-4 lg:px-5 lg:py-16">
        <div className="items-center justify-start text-center pb-6">
          {title && (
            <h3
              className="text-text-light-100 text-2xl lg:text-4xl lg:tracking-tight"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: cleanParagraphTags(title),
              }}
            />
          )}
        </div>

        <iframe
          src={iframeSrc}
          className="w-full h-full-wo-header aspect-[16/9]"
          title={`${title} IFrame`}
        />
      </div>
    </div>
  );
};

export default IFrame;
