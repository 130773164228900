import { Trans, useTranslation } from "next-i18next";
import { useFormContext } from "react-hook-form";
import { QuoteMethodEnum } from "@common/enums/QuoteMethod";
import Link from "next/link";
import { PROTECTION_RIGHTRIDE_CA_EMAIL } from "@common/constants";
import { WarrantyInstantQuoteFormProps } from "./types/WarrantyInstantQuoteFormProps";

const WarrantyInstantQuoteGenerated = () => {
  const { t } = useTranslation(["common", "purchase"]);

  const { watch } = useFormContext<WarrantyInstantQuoteFormProps>();

  return (
    <div className="text-primary-deep">
      <h3>{t("purchase:your_quote_is_being_generated_and_sent")}</h3>
      {watch("quoteMethod")?.id === QuoteMethodEnum.Email ? (
        <div className="body-3 mt-4">
          <Trans
            t={t}
            i18nKey="purchase:please_check_your_email_and_junk_folder"
            values={{
              email: watch("email"),
            }}
            components={{
              bold: <span className="font-semibold" />,
            }}
          />
        </div>
      ) : (
        <div className="body-3 mt-4">
          <Trans
            t={t}
            i18nKey="purchase:please_check_your_phone"
            values={{
              phone: watch("phone"),
            }}
            components={{
              bold: <span className="font-semibold" />,
            }}
          />
        </div>
      )}
      <div className="caption-2 mt-4">
        <Trans
          t={t}
          i18nKey="purchase:if_you_have_not_received_your_quote"
          values={{ email: PROTECTION_RIGHTRIDE_CA_EMAIL }}
          components={{
            a: (
              <Link
                className="text-primary-bold underline"
                href={`mailto:${PROTECTION_RIGHTRIDE_CA_EMAIL}`}
              />
            ),
          }}
        />
      </div>
    </div>
  );
};

export default WarrantyInstantQuoteGenerated;
