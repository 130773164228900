import useAftermarketPurchaseFromQuery from "@modules/purchase/hooks/useAftermarketPurchaseFromQuery";
import { WarrantySelectCoverageLayout } from "@modules/cms/types/WarrantySelectCoverageLayout";
import GetBackgroundStyle from "@modules/cms/helpers/getBackgroundStyle";
import { Trans, useTranslation } from "next-i18next";
import LocalImage from "@common/components/LocalImage";
import {
  BubblesWarrantyEn,
  BubblesWarrantyFr,
  WarrantyHeroEn,
  WarrantyHeroFr,
} from "@public/images/purchase/coverage";
import { ImageWithTranslation } from "@common/types/ImageWithTranslation";
import { AftermarketDiscountConvertData } from "@modules/purchase/types/AftermarketDiscountConvertData";
import { ExperienceResult } from "@modules/core/convert/hooks/useConvertExperience";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { tryGetActiveAftermarketPurchase } from "@modules/purchase/types/Purchase";
import useAftermarketPurchase from "@modules/purchase/hooks/useAftermarketPurchase";
import { withDiscountVaration } from "@modules/core/convert/types/VariationType";
import PurchaseSourceVehicleImage from "../PurchaseSourceVehicleImage";
import {
  WarrantySegmentationMode,
  vehicleOwnershipStatusMode,
} from "./types/WarrantySegmentationMode";
import WarrantySegmentationForm from "./WarrantySegmentationForm";
import WarrantyDiscountBar from "./WarrantyDiscountBar";
import WarrantyDiscountModal from "./WarrantyDiscountModal";

const bubblesImage: ImageWithTranslation = {
  srcEn: BubblesWarrantyEn,
  srcFr: BubblesWarrantyFr,
  altKey: "purchase:alt_wty_info_img",
};

const vehicleDefaultImage: ImageWithTranslation = {
  srcEn: WarrantyHeroEn,
  srcFr: WarrantyHeroFr,
  altKey: "purchase:alt_wty_vehicle_img",
};

type Props = {
  layout: WarrantySelectCoverageLayout;
  convertResult?: ExperienceResult<AftermarketDiscountConvertData>;
};

const WarrantySegmentationSection = ({
  layout: { background },
  convertResult,
}: Props) => {
  const { t } = useTranslation(["common", "purchase"]);
  const router = useRouter();
  const { locale: rlocale } = router;
  const locale = rlocale || "en";

  const aftermarketPurchaseFromQuery = useAftermarketPurchaseFromQuery();

  const { coverImage } = aftermarketPurchaseFromQuery;

  const [warrantySegmentModeState, setWarrantySegmentModeState] =
    useState<WarrantySegmentationMode>(vehicleOwnershipStatusMode);

  const { purchase: purchaseResponse, isLoading } = useAftermarketPurchase();
  const purchase = tryGetActiveAftermarketPurchase(purchaseResponse);

  const [showWarrantyDiscountBar, setShowWarrantyDiscountBar] =
    useState<boolean>(false);

  const [showWarrantyDiscountModal, setShowWarrantyDiscountModal] =
    useState<boolean>(false);

  const handleExperience = useCallback(() => {
    if (
      convertResult?.variationKey === withDiscountVaration.key &&
      (!purchase || purchase?.products?.length === 0) &&
      convertResult &&
      convertResult?.data &&
      convertResult?.data?.discountValue > 0 &&
      !isLoading
    ) {
      setShowWarrantyDiscountBar(true);
      setShowWarrantyDiscountModal(true);
    } else {
      setShowWarrantyDiscountBar(false);
      setShowWarrantyDiscountModal(false);
    }
  }, [convertResult, isLoading, purchase]);

  useEffect(() => {
    handleExperience();
  }, [handleExperience]);

  return (
    <>
      <WarrantyDiscountBar
        isShow={showWarrantyDiscountBar}
        discountValue={convertResult?.data?.discountValue ?? 0}
      />

      <div style={GetBackgroundStyle(background)}>
        <div className="relative max-w-page mx-auto flex gap-4 items-center flex-col pt-10 pb-6 px-4 lg:px-5 lg:py-16 lg:flex-row">
          <div className="flex flex-col lg:basis-[55%]">
            <div className="flex-1 w-full relative">
              <div className="min-w-full h-auto relative inset-0">
                {coverImage ? (
                  <div>
                    <div className="absolute -right-4 md:-right-1 lg:-right-14 xl:-right-10 z-[2]">
                      <LocalImage
                        className="h-48 w-40 md:h-96 md:w-96 object-contain rounded-lg"
                        src={
                          locale === "fr"
                            ? bubblesImage.srcFr
                            : bubblesImage.srcEn
                        }
                        alt={t(bubblesImage.altKey)}
                      />
                    </div>

                    <div className="flex justify-center overflow-hidden relative md:h-full">
                      <div className="rounded-full border-white border-[.5rem] mr-9 md:mr-32 lg:mr-44 h-80 w-80 md:border-[1rem] md:h-[27rem] md:w-[27rem] lg:h-[30rem] lg:w-[30rem]">
                        <PurchaseSourceVehicleImage
                          className="rounded-full w-full h-full object-cover"
                          src={coverImage}
                          alt={t(vehicleDefaultImage.altKey)}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="overflow-auto relative h-56 md:h-96 lg:mr-4">
                    <LocalImage
                      className="z-[2] w-full object-contain h-auto rounded-lg"
                      src={
                        locale === "fr"
                          ? vehicleDefaultImage.srcFr
                          : vehicleDefaultImage.srcEn
                      }
                      alt={t(vehicleDefaultImage.altKey)}
                      fill
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="flex-1 lg:basis-[40%] lg:px-0 md:px-28">
              <div className="w-full items-center justify-center text-center lg:text-left lg:items-start lg:justify-start">
                <p className="text-text-light-100 font-semibold text-[2.25rem] md:text-[3.25rem] leading-[2.625rem] md:leading-[3.625rem]">
                  <Trans
                    t={t}
                    i18nKey="purchase:protect_your_ride_and_wallet"
                    components={[<span className="text-primary-bold" />]}
                  />
                </p>
              </div>

              <div className="flex flex-col gap-4 mt-4 lg:mt-6 w-full items-center justify-center text-center lg:text-left lg:items-start lg:justify-start">
                <h4>
                  <Trans
                    t={t}
                    i18nKey="purchase:get_instant_quote_in_seconds"
                    components={[<span className="font-medium" />]}
                  />
                </h4>
                <p className="body-2">
                  {t("purchase:right_ride_is_a_trusted")}
                </p>
              </div>
            </div>
          </div>
          <div className="bg-gray-25 rounded-2xl py-12 px-6 flex-1 basis-[53%]">
            <WarrantySegmentationForm
              modeState={[
                warrantySegmentModeState,
                setWarrantySegmentModeState,
              ]}
              convertResult={convertResult}
            />
          </div>
        </div>
      </div>
      <WarrantyDiscountModal
        discountValue={convertResult?.data?.discountValue ?? 0}
        isOpen={showWarrantyDiscountModal}
        onClose={() => setShowWarrantyDiscountModal(false)}
      />
    </>
  );
};

export default WarrantySegmentationSection;
