import { ContentAreaLayout } from "../types/ContentAreaLayout";

const ContentArea = ({ contentDetails }: ContentAreaLayout) => {
  return (
    <div className="max-w-page mx-auto items-center gap-6 lg:gap-24 py-2">
      <div
        className="text-base font-normal lg:text-lg lg:leading-6"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: contentDetails,
        }}
      />
    </div>
  );
};

export default ContentArea;
