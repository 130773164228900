import { ArrowRightIcon } from "@common/components/icons";
import { cleanHtml } from "@common/helpers/cleanHtml";
import classNames from "classnames";
import { useState } from "react";
import CloudflareImagesImage from "@common/components/CloudflareImagesImage";
import useAftermarketPurchaseFromQuery from "@modules/purchase/hooks/useAftermarketPurchaseFromQuery";
import { Trans, useTranslation } from "next-i18next";
import CmsButton from "@modules/cms/components/CmsButton";
import CmsButtonModalContainer from "@modules/cms/components/ButtonModalContainer";
import ActionButton from "@modules/cms/enum/ActionButtonEnum";
import { DynamicHeroHeadingLayout } from "@modules/cms/types/DynamicHeroHeadingLayout";
import GetBackgroundStyle from "../helpers/getBackgroundStyle";

const DynamicHeroHeading = ({
  background,
  heading,
  image,
  imagePosition,
  subHeading,
  button,
  secondaryButton,
}: DynamicHeroHeadingLayout) => {
  const { t } = useTranslation(["purchase"]);

  const [buttonModalState, setButtonModalState] = useState<{
    show: boolean;
    actionType: ActionButton;
  }>({ show: false, actionType: ActionButton.UnAssigned });

  // This can be updated to a more generalised hook overtime.
  const aftermarketPurchaseFromQuery = useAftermarketPurchaseFromQuery();
  const { year, make, model } = aftermarketPurchaseFromQuery;

  return (
    <div
      id="dynamic-hero-heading-container"
      className="bg-white overflow-visible no-scrollbar relative bg-cover bg-center"
      style={GetBackgroundStyle(background)}
    >
      <div
        className={classNames(
          "relative max-w-page mx-auto flex items-center flex-col-reverse pt-10 pb-6 px-4 lg:px-5 lg:py-16",
          {
            "lg:flex-row": imagePosition === "Right",
            "lg:flex-row-reverse": imagePosition === "Left",
          }
        )}
      >
        <div className="flex-1 lg:basis-[40%] lg:px-0 md:px-28">
          <div>
            <div className="w-full items-center justify-center text-center sm:text-left sm:items-start sm:justify-start">
              {year && make && model ? (
                <h1 className="text-text-light-100 text-[2.25rem] md:text-[3.25rem] leading-[2.625rem] md:leading-[3.625rem]">
                  <Trans
                    t={t}
                    i18nKey="purchase:looking_for_help"
                    values={{
                      vehicle: `${year} ${make} ${model}`,
                    }}
                    components={{
                      bold: <span className="text-primary-bold" />,
                    }}
                  />
                </h1>
              ) : (
                <h1
                  className="text-text-light-100 text-[2.25rem] md:text-[3.25rem] leading-[2.625rem] md:leading-[3.625rem]"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: cleanHtml(heading),
                  }}
                />
              )}
            </div>

            <div className="mt-2 w-full items-center justify-center text-center sm:text-left sm:items-start sm:justify-start">
              <h4
                className="mt-4 lg:mt-8"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: cleanHtml(subHeading),
                }}
              />
            </div>

            <div className="flex flex-col-reverse sm:flex-row gap-3 mt-8 items-center justify-center text-center sm:text-left sm:items-start sm:justify-start">
              {button && !button.isHidden && (
                <div>
                  <CmsButton
                    button={button}
                    rightIcon={<ArrowRightIcon />}
                    setButtonModalState={setButtonModalState}
                  />
                </div>
              )}
              {secondaryButton && !secondaryButton.isHidden && (
                <div>
                  <CmsButton
                    button={secondaryButton}
                    rightIcon={<ArrowRightIcon />}
                    setButtonModalState={setButtonModalState}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex-1 lg:basis-[60%] w-full relative">
          <div className="min-w-full h-auto relative inset-0">
            <div className="overflow-auto relative h-56 md:h-96">
              {image && image.sourceUrl && (
                <CloudflareImagesImage
                  src={image.sourceUrl}
                  alt={image.altText}
                  fill
                  className="z-[2] w-full object-contain h-auto rounded-lg"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {buttonModalState.show && (
        <CmsButtonModalContainer buttonModalState={buttonModalState} />
      )}
    </div>
  );
};

export default DynamicHeroHeading;
