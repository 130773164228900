import BubblesFinancingEn from "./bubbles-financing-en.png";
import BubblesFinancingFr from "./bubbles-financing-fr.png";
import FinancingHeroEn from "./financing-hero-en.png";
import FinancingHeroFr from "./financing-hero-fr.png";
import CoverageHeroImage from "./c2c-coverage.svg";
import VehicleDetailsHeroImage from "./c2c-vehicle-details.svg";
import FinancingHeroImage from "./c2c-financing.svg";
import IntroductionImage from "./c2c-introduction.svg";
import IntroductionMobileImage from "./c2c-introduction-mobile.svg";
import SellerHeroImageEn from "./seller-hero-en.png";
import SellerHeroImageFr from "./seller-hero-fr.png";
import ReceivedApplicationImage from "./steering-wheel.png";
import ApplicationApprovedImage from "./car-with-bow.png";

export {
  BubblesFinancingEn,
  BubblesFinancingFr,
  FinancingHeroEn,
  FinancingHeroFr,
  CoverageHeroImage,
  VehicleDetailsHeroImage,
  IntroductionImage,
  IntroductionMobileImage,
  FinancingHeroImage,
  SellerHeroImageEn,
  SellerHeroImageFr,
  ReceivedApplicationImage,
  ApplicationApprovedImage,
};
