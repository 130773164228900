import Form from "@common/components/form";
import { PriceTagIcon } from "@common/components/icons";
import Modal from "@common/components/Modal";
import Snackbar from "@common/components/Snackbar";
import getPromoCodeIsValid from "@modules/purchase/api/getPromoCodeIsValid";
import useAftermarketApplyPromoCodeMutation from "@modules/purchase/hooks/useAftermarketApplyPromoCodeMutation";
import { useTranslation, Trans } from "next-i18next";
import { ChangeEvent } from "react";
import { useForm } from "react-hook-form";

type Props = {
  dealId: string;
  isOpen: boolean;
  onClose: () => void;
};

type FormField = {
  promoCode: string | null;
};

const ApplyPromoCodeModal = ({ dealId, isOpen, onClose }: Props) => {
  const { t } = useTranslation(["common", "purchase"]);

  const applyPromoCodeMutation = useAftermarketApplyPromoCodeMutation();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormField>();

  const onSubmit = (form: FormField) => {
    const { promoCode } = form;
    if (!promoCode) return;

    getPromoCodeIsValid(promoCode).then((res) => {
      if (res.error) {
        if (res.error === "Promo Code cannot be found") {
          setError("promoCode", {
            message: t("purchase:promo_code_cannot_be_found"),
          });
        } else if (res.error === "Promo Code has expired") {
          setError("promoCode", {
            message: t("purchase:promo_code_has_expired"),
          });
        }
      } else {
        applyPromoCodeMutation.mutate(
          {
            dealId,
            promoCode,
          },
          {
            onSuccess: () => {
              Snackbar.Pop({
                message: (
                  <Trans
                    t={t}
                    i18nKey="purchase:promo_code_applied_to_quote"
                    values={{
                      name: promoCode,
                    }}
                    components={{
                      b: <span className="font-semibold text-cyan-500" />,
                    }}
                  />
                ),
              });
            },
            onError: () => {
              Snackbar.Pop({
                message: t("common:unexpected_error"),
              });
            },
            onSettled: () => {
              onClose();
            },
          }
        );
      }
    });
  };

  return isOpen ? (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t("purchase:got_a_promo_code")}
      modalType="none"
      alignment="center"
      customIcon={<PriceTagIcon className="w-24 h-24" />}
    >
      <div className="flex flex-col gap-8 p-2">
        <h3 className="">{t("purchase:apply_promo_code_modal_heading")}</h3>
        <p className="body-2">
          {t("purchase:apply_promo_code_modal_description")}
        </p>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col items-center gap-2 pt-4"
      >
        <div className="w-full">
          <Form.Input
            label={t("purchase:promo_code")}
            maxLength={20}
            feedback={errors.promoCode?.message}
            status={errors.promoCode ? "error" : "default"}
            {...register("promoCode", {
              required: {
                value: true,
                message: t("common:required"),
              },
              maxLength: {
                value: 20,
                message: t("common:must_be_fewer_characters", {
                  count: 20,
                }),
              },
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                e.target.value = e.target.value.trim().toUpperCase();
              },
            })}
          />
        </div>
        <div className="flex justify-center w-full">
          <Modal.Buttons
            primaryButtonText={t("purchase:apply_promo_code")}
            secondaryButtonText={t("common:cancel")}
            isPrimarySubmit
          />
        </div>
      </form>
    </Modal>
  ) : null;
};

export default ApplyPromoCodeModal;
