import { IdNameTranslation } from "@common/types/IdNameTranslation";
import api from "@modules/api/api";
import vehicleQueryKeys from "@modules/vehicle/vehicleQueryKeys";
import { useQuery } from "@tanstack/react-query";

const performGet = async () => {
  return api.get<IdNameTranslation[]>("api/vehicles/display-fuel-types");
};

export default function useVehicleDisplayFuelTypes() {
  const { data } = useQuery({
    queryKey: vehicleQueryKeys.vehicleDisplayFuelTypes(),
    queryFn: performGet,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  return {
    fuelTypes: data ?? [],
  };
}
