import { gtmPushData } from "@common/helpers/gtm";
import { useTranslation } from "next-i18next";
import { Dispatch, SetStateAction, useCallback } from "react";
import GoogleMapsWrapper from "@modules/location/helpers/GoogleMapsWrapper";
import { FormProvider, useForm } from "react-hook-form";
import useConsumerFinancingQuery from "@modules/purchase/hooks/useConsumerFinancingFromQuery";
import Button from "@common/components/Button";
import useConsumerFinancing, {
  checkActiveConsumerFinancing,
} from "@modules/purchase/hooks/useConsumerFinancing";
import {
  getStepById,
  getStepPathname,
} from "@modules/purchase/types/PurchaseStep";
import { Purchase } from "@modules/purchase/types/Purchase";
import { useRouter } from "next/router";
import useSourceInfoFromQuery from "@modules/query-string/hooks/useSourceInfoFromQuery";
import {
  FinancingPreQualificationMode,
  PreQualificationModeName,
} from "./types/FinancingPreQualificationMode";
import FinancingPreQualificationIntroduction from "./FinancingPreQualificationIntroduction";
import FinancingPreQualificationPersonalInfo from "./FinancingPreQualificationPersonalInfo";
import FinancingPreQualificationResidenceInfo from "./FinancingPreQualificationResidenceInfo";
import { PreQualificationFormProps } from "./types/FinancingPreQualificationFormProps";
import FinancingPreQualificationFinancialInfo from "./FinancingPreQualificationFinancialInfo";

type Props = {
  modeState: [
    FinancingPreQualificationMode,
    Dispatch<SetStateAction<FinancingPreQualificationMode>>
  ];
};

const FinancingPreQualificationForm = ({ modeState }: Props) => {
  const { t } = useTranslation(["common", "purchase"]);
  const [mode, setMode] = modeState;
  const router = useRouter();

  const { source } = useSourceInfoFromQuery();

  const switchMode = useCallback(
    (newMode: FinancingPreQualificationMode) => {
      gtmPushData({
        event: "Prequalification",
        element: "Progress",
        stage: newMode.name,
      });
      setMode(newMode);
    },
    [setMode]
  );

  const { consumerFinancing: consumerFinancingOrNotFound } =
    useConsumerFinancing();

  const consumerFinancing = consumerFinancingOrNotFound as Purchase;

  const consumerFinancingQuery = useConsumerFinancingQuery();
  const {
    firstName,
    lastName,
    email,
    phone,
    address,
    city,
    province,
    postalCode,
  } = consumerFinancingQuery;

  const methods = useForm<PreQualificationFormProps>({
    defaultValues: {
      firstName: firstName ?? "",
      lastName: lastName ?? "",
      email: email ?? "",
      phoneNumber: phone ?? "",
      dateOfBirth: null,
      address: address ?? "",
      city: city ?? "",
      province: province ?? "",
      postalCode: postalCode ?? "",
    },
  });

  const isConsumerFinancingFound = checkActiveConsumerFinancing(
    consumerFinancingOrNotFound
  );

  const handleResumeClick = () => {
    if (consumerFinancing && consumerFinancing.currentStepId) {
      const resumeStep = getStepById(
        "financing",
        consumerFinancing.currentStepId
      );

      if (resumeStep) {
        router.push({
          pathname: getStepPathname(resumeStep, true),
          query: { source },
        });
      }
    }
  };

  return (
    <div>
      <div className="flex flex-col items-center justify-center sm:px-1 md:px-6">
        <div className="w-full max-w-md">
          <h5 className="mb-6 text-center justify-center">
            {mode.name === PreQualificationModeName.introduction &&
              t("purchase:how_does_pre_approval_work")}
            {mode.name === PreQualificationModeName.personalInfo &&
              t("purchase:tell_us_about_yourself")}
            {mode.name === PreQualificationModeName.residenceInfo &&
              t("purchase:where_are_you_living")}
            {mode.name === PreQualificationModeName.financialInfo &&
              t("purchase:your_budget")}
          </h5>
          <div className="mb-6 body-4 text-center justify-center">
            {mode.name === PreQualificationModeName.introduction &&
              t("purchase:fill_out_our_preapproval_form")}
            {mode.name === PreQualificationModeName.personalInfo &&
              t("purchase:provide_your_basic_personal_info")}
            {mode.name === PreQualificationModeName.residenceInfo &&
              t(
                "purchase:we_use_your_address_to_find_which_loans_and_financing_rates_available"
              )}
            {mode.name === PreQualificationModeName.financialInfo &&
              t(
                "purchase:please_how_much_looking_to_spend_toward_next_vehicle"
              )}
          </div>
        </div>
      </div>
      <GoogleMapsWrapper>
        {/* eslint-disable react/jsx-props-no-spreading */}
        <FormProvider {...methods}>
          <div>
            {mode?.name === PreQualificationModeName.introduction && (
              <FinancingPreQualificationIntroduction
                onChangeMode={switchMode}
              />
            )}
            {mode?.name === PreQualificationModeName.personalInfo && (
              <FinancingPreQualificationPersonalInfo
                onChangeMode={switchMode}
              />
            )}
            {mode?.name === PreQualificationModeName.residenceInfo && (
              <FinancingPreQualificationResidenceInfo
                onChangeMode={switchMode}
              />
            )}
            {mode?.name === PreQualificationModeName.financialInfo && (
              <FinancingPreQualificationFinancialInfo
                onChangeMode={switchMode}
              />
            )}
          </div>
        </FormProvider>
      </GoogleMapsWrapper>
      {isConsumerFinancingFound && (
        <div className="flex justify-center mt-auto">
          <Button
            fill="link"
            className="underline pl-0 lg:text-start lg:w-3/4 xl:w-auto"
            onClick={() => handleResumeClick()}
          >
            {t("purchase:resume_my_application")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default FinancingPreQualificationForm;
