import DivCheckbox from "@common/components/DivCheckbox";
import {
  VehicleConvertibleIcon,
  VehicleCoupeIcon,
  VehicleHatchbackIcon,
  VehicleMinivanIcon,
  VehicleSedanIcon,
  VehicleSuvIcon,
  VehicleTruckIcon,
  VehicleWagonIcon,
} from "@common/components/icons";
import translate from "@common/helpers/translate";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { ChangeEvent, ReactNode } from "react";
import { VehicleBodyStyle } from "@modules/vehicle/types/Vehicle";
import BodyStyle from "@common/enums/BodyStyle";
import {
  BodyStyleFilter,
  FilterAction,
  FilterActions,
  FilterKeys,
  VehicleFilter,
} from "../types/vehicleFilter";
import FilterDisclosure from "./FilterDisclosure";

type Props = {
  bodyStyles: VehicleBodyStyle[];
  onUpdate(filterAction: FilterAction): void;
  vehicleFilters: VehicleFilter[];
};
interface Orderable {
  order: number;
}

const orderFunction = (a: Orderable, b: Orderable) => a.order - b.order;

export const setBodyStyleIcon = (id: number, className: string): ReactNode => {
  let icon: ReactNode;

  switch (id) {
    case BodyStyle.Sedan: {
      icon = <VehicleSedanIcon className={className} />;
      break;
    }
    case BodyStyle.Coupe: {
      icon = <VehicleCoupeIcon className={className} />;
      break;
    }
    case BodyStyle.Hatchback: {
      icon = <VehicleHatchbackIcon className={className} />;
      break;
    }
    case BodyStyle.SUV: {
      icon = <VehicleSuvIcon className={className} />;
      break;
    }
    case BodyStyle.Minivan: {
      icon = <VehicleMinivanIcon className={className} />;
      break;
    }
    case BodyStyle.Truck: {
      icon = <VehicleTruckIcon className={className} />;
      break;
    }
    case BodyStyle.Convertible: {
      icon = <VehicleConvertibleIcon className={className} />;
      break;
    }
    case BodyStyle.Wagon: {
      icon = <VehicleWagonIcon className={className} />;
      break;
    }
    default:
  }

  return icon;
};

const VehicleBodyStyleFilter = ({
  bodyStyles,
  onUpdate,
  vehicleFilters,
}: Props) => {
  const { t } = useTranslation(["vehiclesFilters"]);
  const router = useRouter();
  const locale = router.locale || "";
  const iconClassName = "block m-auto text-gray-100 w-16 h-8";

  const bodyStyleFilter = vehicleFilters.filter(
    (f) => f.key === FilterKeys.BodyStyle
  ) as BodyStyleFilter[];

  const selectedBodyStyles = bodyStyleFilter.map((f) => f.state);

  const handleCheckChange = (
    event: ChangeEvent<HTMLInputElement>,
    displayBodyStyle: VehicleBodyStyle
  ) => {
    if (event.target.checked) {
      onUpdate({
        type: FilterActions.AddBodyStyleFilter,
        payload: {
          id: displayBodyStyle.id.toString(),
          name: displayBodyStyle.name,
        },
      });
    } else {
      onUpdate({
        type: FilterActions.RemoveByIdAndType,
        payload: {
          type: FilterKeys.BodyStyle,
          id: displayBodyStyle.id.toString(),
        },
      });
    }
  };

  return (
    <FilterDisclosure
      headerText={t("vehiclesFilters:body_style")}
      active={selectedBodyStyles.length > 0}
    >
      <ul className="body-3 grid grid-cols-2 gap-2">
        {bodyStyles &&
          bodyStyles.sort(orderFunction).map((bds) => (
            <li key={bds.id}>
              <DivCheckbox
                id={`bodyStyle${translate(bds.name, locale || "en")}Filter`}
                objectValue={bds}
                className="flex-col"
                onChangeEvent={handleCheckChange}
                isChecked={
                  selectedBodyStyles
                    .map((st) => st.id)
                    .indexOf(bds.id.toString()) > -1
                }
              >
                {setBodyStyleIcon(bds.id, iconClassName)}
                <span>{translate(bds.name, locale)}</span>
              </DivCheckbox>
            </li>
          ))}
      </ul>
    </FilterDisclosure>
  );
};

export default VehicleBodyStyleFilter;
