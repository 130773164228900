import Modal from "@common/components/Modal";
import { useTranslation } from "next-i18next";
import { useNumberMask } from "@common/hooks/useMask";
import {
  getStepById,
  aftermarketDetailsStep,
  purchaseLastStep,
} from "../../types/PurchaseStep";
import { Purchase } from "../../types/Purchase";
import { getCoverageByAbbr } from "../../types/ExtendedWarrantyCoverageType";

type Props = {
  purchase: Purchase;
  show: boolean;
  onClose: () => void;
  onResumePurchaseClick: () => void;
  onOverwritePurchaseClick: () => void;
  disableClosing?: boolean;
  modalType?: "default" | "rangeQuote";
};

const getVehicleYmmt = (purchase: Purchase) => {
  const { vehicle } = purchase ?? {};
  let year = vehicle?.year;
  let make = vehicle?.make;
  let model = vehicle?.model;
  let trim = vehicle?.trim;

  // This is a temporary fix for the small window where user exited from intro page and tried to quote another vehicle.
  // In this scenario the vehicle object can have null ymmt since we have not fully updated vehicle details (this happens on Details step).
  // Once Intro Page is removed via future ticket, this can be removed/refactored to return purchase.vehicle ymmt.
  if (
    (purchase?.currentStepId === aftermarketDetailsStep.id &&
      (!year || !make)) ||
    !model
  ) {
    year = vehicle?.chromeDecodedData?.year;
    make = vehicle?.chromeDecodedData?.make;
    model = vehicle?.chromeDecodedData?.model;
    trim =
      vehicle?.chromeDecodedData?.trims &&
      vehicle?.chromeDecodedData.trims.length === 1
        ? vehicle?.chromeDecodedData?.trims[0] ?? ""
        : "";
  }

  const vehicleYmmt = [year, make, model, trim].filter(Boolean).join(" ");
  return vehicleYmmt;
};

const CoverageOverwriteModal = ({
  purchase,
  show,
  onClose,
  onResumePurchaseClick,
  onOverwritePurchaseClick,
  disableClosing = false,
  modalType = "default",
}: Props) => {
  const { t } = useTranslation(["purchase", "common"]);
  const { maskPipe } = useNumberMask();

  const resumePurchaseStep = getStepById(
    "aftermarket",
    purchase?.currentStepId ?? aftermarketDetailsStep.id
  );

  const resumePurchaseStepText = resumePurchaseStep
    ? t(resumePurchaseStep.nameTranslation)
    : "";

  const isPurchaseComplete =
    purchase.currentStepId === purchaseLastStep.aftermarket.id;

  const resumeButtonLabelText = isPurchaseComplete
    ? t("purchase:view_my_contract_and_receipt")
    : t("purchase:resume_to_section", {
        step: resumePurchaseStepText,
      });

  const coverageTypeName = purchase?.products?.[0]?.config.coverageTypeId
    ? getCoverageByAbbr(purchase.products[0].config.coverageTypeId as string)
        ?.i18nKey
    : undefined;

  const formattedTermKms = purchase?.products?.[0]?.config.termKms
    ? maskPipe(purchase.products[0].config.termKms.toString())
    : "";
  const coverageDescription =
    purchase?.products?.[0]?.config.term && formattedTermKms
      ? t("purchase:coverage_terms", {
          term: purchase.products[0].config.term as string,
          termKms: formattedTermKms,
        })
      : "";

  return (
    <Modal
      isOpen={show}
      alignment="center"
      onClose={onClose}
      title={t("purchase:continue_purchase")}
      disableClosing={disableClosing}
    >
      <h5 className="text-primary-deep mb-3">
        {purchase?.currentStepId === purchaseLastStep.aftermarket.id
          ? t("purchase:warranty_purchase_is_purchased")
          : t("purchase:warranty_purchase_in_progress")}
      </h5>
      <div className="flex flex-col items-center gap-1 my-8 p-4 max-w-sm mx-auto bg-gray-25 rounded-lg border-eggBlue border-[1px] shadow-elevation-01">
        <div>
          {coverageTypeName && (
            <div className="body-1">{t(coverageTypeName)}</div>
          )}
          {coverageDescription && (
            <div className="caption-4">{coverageDescription}</div>
          )}
        </div>
        <div>
          <div className="body-3">{getVehicleYmmt(purchase)}</div>
          {purchase?.vehicle?.exteriorColour && (
            <div className="caption-4 mt-1">
              {purchase?.vehicle?.exteriorColour}
            </div>
          )}
        </div>
      </div>
      {!isPurchaseComplete && (
        <p className="body-4">
          {t("purchase:coverage_overwrite_modal_helper")}
        </p>
      )}
      <Modal.Buttons
        stretchButtonToContent
        primaryButtonText={resumeButtonLabelText}
        onPrimaryClick={onResumePurchaseClick}
        secondaryButtonText={
          modalType === "default"
            ? t("purchase:purchase_a_different_coverage")
            : t("purchase:get_my_estimated_range")
        }
        secondaryButtonFill="link"
        onSecondaryClick={onOverwritePurchaseClick}
        hideSecondaryButton={
          purchase?.currentStepId === purchaseLastStep.aftermarket.id
        }
      />
    </Modal>
  );
};

export default CoverageOverwriteModal;
