import { PaymentFrequency } from "@modules/vehicle/types/PaymentFrequency";
import { createContext, Dispatch, SetStateAction } from "react";

export type FinancingPageState = {
  paymentAmount: number;
  paymentFrequency: PaymentFrequency | null;
};

export type C2CPaymentFinanceContextState = {
  financingState: [
    FinancingPageState,
    Dispatch<SetStateAction<FinancingPageState>>
  ];
};

const FinancingPaymentAmountContext =
  createContext<C2CPaymentFinanceContextState>(
    {} as C2CPaymentFinanceContextState
  );

export default FinancingPaymentAmountContext;
