import Label from "@common/components/form/Label";
import MoneyInput from "@common/components/form/MoneyInput";
import {
  formatWithSymbol,
  getLoanTerms,
  LoanTerm,
} from "@modules/vehicle/components/vdp/PurchaseOptions";
import PaymentFrequencyOption from "@modules/vehicle/components/vdp/PurchaseOptions/PaymentFrequencyOption";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import Listbox from "@common/components/form/Listbox";
import {
  formatCurrency,
  formatPercent,
} from "@common/helpers/numberFormatting";
import LineItemContainer from "@modules/vehicle/components/vdp/PurchaseOptions/LineItemContainer";
import LineItem from "@modules/vehicle/components/vdp/PurchaseOptions/LineItem";
import Button from "@common/components/Button";
import { ArrowRightIcon } from "@common/components/icons";
import useDebounce from "@common/hooks/useDebounce";
import {
  PaymentFrequencies,
  PaymentFrequency,
} from "@modules/vehicle/types/PaymentFrequency";
import translate from "@common/helpers/translate";
import useVehicleFinancePricingBreakdown from "@modules/purchase/hooks/useVehicleFinancePricingBreakdown";
import { VehiclePricingDefaultResponse } from "@modules/purchase/types/VehicleFinancePricingBreakdown";
import classNames from "classnames";
import { MAX_DECIMALS_TAX_BREAKDOWN } from "@common/constants";
import FinancingPaymentAmountContext from "@modules/purchase/ConsumerFinancingPageContext";
import useConsumerFinancingFromQuery from "@modules/purchase/hooks/useConsumerFinancingFromQuery";

type Props = {
  pricingDefaults: VehiclePricingDefaultResponse;
  onStartFinancing: () => void;
};

const FinancingCalculator = ({ pricingDefaults, onStartFinancing }: Props) => {
  const { t } = useTranslation(["common", "vehicleDisplayPage", "purchase"]);
  const router = useRouter();
  const locale = router?.locale || "en";
  const [loanTerms] = useState<LoanTerm[]>(getLoanTerms(t));
  const { financingState } = useContext(FinancingPaymentAmountContext);
  const [, setFinancingState] = financingState ?? [{}];

  const {
    province: provinceAbbr,
    purchasePrice,
    year,
  } = useConsumerFinancingFromQuery();
  const [purchaseAmount, setPurchaseAmount] = useState<number>(
    purchasePrice || 0
  );

  const [paymentFrequency, setPaymentFrequency] = useState<PaymentFrequency>(
    () =>
      Object.values(PaymentFrequencies).find(
        (pf) => pf.id === pricingDefaults.paymentFrequencyId
      ) || PaymentFrequencies.biWeekly
  );

  const [loanTerm, setLoanTerm] = useState<LoanTerm>(
    () =>
      loanTerms.find(
        (lt) => lt.months === (pricingDefaults.maxTermInMonths as number)
      ) ??
      loanTerms.find((lt) => lt.months === pricingDefaults.termInMonths) ??
      loanTerms[0]
  );
  const [cashDown, setCashDown] = useState<number>(
    pricingDefaults.cashDownAmount
  );

  const debouncedCashDown = useDebounce(cashDown);
  const debouncedPurchaseAmount = useDebounce(purchaseAmount);

  const { pricingBreakdown } = useVehicleFinancePricingBreakdown({
    sellingPrice: debouncedPurchaseAmount,
    cashDownAmount: debouncedCashDown,
    paymentFrequencyId: paymentFrequency.id,
    termInMonths: loanTerm.months,
    buyerProvince: provinceAbbr || "",
    vehicleYear: year || 0,
  });

  useEffect(() => {
    if (setFinancingState) {
      setFinancingState({
        paymentAmount: pricingBreakdown?.paymentAmount || 0,
        paymentFrequency,
      });
    }
  }, [paymentFrequency, pricingBreakdown?.paymentAmount, setFinancingState]);

  return (
    <div className="w-full">
      <div className="bg-primary-deep rounded-2xl p-4 md:py-6 lg:px-10 lg:pt-8 lg:pb-6 mt-6 md:mt-5">
        <div className="flex gap-x-6 lg:gap-x-24 flex-col md:flex-row">
          <div className="basis-[45%]">
            <h5 className="text-white md:mt-3">
              {t("vehicleDisplayPage:cash_down_payment")}
            </h5>
            <div className="flex items-end gap-4 mt-3 md:mt-6">
              <div id="trade-in-estimate-input" className="flex-1">
                <MoneyInput
                  id="vehiclePrice"
                  label={t("vehicleDisplayPage:vehicle_price")}
                  labelTextStyle="light-gray"
                  locale={locale || ""}
                  value={purchaseAmount}
                  onChange={(e) => setPurchaseAmount(e)}
                />
              </div>
            </div>
            <div className="mt-5 md:mt-7">
              <MoneyInput
                id="cashDown"
                label={t("vehicleDisplayPage:cash_down_payment")}
                labelTextStyle="light-gray"
                locale={locale || ""}
                value={cashDown}
                onChange={(e) => setCashDown(e)}
              />
            </div>
            <div className="mt-5 md:mt-7">
              <Label
                label={t("common:payment_frequency")}
                textStyle="light-gray"
              />
              <div className="flex gap-x-4 mt-2">
                <PaymentFrequencyOption
                  text={translate(PaymentFrequencies.monthly.name, locale)}
                  isSelected={
                    paymentFrequency.id === PaymentFrequencies.monthly.id
                  }
                  onClick={() =>
                    setPaymentFrequency(PaymentFrequencies.monthly)
                  }
                />

                <PaymentFrequencyOption
                  text={translate(PaymentFrequencies.biWeekly.name, locale)}
                  isSelected={
                    paymentFrequency.id === PaymentFrequencies.biWeekly.id
                  }
                  onClick={() =>
                    setPaymentFrequency(PaymentFrequencies.biWeekly)
                  }
                />
              </div>
            </div>
            <div className="mt-5 md:mt-7">
              <Listbox
                items={loanTerms}
                label={t("vehicleDisplayPage:loan_length")}
                labelTextStyle="light-gray"
                keyOption={(item) => item.id}
                displayOption={(item) => (item as unknown as LoanTerm).name}
                displayButton={(item) => (item as unknown as LoanTerm).name}
                valueOption={(item) => item}
                selectedItem={loanTerm}
                onChange={(item) => {
                  setLoanTerm(item);
                }}
              />
            </div>
          </div>
          <div className="basis-[55%]">
            <div className="flex gap-x-4 justify-between mt-7 md:mt-0">
              <h5 className="text-white w-44">
                {t("vehicleDisplayPage:your_estimated_financing_terms")}
              </h5>
              <div className="pt-4 pb-2 px-5 min-w-[9.81rem] bg-primary-light rounded-lg rounded-b-none">
                <div className="text-2xl leading-6 text-center font-semibold text-primary-bold">
                  {`${formatCurrency(
                    pricingBreakdown?.paymentAmount || 0,
                    locale
                  )}*`}
                </div>
                <div className="text-sm text-center font-medium text-gray-600 tracking-wider uppercase">
                  {translate(paymentFrequency.name, locale)}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-y-4 p-4 bg-white rounded-2xl rounded-tr-none">
              <LineItemContainer
                title={t("vehicleDisplayPage:terms_breakdown")}
              >
                <LineItem
                  name={t("vehicleDisplayPage:vehicle_price")}
                  value={formatCurrency(purchaseAmount, locale)}
                />
                <LineItem
                  id="interest-rate-line-item"
                  name={t("common:interest_rate")}
                  value={formatPercent(
                    pricingBreakdown?.interestRate ??
                      pricingDefaults.interestRate,
                    locale
                  )}
                />
                <LineItem
                  name={t("vehicleDisplayPage:loan_length")}
                  value={loanTerm.name}
                />
              </LineItemContainer>

              {provinceAbbr && (
                <LineItemContainer
                  title={t("vehicleDisplayPage:tax_breakdown")}
                >
                  {pricingBreakdown?.taxes.map((tax) => (
                    <LineItem
                      key={tax.name}
                      name={`${tax.name} (${formatPercent(
                        tax.rate,
                        locale,
                        MAX_DECIMALS_TAX_BREAKDOWN
                      )})`}
                      value={formatCurrency(tax.amount, locale)}
                    />
                  ))}
                </LineItemContainer>
              )}

              <div className="flex justify-between items-baseline">
                <h6 className="text-lg leading-6 font-semibold text-primary-deep">
                  {t("vehicleDisplayPage:cash_down_payment")}
                </h6>
                <div
                  className={classNames("text-base leading-6 pr-1", {
                    "text-primary-bold font-semibold": cashDown > 0,
                    "text-primary-deep font-medium": cashDown <= 0,
                  })}
                >
                  {formatWithSymbol(
                    cashDown,
                    formatCurrency(cashDown, locale, {
                      showCents: cashDown > 0 ? "always" : "never",
                    })
                  )}
                </div>
              </div>

              <div className="flex justify-between items-baseline">
                <h6 className="text-lg leading-6 font-semibold text-primary-deep">
                  {t("vehicleDisplayPage:estimated_total_cost")}
                </h6>
                <div className="text-lg leading-6 text-primary-deep font-semibold">
                  {formatCurrency(
                    pricingBreakdown?.amountFinanced || 0,
                    locale
                  )}
                </div>
              </div>

              <div className="mx-auto my-4">
                <Button
                  spacing="hug"
                  rightIcon={<ArrowRightIcon />}
                  onClick={onStartFinancing}
                >
                  {t("common:apply_for_financing")}
                </Button>
              </div>
            </div>
          </div>
        </div>

        <p className="my-5 caption-4 text-white">
          {t("vehicleDisplayPage:purchase_options_fine_print")}
        </p>
      </div>
    </div>
  );
};

export default FinancingCalculator;
