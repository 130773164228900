import useAftermarketPurchaseFromQuery from "@modules/purchase/hooks/useAftermarketPurchaseFromQuery";
import useSourceInfoFromQuery from "@modules/query-string/hooks/useSourceInfoFromQuery";
import { useRouter } from "next/router";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormProvider, useForm } from "react-hook-form";
import { provinceFilters } from "@modules/purchase/hooks/useEligibleProvinces";
import useProvincesStatusBySourceList from "@modules/purchase/hooks/useProvincesStatusBySourceList";
import { getCondensedCbbVehicleName } from "@modules/vehicle/hooks/useCbbVehicleLookup";
import { useNullableNumberMask } from "@common/hooks/useMask";
import useVehicleDisplayFuelTypes from "@modules/vehicle/hooks/useVehicleDisplayFuelTypes";
import translate from "@common/helpers/translate";
import { AftermarketDiscountConvertData } from "@modules/purchase/types/AftermarketDiscountConvertData";
import { ExperienceResult } from "@modules/core/convert/hooks/useConvertExperience";
import { Trans, useTranslation } from "next-i18next";
import { provinces } from "@common/types/Province";
import { getApproximateBuyingTimeframeById } from "@common/types/ApproximateBuyingTimeframe";
import { removeQueryParams } from "@modules/core/routing/routingHelpers";
import {
  quoteFormMode,
  WarrantySegmentationMode,
  WarrantySegmentationModeName,
} from "./types/WarrantySegmentationMode";
import { WarrantySegmentationFormProps } from "./types/WarrantySegmentationFormProps";
import WarrantyVehicleOwnershipStatusQuestionnaireSegment from "./WarrantyVehicleOwnershipStatusQuestionnaireSegment";
import WarrantyApproximateBuyingTimeframeQuestionnaireSegment from "./WarrantyApproximateBuyingTimeframeQuestionnaireSegment";
import WarrantyQuoteForm from "./WarrantyQuoteForm";
import WarrantyEstimatedCoverageOptions from "./WarrantyEstimatedCoverageOptions";

type Props = {
  modeState: [
    WarrantySegmentationMode,
    Dispatch<SetStateAction<WarrantySegmentationMode>>
  ];
  convertResult?: ExperienceResult<AftermarketDiscountConvertData>;
};

const WarrantySegmentationForm = ({ modeState, convertResult }: Props) => {
  const { t } = useTranslation(["common", "purchase"]);
  const [mode, setMode] = modeState;
  const router = useRouter();
  const { locale: rlocale } = router;
  const locale = rlocale || "en";

  const switchMode = useCallback(
    (newMode: WarrantySegmentationMode) => {
      setMode(newMode);
    },
    [setMode]
  );

  const aftermarketPurchaseFromQuery = useAftermarketPurchaseFromQuery();
  const { source: querySource } = useSourceInfoFromQuery();

  const {
    firstName,
    lastName,
    email,
    phone,
    province: buyerProvince,
    vin: buyerVin,
    mileage,
    year,
    make,
    model,
    trim,
    fuelType,
  } = aftermarketPurchaseFromQuery;

  const { promo, skipToQuoteForm } = router.query;

  const provincesList = useProvincesStatusBySourceList(
    querySource,
    provinceFilters.isAftermarketEnabled
  );

  const { maskPipe: numberMaskPipe } = useNullableNumberMask();

  const { fuelTypes } = useVehicleDisplayFuelTypes();
  const fuelTypeOptions: { id: number; name: string }[] | null = useMemo(
    () =>
      fuelTypes.length > 0
        ? fuelTypes.map(({ id, name }) => ({
            id,
            name: translate(name, locale),
          }))
        : null,
    [fuelTypes, locale]
  );

  const methods = useForm<WarrantySegmentationFormProps>({
    shouldFocusError: false,
    defaultValues: {
      firstName: firstName ?? "",
      lastName: lastName ?? "",
      email: email ?? "",
      phone: phone ?? "",
      province: buyerProvince
        ? provincesList.find((p) => p.abbr === buyerProvince)
        : undefined,
      vin: buyerVin ?? "",
      odometer: mileage ? numberMaskPipe(mileage.toString()) : "",
      consent: false,
      yearMakeModel:
        year && make && model && model !== "Other" && model !== "Autre"
          ? {
              year,
              make,
              model,
              trim: trim ?? undefined,
              name: getCondensedCbbVehicleName(year, make, model, trim),
            }
          : undefined,
      fuelType: fuelType
        ? fuelTypeOptions?.find((ft) => ft.name === fuelType)
        : undefined,
      promoCode: (promo as string)?.toUpperCase(),
    },
  });

  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (router.isReady && firstLoad) {
      methods.reset({
        firstName: firstName ?? "",
        lastName: lastName ?? "",
        email: email ?? "",
        phone: phone ?? "",
        province: buyerProvince
          ? provinces.find((p) => p.abbr === buyerProvince)
          : undefined,
        vin: buyerVin ?? "",
        odometer: mileage ? numberMaskPipe(mileage.toString()) : "",
        consent: false,
        yearMakeModel:
          year && make && model && model !== "Other" && model !== "Autre"
            ? {
                year,
                make,
                model,
                trim: trim ?? undefined,
                name: getCondensedCbbVehicleName(year, make, model, trim),
              }
            : undefined,
        fuelType: fuelType
          ? fuelTypeOptions?.find((ft) => ft.name === fuelType)
          : undefined,
        promoCode: (promo as string)?.toUpperCase(),
      });
      setFirstLoad(false);
    }
  }, [
    buyerProvince,
    buyerVin,
    email,
    firstLoad,
    firstName,
    fuelType,
    fuelTypeOptions,
    lastName,
    make,
    methods,
    mileage,
    model,
    numberMaskPipe,
    phone,
    promo,
    router.isReady,
    trim,
    year,
  ]);

  useEffect(() => {
    if (skipToQuoteForm) {
      switchMode(quoteFormMode);
      removeQueryParams(router, ["skipToQuoteForm"]);
    }
  }, [router, skipToQuoteForm, switchMode]);

  const selectedApproximateBuyingTimeframe = methods.watch(
    "selectedApproximateBuyingTimeframe"
  );

  const approximateBuyingTimeframe = getApproximateBuyingTimeframeById(
    selectedApproximateBuyingTimeframe ?? null
  )?.name;

  return (
    <div>
      <div className="flex flex-col items-center justify-center sm:px-1 md:px-6">
        <div className="w-full max-w-md">
          <h3 className="mb-6 text-center justify-center">
            {mode.name ===
              WarrantySegmentationModeName.vehicleOwnershipStatus &&
              t("purchase:lets_get_started")}
            {mode.name ===
              WarrantySegmentationModeName.approximateBuyingTimeframe &&
              t("common:sounds_good")}
            {mode.name === WarrantySegmentationModeName.quoteForm &&
              t("purchase:get_a_link_to_your_quote")}
            {mode.name ===
              WarrantySegmentationModeName.estimatedCoverageOption &&
              t("purchase:get_estimated_coverage_options")}
          </h3>
          <div className="mb-6 body-2 text-center justify-center">
            {mode.name ===
              WarrantySegmentationModeName.vehicleOwnershipStatus &&
              t("purchase:we_noticed_you_stopped_by_kijiji")}
            {mode.name ===
              WarrantySegmentationModeName.approximateBuyingTimeframe &&
              t("purchase:how_soon_purchase_vehicle")}
            {mode.name === WarrantySegmentationModeName.quoteForm &&
              t("purchase:fill_out_information_and_will_send_link")}
            {mode.name ===
              WarrantySegmentationModeName.estimatedCoverageOption && (
              <Trans
                t={t}
                i18nKey="purchase:lets_provide_you_with_a_free_estimate"
                values={{
                  approximateBuyingTimeframe: approximateBuyingTimeframe
                    ? t(approximateBuyingTimeframe)
                    : "",
                }}
                components={{
                  bold: <span className="font-semibold lowercase" />,
                }}
              />
            )}
          </div>
        </div>
      </div>
      {/* eslint-disable react/jsx-props-no-spreading */}
      <FormProvider {...methods}>
        <div>
          {mode?.name ===
            WarrantySegmentationModeName.vehicleOwnershipStatus && (
            <WarrantyVehicleOwnershipStatusQuestionnaireSegment
              onChangeMode={switchMode}
            />
          )}
          {mode?.name ===
            WarrantySegmentationModeName.approximateBuyingTimeframe && (
            <WarrantyApproximateBuyingTimeframeQuestionnaireSegment
              onChangeMode={switchMode}
            />
          )}
          {mode?.name === WarrantySegmentationModeName.quoteForm && (
            <WarrantyQuoteForm
              convertResult={convertResult}
              onChangeMode={switchMode}
            />
          )}
          {mode?.name ===
            WarrantySegmentationModeName.estimatedCoverageOption && (
            <WarrantyEstimatedCoverageOptions onChangeMode={switchMode} />
          )}
        </div>
      </FormProvider>
    </div>
  );
};

export default WarrantySegmentationForm;
