import Button from "@common/components/Button";
import { cleanParagraphTags } from "@common/helpers/cleanHtml";
import { VLP_PATH } from "@common/helpers/isVehicleListingPage";
import translate from "@common/helpers/translate";
import { setBodyStyleIcon } from "@modules/vehicle/components/VehicleBodyStylesFilter";
import cleanVlpQueryParam from "@modules/vehicle/helpers/cleanVlpQueryParam";
import useGetVehicleFilterData from "@modules/vehicle/hooks/useVehicleFilterData";
import Link from "next/link";
import { BodyStylesLayoutFields } from "../types/ButtonGroupsLayout";

type Props = {
  locale: string;
} & Omit<BodyStylesLayoutFields, "typeName">;

const iconClassName = "m-auto w-20 h-16";

const BodyStylesLayout = ({ locale, subheading = "", bodyStyles }: Props) => {
  const { vehicleFilters: vehicleFiltersData } = useGetVehicleFilterData();

  return (
    <div>
      {subheading && (
        <h3
          className="pb-4"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: cleanParagraphTags(subheading),
          }}
        />
      )}
      <div className="body-3 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-8 gap-2">
        {bodyStyles?.map((bs) => {
          const bodyStyle = vehicleFiltersData?.bodyStyles.find(
            (b) => b.id === parseInt(bs, 10)
          );

          return (
            bodyStyle && (
              <Link
                href={`/${VLP_PATH}/${cleanVlpQueryParam(
                  translate(bodyStyle.name, locale || "")
                )}`}
                key={`link-body-style-${bodyStyle.id}`}
              >
                <Button
                  buttonStyle="filter"
                  fill="outline"
                  className="rounded-[4px] active:bg-gray-25"
                >
                  <div className="py-1">
                    {setBodyStyleIcon(
                      bodyStyle.id,
                      `${iconClassName} text-primary-bold`
                    )}
                    {translate(bodyStyle.name, locale)}
                  </div>
                </Button>
              </Link>
            )
          );
        })}
      </div>
    </div>
  );
};

export default BodyStylesLayout;
