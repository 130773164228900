export enum WarrantyInstantQuoteModalModeName {
  vehicleInfo = "vehicleInfo",
  personalInfo = "personalInfo",
  quoteGenerated = "quoteGenerated",
}

export type WarrantyInstantQuoteModalMode = {
  name: WarrantyInstantQuoteModalModeName;
  modalHeaderTextKey: string;
};

export const vehicleInfoMode: WarrantyInstantQuoteModalMode = {
  name: WarrantyInstantQuoteModalModeName.vehicleInfo,
  modalHeaderTextKey: "purchase:instant_quote",
};

export const personalInfoMode: WarrantyInstantQuoteModalMode = {
  name: WarrantyInstantQuoteModalModeName.personalInfo,
  modalHeaderTextKey: "purchase:instant_quote",
};

export const quoteGeneratedMode: WarrantyInstantQuoteModalMode = {
  name: WarrantyInstantQuoteModalModeName.quoteGenerated,
  modalHeaderTextKey: "purchase:we_are_on_it",
};
