import SigningImage from "./signing.png";
import DoorHandleImage from "./door-handle.png";
import KeysOfferingImage from "./keys-offering.png";
import SeatbeltImage from "./seatbelt.png";
import SteeringWheelImage from "./steering-wheel.png";
import UploadOnPhoneImage from "./upload-on-phone.png";
import BubblesWarrantyEn from "./bubbles-warranty-en.png";
import BubblesWarrantyFr from "./bubbles-warranty-fr.png";
import WarrantyHeroEn from "./warranty-hero-en.png";
import WarrantyHeroFr from "./warranty-hero-fr.png";
import LinkBackEmail from "./link-back-email.png";
import WarrantyCompleteBubblesEn from "./warranty-complete-bubbles-en.png";
import WarrantyCompleteBubblesFr from "./warranty-complete-bubbles-fr.png";
import WarrantyCompleteEn from "./warranty-complete-en.png";
import WarrantyCompleteFr from "./warranty-complete-fr.png";

export {
  SigningImage,
  DoorHandleImage,
  KeysOfferingImage,
  SeatbeltImage,
  SteeringWheelImage,
  UploadOnPhoneImage,
  BubblesWarrantyEn,
  BubblesWarrantyFr,
  WarrantyHeroEn,
  WarrantyHeroFr,
  LinkBackEmail,
  WarrantyCompleteBubblesEn,
  WarrantyCompleteBubblesFr,
  WarrantyCompleteEn,
  WarrantyCompleteFr,
};
