import {
  Controller,
  SubmitHandler,
  useForm,
  useFormContext,
} from "react-hook-form";
import DivCheckbox from "@common/components/DivCheckbox";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@common/components/Button";
import ArrowLeftIcon from "@common/components/icons/ArrowLeftIcon";
import { ArrowRightIcon } from "@common/components/icons";
import useAftermarketPurchase from "@modules/purchase/hooks/useAftermarketPurchase";
import { tryGetActiveAftermarketPurchase } from "@modules/purchase/types/Purchase";
import {
  aftermarketDetailsStep,
  getStepById,
} from "@modules/purchase/types/PurchaseStep";
import { useRouter } from "next/router";
import {
  quoteMethods,
  QuoteMethod,
  QuoteMethodEnum,
  getQuoteMethods,
} from "@common/enums/QuoteMethod";
import customerValidation from "@common/helpers/validations/customerValidationDeprecated";
import Form from "@common/components/form";
import PhoneInput from "@common/components/form/PhoneInput";
import {
  useNullableNumberMask,
  usePhoneNumberMask,
} from "@common/hooks/useMask";
import useAftermarketPurchaseFromQuery from "@modules/purchase/hooks/useAftermarketPurchaseFromQuery";
import useSourceInfoFromQuery from "@modules/query-string/hooks/useSourceInfoFromQuery";
import useProvincesStatusBySourceList from "@modules/purchase/hooks/useProvincesStatusBySourceList";
import { provinceFilters } from "@modules/purchase/hooks/useEligibleProvinces";
import useCreateAftermarketLeadMutation from "@modules/purchase/hooks/useCreateAftermarketLeadMutation";
import useVehicleDisplayFuelTypes from "@modules/vehicle/hooks/useVehicleDisplayFuelTypes";
import translate from "@common/helpers/translate";
import useUtmParamsFromQuery from "@modules/query-string/hooks/useUtmParamsFromQuery";
import OverlayLoader from "@common/components/OverlayLoader";
import DealSegment from "@common/enums/DealSegment";
import { ParsedUrlQueryInput } from "querystring";
import {
  WarrantySegmentationMode,
  vehicleOwnershipStatusMode,
} from "./types/WarrantySegmentationMode";
import { WarrantySegmentationFormProps } from "./types/WarrantySegmentationFormProps";
import CoverageOverwriteModal from "../coverage/CoverageOverwriteModal";
import { RANGE_QUOTE_SEGMENT } from "./types/DealSegmentConstants";

type Props = {
  onChangeMode(newMode: WarrantySegmentationMode): void;
};

type EstimatedCoverageOptionFormInput = {
  email?: string;
  phone?: string;
  quoteMethod: QuoteMethod;
};

const WarrantyEstimatedCoverageOptions = ({ onChangeMode }: Props) => {
  const { t } = useTranslation(["common", "purchase"]);
  const router = useRouter();
  const { locale: rlocale, query } = router;
  const locale = rlocale || "en";

  const aftermarketPurchaseFromQuery = useAftermarketPurchaseFromQuery();
  const { source: querySource } = useSourceInfoFromQuery();

  const {
    purchase: purchaseResponse,
    isFetching: isPurchaseFetching,
    isLoading: isPurchaseLoading,
  } = useAftermarketPurchase();
  const purchase = tryGetActiveAftermarketPurchase(purchaseResponse);

  const { watch: questionnaireInputWatch } =
    useFormContext<WarrantySegmentationFormProps>();

  const selectedApproximateBuyingTimeframe = questionnaireInputWatch(
    "selectedApproximateBuyingTimeframe"
  );
  const selectedVehicleOwnershipStatus = questionnaireInputWatch(
    "selectedVehicleOwnershipStatus"
  );

  const createAftermarketLeadMutation = useCreateAftermarketLeadMutation();

  const {
    firstName,
    lastName,
    email,
    phone,
    province: buyerProvince,
    vin: buyerVin,
    mileage,
    year,
    make,
    model,
    trim,
    fuelType,
    coverImage,
    sourceAdId,
    sourceUserId,
    sourceSessionId,
    sourceAppId,
    sourceLocationId,
    sourceCtaId,
  } = aftermarketPurchaseFromQuery;

  const { promo } = router.query;

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    register,
    getValues,
    formState: { errors },
  } = useForm<EstimatedCoverageOptionFormInput>({
    defaultValues: {
      email: email ?? "",
      phone: phone ?? "",
      quoteMethod: getQuoteMethods(QuoteMethodEnum.Email),
    },
  });

  const { unMaskPipe: phoneUnMaskPipe } = usePhoneNumberMask();
  const { unMaskPipe: numberUnMaskPipe } = useNullableNumberMask();

  // Watch for the selected quote method
  const selectedQuoteMethod = watch("quoteMethod");

  const provincesList = useProvincesStatusBySourceList(
    querySource,
    provinceFilters.isAftermarketEnabled
  );

  const { fuelTypes } = useVehicleDisplayFuelTypes();
  const fuelTypeOptions: { id: number; name: string }[] | null = useMemo(
    () =>
      fuelTypes.length > 0
        ? fuelTypes.map(({ id, name }) => ({
            id,
            name: translate(name, locale),
          }))
        : null,
    [fuelTypes, locale]
  );

  const utmParams = useUtmParamsFromQuery();

  const [showOverlay, setShowOverlay] = useState(false);

  const sendLeadForm = useCallback(
    (
      formInput: EstimatedCoverageOptionFormInput,
      abandonExistingDeals = false
    ) => {
      const { phone: formPhone, email: formEmail, quoteMethod } = formInput;

      createAftermarketLeadMutation.mutate(
        {
          firstName: firstName ?? "",
          lastName: lastName ?? "",
          phone: formPhone ?? "",
          email: formEmail ?? "",
          province: buyerProvince
            ? provincesList.find((p) => p.abbr === buyerProvince)?.abbr ?? ""
            : "",
          odometer: mileage ? numberUnMaskPipe(mileage.toString()) ?? 0 : 0,
          source: querySource,
          vin: buyerVin ?? "",
          year: year ?? null,
          make: make ?? null,
          model: model ?? null,
          trim: trim ?? null,
          promoCodeId: promo ? (promo as string)?.toUpperCase() : null,
          isConsentGiven: false,
          sourceAdId,
          sourceUserId,
          sourceSessionId,
          sourceAppId,
          sourceLocationId,
          sourceCtaId,
          vehicleCoverImage: coverImage,
          quoteMethod: quoteMethod.id ?? null,
          dealSegment: DealSegment.RangeQuote,
          fuelType: fuelType
            ? fuelTypeOptions?.find((ft) => ft.name === fuelType)?.name ?? ""
            : "",
          selectedApproximateBuyingTimeframe:
            selectedApproximateBuyingTimeframe ?? null,
          selectedVehicleOwnershipStatus:
            selectedVehicleOwnershipStatus ?? null,
          discount: 0,
          abandonExistingDeals,
          locale,
          ...utmParams,
        },
        {
          onSuccess: () => {
            const removeEmptyValues = (obj: Record<string, unknown>) => {
              return Object.fromEntries(
                Object.entries(obj).filter(
                  ([, value]) =>
                    value !== "" &&
                    value !== undefined &&
                    (typeof value === "string" ||
                      typeof value === "number" ||
                      typeof value === "boolean" ||
                      Array.isArray(value))
                )
              );
            };

            // Keep the query params as passed but filter out empty values
            const { loginSuccessful, ...restOfQuery } = query;

            const filteredQuery = removeEmptyValues({
              ...restOfQuery,
              vin: buyerVin || undefined,
              year: !buyerVin ? year ?? undefined : undefined,
              make: !buyerVin ? make ?? undefined : undefined,
              model: !buyerVin ? model ?? undefined : undefined,
              trim: !buyerVin ? trim ?? undefined : undefined,
              fuelType: !buyerVin ? fuelType ?? undefined : undefined,
              buyerProvince: buyerProvince
                ? provincesList.find((p) => p.abbr === buyerProvince)?.abbr ??
                  ""
                : undefined,
              buyerFirst: firstName ?? "",
              buyerLast: lastName ?? "",
              buyerEmail: formEmail,
              buyerPhoneNumber: phoneUnMaskPipe(formPhone),
              odometer: mileage ?? 0,
              dealSegment: RANGE_QUOTE_SEGMENT,
            }) as ParsedUrlQueryInput;

            router.push({
              pathname: "/purchase/coverage/quote",
              query: filteredQuery,
            });
          },
          onError: () => {
            setShowOverlay(false);
          },
        }
      );
    },
    [
      buyerProvince,
      buyerVin,
      coverImage,
      createAftermarketLeadMutation,
      firstName,
      fuelType,
      fuelTypeOptions,
      lastName,
      locale,
      make,
      mileage,
      model,
      numberUnMaskPipe,
      phoneUnMaskPipe,
      promo,
      provincesList,
      query,
      querySource,
      router,
      selectedApproximateBuyingTimeframe,
      selectedVehicleOwnershipStatus,
      sourceAdId,
      sourceAppId,
      sourceCtaId,
      sourceLocationId,
      sourceSessionId,
      sourceUserId,
      trim,
      utmParams,
      year,
    ]
  );

  const [showOverwriteModal, setShowOverwriteModal] = useState(false);

  const handleFormSubmit: SubmitHandler<EstimatedCoverageOptionFormInput> = (
    formInput: EstimatedCoverageOptionFormInput
  ) => {
    if (isPurchaseLoading || isPurchaseFetching) return;

    if (purchase?.currentStepId || purchase?.overwriteCandidate) {
      setShowOverwriteModal(true);
    } else {
      sendLeadForm(formInput);
    }
  };

  const handleCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>,
    objectValue: QuoteMethod
  ) => {
    setValue("quoteMethod", objectValue);
  };

  const onClickBackButton = () => {
    onChangeMode(vehicleOwnershipStatusMode);
  };

  const showResumeButton = !!purchase && purchase.currentStepId != null;

  const handleOnResumePurchaseClick = () => {
    if (!purchase) return;

    const purchaseStep = getStepById(
      "aftermarket",
      purchase?.currentStepId ?? aftermarketDetailsStep.id
    );

    if (purchaseStep) {
      router.push({
        pathname: purchaseStep.href,
      });
    }
  };

  const handleOnOverwritePurchaseClick = () => {
    const formInput = getValues();

    sendLeadForm(formInput, true);
  };

  return (
    <OverlayLoader isLoading={showOverlay}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="flex flex-col gap-3">
          <ul className="body-3 grid grid-cols-2 gap-2 auto-rows-fr">
            {quoteMethods &&
              quoteMethods.map((qm) => (
                <li key={qm.id}>
                  <Controller
                    name="quoteMethod"
                    control={control}
                    render={() => (
                      <DivCheckbox
                        id={`quoteMethod-${qm.name}`}
                        readOnly
                        objectValue={{
                          id: qm.id,
                          name: qm.name,
                        }}
                        className="flex-col bg-white p-4"
                        onChangeEvent={handleCheckboxChange}
                        isChecked={selectedQuoteMethod?.id === qm.id}
                      >
                        {qm.icon}
                        <span className="mt-4">{t(qm.name)}</span>
                      </DivCheckbox>
                    )}
                  />
                </li>
              ))}
          </ul>

          {selectedQuoteMethod?.id === QuoteMethodEnum.Email && (
            <Form.Input
              label={t("common:email")}
              maxLength={customerValidation.email.maxLength}
              feedback={
                errors.email?.type === "required" && (
                  <p className="text-error-500">
                    {t("common:form_error_required_email")}
                  </p>
                )
              }
              {...register("email", {
                required: true,
                pattern: customerValidation.email.pattern,
                maxLength: customerValidation.email.maxLength,
              })}
            />
          )}

          {selectedQuoteMethod?.id === QuoteMethodEnum.Sms && (
            <PhoneInput
              label={t("common:phone")}
              feedback={errors.phone?.message}
              status={errors.phone ? "error" : "default"}
              {...register("phone", {
                required: {
                  value: true,
                  message: t("common:form_error_required_phone_number"),
                },
                validate: {
                  minLength: (value) =>
                    customerValidation.phoneNumber.valid(
                      phoneUnMaskPipe(value)
                    ) || t("common:phone_min_length"),
                  validateAreaCode: (value) =>
                    customerValidation.phoneNumber.validAreaCode(
                      phoneUnMaskPipe(value)
                    ) || t("common:form_error_invalid_phone_number"),
                },
              })}
            />
          )}
        </div>

        <div className="flex flex-col items-center space-y-4 mt-6">
          <Button type="submit" size="extra-large">
            {t("purchase:get_my_estimated_range")}
          </Button>
        </div>
      </form>

      {showResumeButton && (
        <div className="flex flex-col items-center space-y-4 mt-6">
          <Button
            rightIcon={<ArrowRightIcon />}
            onClick={handleOnResumePurchaseClick}
            buttonStyle="borderless"
            fill="link"
            spacing="no-padding"
            className="text-primary-bold underline hover:no-underline"
          >
            {t("purchase:resume_my_purchase")}
          </Button>
        </div>
      )}

      <div className="flex justify-center mt-6">
        <Button
          fill="link"
          buttonStyle="borderless"
          spacing="tight-hug"
          leftIcon={<ArrowLeftIcon />}
          className="text-primary-bold underline hover:no-underline"
          onClick={() => onClickBackButton()}
        >
          {t("common:go_back")}
        </Button>
      </div>
      {purchase && (
        <CoverageOverwriteModal
          purchase={purchase}
          show={showOverwriteModal}
          onClose={() => setShowOverwriteModal(false)}
          onResumePurchaseClick={handleOnResumePurchaseClick}
          onOverwritePurchaseClick={handleOnOverwritePurchaseClick}
          modalType="rangeQuote"
        />
      )}
    </OverlayLoader>
  );
};

export default WarrantyEstimatedCoverageOptions;
