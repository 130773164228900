import api from "@modules/api/api";
import { useMutation } from "@tanstack/react-query";
import { QuoteMethodEnum } from "@common/enums/QuoteMethod";
import DealSegment from "@common/enums/DealSegment";

export const decodeErrorResponse = {
  invalidVin: "Invalid VIN",
  ratesFailed: "Rates Failed",
};

type PostBody = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  year?: number | null;
  make?: string | null;
  model?: string | null;
  trim?: string | null;
  vin?: string | null;
  fuelType?: string | null;
  odometer: number;
  province: string;
  promoCodeId: string | null;
  isConsentGiven: boolean;
  source?: string | null;
  vehicleCoverImage?: string | null;
  sourceAdId?: string | null;
  sourceAppId?: string | null;
  sourceLocationId?: string | null;
  sourceSessionId?: string | null;
  sourceUserId?: string | null;
  sourceCtaId?: string | null;
  utmSource?: string | null;
  utmMedium?: string | null;
  utmCampaign?: string | null;
  utmContent?: string | null;
  quoteMethod: QuoteMethodEnum | null;
  dealSegment?: DealSegment | null;
  selectedVehicleOwnershipStatus?: number | null;
  selectedApproximateBuyingTimeframe?: number | null;
  discount?: number;
  locale: string;
  abandonExistingDeals?: boolean;
};

type CreateAftermarketLeadResponse = {
  success: boolean;
  message: string;
  decodedVin?: string;
};

const performPost = async ({ locale, ...postBody }: PostBody) => {
  const response = await api.post<CreateAftermarketLeadResponse>(
    "api/purchases/aftermarket-lead",
    {
      json: postBody,
      headers: { "Accept-Language": locale },
    }
  );
  return response;
};

export default function useCreateAftermarketLeadMutation() {
  return useMutation({ mutationFn: performPost });
}
