import api from "@modules/api/api";
import { useQuery } from "@tanstack/react-query";
import purchaseQueryKeys from "../purchaseQueryKeys";
import { VehiclePricingDefaultResponse } from "../types/VehicleFinancePricingBreakdown";

const getPricingDefaults = async (
  vehicleYear?: number | null
): Promise<VehiclePricingDefaultResponse> => {
  const params = new URLSearchParams();
  if (vehicleYear) {
    params.append("vehicleYear", vehicleYear.toString());
  }

  return api.get<VehiclePricingDefaultResponse>(
    `api/purchases/finance-pricing-defaults?${params}`
  );
};

export default function useVehicleFinancePricingDefaults(
  vehicleYear?: number | null
) {
  const { data, isLoading, isFetched } = useQuery({
    queryKey: purchaseQueryKeys.financePricingDefaults(),
    queryFn: () => getPricingDefaults(vehicleYear),
  });

  return {
    pricingDefaults: data ?? {
      termInMonths: 0,
      maxTermInMonths: 0,
      paymentFrequencyId: 0,
      interestRate: 0,
      cashDownAmount: 0,
    },
    isLoading,
    isFetched,
  };
}
