import { formatCurrency } from "@common/helpers/numberFormatting";
import translate from "@common/helpers/translate";
import FinancingPaymentAmountContext, {
  FinancingPageState,
} from "@modules/purchase/ConsumerFinancingPageContext";
import { useTranslation, Trans } from "next-i18next";
import { useRouter } from "next/router";
import { useContext, useState } from "react";
import useConsumerFinancingQuery from "@modules/purchase/hooks/useConsumerFinancingFromQuery";
import LocalImage from "@common/components/LocalImage";
import { ImageWithTranslation } from "@common/types/ImageWithTranslation";
import {
  BubblesFinancingEn,
  BubblesFinancingFr,
  FinancingHeroEn,
  FinancingHeroFr,
} from "public/images/purchase/consumer";
import { Purchase } from "@modules/purchase/types/Purchase";
import useConsumerFinancing from "@modules/purchase/hooks/useConsumerFinancing";
import OverlayLoader from "@common/components/OverlayLoader";
import useStartConsumerFinancingMutation from "@modules/purchase/hooks/useStartConsumerFinancingMutation";
import GetBackgroundStyle from "@modules/cms/helpers/getBackgroundStyle";
import { FinancingPreQualificationFormLayout } from "@modules/cms/types/FinancingPreQualificationFormLayout";
import PurchaseSourceVehicleImage from "../PurchaseSourceVehicleImage";
import FinancingPreQualificationForm from "./FinancingPreQualificationForm";
import {
  FinancingPreQualificationMode,
  introductionMode,
} from "./types/FinancingPreQualificationMode";

type Props = {
  layout: FinancingPreQualificationFormLayout;
};

const vehicleDefaultImage: ImageWithTranslation = {
  srcEn: FinancingHeroEn,
  srcFr: FinancingHeroFr,
  altKey: "purchase:alt_c2c_vehicle_img",
};

const bubblesImage: ImageWithTranslation = {
  srcEn: BubblesFinancingEn,
  srcFr: BubblesFinancingFr,
  altKey: "purchase:alt_c2c_info_img",
};

const FinancingPreQualification = ({ layout }: Props) => {
  const { t } = useTranslation(["common", "purchase"]);
  const router = useRouter();
  const { locale } = router;
  const { background } = layout;

  const { financingState } = useContext(FinancingPaymentAmountContext);

  const [state] = financingState ?? [{}];

  const [financingPreQualModeState, setFinancingPreQualModeState] =
    useState<FinancingPreQualificationMode>(introductionMode);

  const data: FinancingPageState = {
    paymentAmount: state.paymentAmount ?? 0,
    paymentFrequency: state.paymentFrequency ?? null,
  };

  const {
    consumerFinancing: consumerFinancingOrNotFound,
    isLoading: isConsumerFinancingLoading,
  } = useConsumerFinancing();
  const consumerFinancingQuery = useConsumerFinancingQuery();
  const consumerFinancing = consumerFinancingOrNotFound as Purchase;
  const { year, make, model, coverImage } = consumerFinancingQuery;
  const vehicleCoverImage = coverImage || consumerFinancing?.vehicle?.image;
  const startConsumerFinancingMutation = useStartConsumerFinancingMutation();
  return (
    <OverlayLoader
      isLoading={
        isConsumerFinancingLoading || startConsumerFinancingMutation.isPending
      }
    >
      <div style={GetBackgroundStyle(background)}>
        <div className="relative max-w-page mx-auto flex gap-4 items-center flex-col pt-10 pb-6 px-4 lg:px-5 lg:py-16 lg:flex-row">
          <div className="flex flex-col lg:basis-[55%]">
            <div className="flex-1 w-full relative">
              <div className="min-w-full h-auto relative inset-0">
                {vehicleCoverImage ? (
                  <div>
                    <div className="absolute -right-4 md:-right-1 lg:-right-14 xl:-right-10 z-[2]">
                      <LocalImage
                        className="h-48 w-40 md:h-96 md:w-96 object-contain rounded-lg"
                        src={
                          locale === "fr"
                            ? bubblesImage.srcFr
                            : bubblesImage.srcEn
                        }
                        alt={t(bubblesImage.altKey)}
                      />
                    </div>

                    <div className="flex justify-center overflow-hidden relative md:h-full">
                      <div className="rounded-full border-white border-[.5rem] mr-9 md:mr-32 lg:mr-44 h-80 w-80 md:border-[1rem] md:h-[27rem] md:w-[27rem] lg:h-[30rem] lg:w-[30rem]">
                        <PurchaseSourceVehicleImage
                          className="rounded-full w-full h-full object-cover"
                          src={vehicleCoverImage}
                          alt={t(vehicleDefaultImage.altKey)}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="overflow-auto relative h-56 md:h-96 lg:mr-4">
                    <LocalImage
                      className="z-[2] w-full object-contain h-auto rounded-lg"
                      src={
                        locale === "fr"
                          ? vehicleDefaultImage.srcFr
                          : vehicleDefaultImage.srcEn
                      }
                      alt={t(vehicleDefaultImage.altKey)}
                      fill
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="flex-1 lg:basis-[40%] lg:px-0 md:px-28">
              <div className="w-full items-center justify-center text-center lg:text-left lg:items-start lg:justify-start">
                <p className="text-text-light-100 font-semibold text-[2.25rem] md:text-[3.25rem] leading-[2.625rem] md:leading-[3.625rem]">
                  {year &&
                  make &&
                  model &&
                  data.paymentAmount !== 0 &&
                  data.paymentFrequency ? (
                    <Trans
                      t={t}
                      i18nKey="purchase:interested_in_financing_specific_vehicle"
                      values={{
                        vehicle: `${year} ${make} ${model}`,
                        payment: formatCurrency(
                          Math.round(data.paymentAmount),
                          locale || "en",
                          { showCents: "never" }
                        ),
                        frequency: translate(
                          data.paymentFrequency.name,
                          locale || "en"
                        ),
                      }}
                      components={{
                        bold: <span className="text-primary-bold" />,
                      }}
                    />
                  ) : (
                    <Trans
                      t={t}
                      i18nKey="purchase:interested_in_financing_a_vehicle"
                      components={{
                        bold: <span className="text-primary-bold" />,
                      }}
                    />
                  )}
                </p>
              </div>

              <div className="mt-2 w-full items-center justify-center text-center lg:text-left lg:items-start lg:justify-start">
                <h4 className="mt-4 lg:mt-8">
                  <Trans
                    t={t}
                    i18nKey="purchase:purchasing_a_car_description"
                    components={{
                      bold: <span className="font-semibold" />,
                    }}
                  />
                </h4>
              </div>
            </div>
          </div>
          <div className="bg-gray-25 rounded-2xl py-8 px-6 flex-1 basis-[45%]">
            <FinancingPreQualificationForm
              modeState={[
                financingPreQualModeState,
                setFinancingPreQualModeState,
              ]}
            />
          </div>
        </div>
      </div>
    </OverlayLoader>
  );
};

export default FinancingPreQualification;
