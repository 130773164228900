import { createContext } from "react";

export type SellerPageLoginFlowContextState = {
  setShowModal(value: boolean): void;
};

const AftermarketConsumerSellerPageContext =
  createContext<SellerPageLoginFlowContextState>(
    {} as SellerPageLoginFlowContextState
  );

export default AftermarketConsumerSellerPageContext;
