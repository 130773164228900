import Form from "@common/components/form";
import customerValidation from "@common/helpers/validations/customerValidationDeprecated";
import PhoneInput from "@common/components/form/PhoneInput";
import Button from "@common/components/Button";
import { useTranslation } from "next-i18next";
import { ArrowLeftIcon } from "@common/components/icons";
import { Controller, SubmitHandler, useFormContext } from "react-hook-form";
import { useNumberMask, usePhoneNumberMask } from "@common/hooks/useMask";
import { Province, provinces } from "@common/types/Province";
import Listbox from "@common/components/form/Listbox";
import { quoteMethods } from "@common/enums/QuoteMethod";
import useCreateAftermarketInstantQuoteMutation, {
  decodeErrorResponse,
} from "@modules/purchase/hooks/useCreateAftermarketInstantQuote";
import useFeatureFlags from "@modules/feature-flags/hooks/useFeatureFlags";
import { useRouter } from "next/router";
import { useCallback, useState } from "react";
import { FeatureFlags } from "@modules/feature-flags/types/FeatureFlag";
import useUser from "@modules/user/hooks/useUser";
import OverlayLoader from "@common/components/OverlayLoader";
import { WarrantyInstantQuoteFormProps } from "./types/WarrantyInstantQuoteFormProps";
import {
  quoteGeneratedMode,
  vehicleInfoMode,
  WarrantyInstantQuoteModalMode,
} from "./types/WarrantyInstantQuoteModalMode";

type Props = {
  onChangeMode(newMode: WarrantyInstantQuoteModalMode): void;
};

const WarrantyInstantQuotePersonalInfo = ({ onChangeMode }: Props) => {
  const { isEnabled } = useFeatureFlags();
  const { t } = useTranslation(["common", "purchase"]);
  const router = useRouter();
  const { locale: rlocale } = router;
  const locale = rlocale || "en";
  const { user } = useUser();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useFormContext<WarrantyInstantQuoteFormProps>();

  const { unMaskPipe: phoneUnMaskPipe } = usePhoneNumberMask();

  const createAftermarketInstantQuoteMutation =
    useCreateAftermarketInstantQuoteMutation();

  // Aftermarket quote alert should show for all users except logged in users and users who have already started a purchase
  const aftermarketQuoteMethodEnabled =
    !user?.isLoggedIn && isEnabled(FeatureFlags.AftermarketQuoteMethod);

  const onBack: () => void = () => {
    onChangeMode(vehicleInfoMode);
  };

  const { unMaskPipe: unMaskNumberPipe } = useNumberMask();

  const [showOverlay, setShowOverlay] = useState(false);

  const sendLeadForm = useCallback(
    (formInput: WarrantyInstantQuoteFormProps) => {
      const {
        firstName: formFirstName,
        lastName: formLastName,
        phone: formPhone,
        email: formEmail,
        province,
        odometer,
        vin,
        vehicleDetails,
        consent,
        quoteMethod,
      } = formInput;

      if (!province || !odometer) {
        return;
      }
      createAftermarketInstantQuoteMutation.mutate(
        {
          firstName: formFirstName,
          lastName: formLastName,
          phone: phoneUnMaskPipe(formPhone),
          email: formEmail,
          province,
          odometer: unMaskNumberPipe(odometer.toString()),
          vin: vin ?? null,
          year: vehicleDetails?.year ?? null,
          make: vehicleDetails?.make ?? null,
          model: vehicleDetails?.model ?? null,
          trim: vehicleDetails?.trim ?? null,
          isConsentGiven: consent,
          quoteMethod: aftermarketQuoteMethodEnabled ? quoteMethod.id : null,
          fuelType: vehicleDetails ? formInput.fuelType?.name ?? null : null,
          locale,
        },
        {
          onSuccess: (data) => {
            const ratesError =
              data &&
              data.success === false &&
              data.message === decodeErrorResponse.ratesFailed;

            if (aftermarketQuoteMethodEnabled && !ratesError) {
              onChangeMode(quoteGeneratedMode);
              setShowOverlay(false);
            }
          },
          onError: () => {
            setShowOverlay(false);
          },
        }
      );
    },
    [
      aftermarketQuoteMethodEnabled,
      createAftermarketInstantQuoteMutation,
      locale,
      onChangeMode,
      phoneUnMaskPipe,
      unMaskNumberPipe,
    ]
  );

  const onFormSubmit: SubmitHandler<WarrantyInstantQuoteFormProps> = (
    formInput: WarrantyInstantQuoteFormProps
  ) => {
    setShowOverlay(true);
    sendLeadForm(formInput);
  };

  return (
    <OverlayLoader isLoading={showOverlay}>
      <div className="mx-auto mb-5 lg:w-5/6 md:w-3/4 sm:w-4/5">
        <div>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <div className="flex flex-col gap-3">
              <Form.Input
                label={t("common:first_name")}
                maxLength={customerValidation.firstName.maxLength}
                feedback={
                  errors.firstName && (
                    <p className="text-error-500">{errors.firstName.message}</p>
                  )
                }
                {...register("firstName", {
                  required: { value: true, message: t("common:required") },
                  validate: customerValidation.firstName.valid,
                  maxLength: customerValidation.firstName.maxLength,
                })}
              />
              <Form.Input
                label={t("common:last_name")}
                maxLength={customerValidation.lastName.maxLength}
                feedback={
                  errors.lastName && (
                    <p className="text-error-500">{errors.lastName.message}</p>
                  )
                }
                {...register("lastName", {
                  required: { value: true, message: t("common:required") },
                  validate: customerValidation.lastName.valid,
                  maxLength: customerValidation.lastName.maxLength,
                })}
              />

              <p className="caption-3">
                {t("common:enter_name_as_displayed_on_license")}
              </p>

              <Form.Input
                label={t("common:email")}
                maxLength={customerValidation.email.maxLength}
                feedback={
                  errors.email && (
                    <p className="text-error-500">{errors.email.message}</p>
                  )
                }
                {...register("email", {
                  required: { value: true, message: t("common:required") },
                  pattern: customerValidation.email.pattern,
                  maxLength: customerValidation.email.maxLength,
                })}
              />
              <PhoneInput
                label={t("common:phone")}
                feedback={errors.phone?.message}
                status={errors.phone ? "error" : "default"}
                {...register("phone", {
                  required: { value: true, message: t("common:required") },
                  validate: {
                    minLength: (value) =>
                      customerValidation.phoneNumber.valid(
                        phoneUnMaskPipe(value)
                      ) || t("common:phone_min_length"),
                    validateAreaCode: (value) =>
                      customerValidation.phoneNumber.validAreaCode(
                        phoneUnMaskPipe(value)
                      ) || t("common:form_error_invalid_phone_number"),
                  },
                })}
              />
              <Controller
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t("common:required"),
                  },
                }}
                name="province"
                render={({ field: { onChange, value, ref } }) => {
                  const provValue = provinces.find((p) => p.abbr === value);

                  return (
                    <Listbox
                      items={provinces}
                      label={t("common:province")}
                      keyOption={(item) => item.abbr}
                      displayOption={(item) =>
                        item?.name != null ? t(item.name) : ""
                      }
                      displayButton={(item) =>
                        item?.name != null
                          ? t(item.name)
                          : t("common:select_province")
                      }
                      valueOption={(item) => item}
                      status={errors.province ? "error" : "default"}
                      feedback={errors.province?.message}
                      onChange={(provinceInput: Province) => {
                        onChange(provinceInput.abbr);
                      }}
                      selectedItem={provValue}
                      defaultButtonClassName="text-slate-400"
                      menuPosition="Up"
                      buttonRef={ref}
                    />
                  );
                }}
              />
              <Controller
                name="quoteMethod"
                control={control}
                rules={{ required: true }}
                defaultValue={quoteMethods[0]}
                render={({ field: { onChange, ref } }) => (
                  <Listbox
                    label={t("purchase:send_me_my_quote_via")}
                    onChange={onChange}
                    selectedItem={watch("quoteMethod")}
                    items={quoteMethods}
                    displayOption={(item) => t(item?.name) ?? ""}
                    displayButton={(item) =>
                      item?.name != null
                        ? t(item.name)
                        : t(quoteMethods[0].name)
                    }
                    keyOption={(p) => p.id}
                    valueOption={(p) => p}
                    buttonRef={ref}
                    feedback={
                      errors.quoteMethod?.type === "required" && (
                        <p className="text-error-500">
                          {t("purchase:quote_type_required")}
                        </p>
                      )
                    }
                  />
                )}
              />
            </div>
            <div className="mt-6">
              <div className="flex flex-col items-center space-y-4 mt-6">
                <Button type="submit" size="extra-large">
                  {t("purchase:send_me_my_quote")}
                </Button>
              </div>
            </div>
          </form>
          <div className="flex justify-center mt-6">
            <Button
              fill="link"
              buttonStyle="borderless"
              spacing="tight-hug"
              leftIcon={<ArrowLeftIcon />}
              className="text-primary-bold underline hover:no-underline"
              onClick={() => onBack()}
            >
              {t("purchase:back_to_vehicle_info")}
            </Button>
          </div>
        </div>
      </div>
    </OverlayLoader>
  );
};

export default WarrantyInstantQuotePersonalInfo;
