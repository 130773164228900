import { logWarning } from "@common/helpers/logger";

const vehicleImageValidator = (url: string | null): boolean => {
  if (!url) return false;

  const trustedDomains = [
    "https://media.kijiji.ca",
    "https://sandbox.rightride.com",
  ];

  const isTrustedDomain = trustedDomains.some((domain) =>
    url.startsWith(domain)
  );

  if (!isTrustedDomain) {
    logWarning(
      `Vehicle image is not from a trusted domain. Please check the image URL: ${url}.`
    );
  }

  return isTrustedDomain;
};

export default vehicleImageValidator;
