import api from "@modules/api/api";
import { useQuery } from "@tanstack/react-query";
import tradeInQueryKeys from "@modules/trade/types/tradeInQueryKeys";

export type PreviewResponse = {
  imageList: string[];
  tradeInId: string;
};

const performGet = (
  tokenId: string,
  source: string
): Promise<PreviewResponse> => {
  return api.get<PreviewResponse>(
    `api/trade-in/scanned-trade-in-images?source=${source}&tradeInToken=${tokenId}`
  );
};

export default function useScannedTradeInImages(
  tokenId: string,
  source: string,
  isEnabled: boolean
) {
  return useQuery({
    queryKey: tradeInQueryKeys.appraisalPreviewScannedImageIds(tokenId),
    queryFn: () => performGet(tokenId, source),
    enabled: isEnabled,
  });
}
