import { useRouter } from "next/router";
import { useMemo } from "react";
import {
  useNumberMask,
  useUppercaseAlphaNumericMask,
} from "@common/hooks/useMask";
import vehicleValidation from "@common/helpers/validations/vehicleValidation";
import { LocalDate } from "@js-joda/core";
import vehicleImageValidator from "@modules/purchase/helpers/vehicleImageValidator";
import { VehicleInformation, VehicleQueryParams } from "../types";

type MaskPipe = (value: string) => string;

const parseVin = (
  value: string | undefined,
  maskUppercaseAlphaNumericPipe: MaskPipe
) => {
  if (!value || !vehicleValidation.vin.validVin(value)) {
    return null;
  }

  return maskUppercaseAlphaNumericPipe(value);
};

const parseYear = (value: string | undefined) => {
  if (!value) {
    return null;
  }

  const { validYear, beforeCurrentYear } = vehicleValidation.year;

  let parsedYear = value ? parseInt(value, 10) : null;
  parsedYear = parsedYear && !Number.isNaN(parsedYear) ? parsedYear : null;

  if (!validYear(parsedYear) || !beforeCurrentYear(parsedYear)) {
    return null;
  }

  return parsedYear;
};

const parseOdometer = (value: string | undefined, maskNumberPipe: MaskPipe) => {
  if (
    !value ||
    value?.toString().length > vehicleValidation.odometer.maxLength - 1
  ) {
    return null;
  }

  return maskNumberPipe(value);
};

const parsePrice = (
  value: string | undefined,
  maskNumberPipe: MaskPipe,
  maxLength = vehicleValidation.price.valueLength
): string | null => {
  if (!value || value?.toString().length > maxLength) {
    return null;
  }

  return maskNumberPipe(value);
};

const parsePurchaseDate = (value: string | undefined) => {
  let result;
  try {
    result = value ? LocalDate.parse(value) : null;
  } catch (e) {
    result = null;
  }
  return result;
};

const useVehicleInfoFromQuery = () => {
  const router = useRouter();
  const {
    vin,
    year,
    make,
    model,
    trim,
    bodyType,
    colour,
    fuelType,
    odometer,
    sellingPrice,
    purchaseDate,
    purchasePrice,
    coverImage,
  } = router.query as VehicleQueryParams;
  const { maskPipe: maskNumberPipe } = useNumberMask();
  const { maskPipe: maskUppercaseAlphaNumericPipe } =
    useUppercaseAlphaNumericMask();

  const vinDisplayValue = parseVin(vin, maskUppercaseAlphaNumericPipe);
  const yearDisplayValue = parseYear(year);
  const odometerDisplayValue = parseOdometer(odometer, maskNumberPipe);
  const purchasePriceDisplayValue = parsePrice(
    purchasePrice,
    maskNumberPipe,
    vehicleValidation.warrantyVehiclePurchasePrice.valueLength
  );
  const purchaseDateValue = parsePurchaseDate(purchaseDate);
  const listPriceValue = parsePrice(sellingPrice, maskNumberPipe);

  const vehicleInformation: VehicleInformation = useMemo(() => {
    return {
      vin: vinDisplayValue,
      year: yearDisplayValue,
      make:
        make && make.length <= vehicleValidation.make.maxLength ? make : null,
      model:
        model && model.length <= vehicleValidation.model.maxLength
          ? model
          : null,
      trim:
        trim && trim.length <= vehicleValidation.trim.maxLength ? trim : null,
      bodyType:
        bodyType && bodyType.length <= vehicleValidation.bodyType.maxLength
          ? bodyType
          : null,
      exteriorColour:
        colour && colour.length <= vehicleValidation.exteriorColour.maxLength
          ? colour
          : null,
      fuelType:
        fuelType && fuelType.length <= vehicleValidation.fuelType.maxLength
          ? fuelType
          : null,
      odometer: odometerDisplayValue,
      purchaseDate: purchaseDateValue,
      purchasePrice: purchasePriceDisplayValue,
      vehicleSoldDate: null,
      listPrice: listPriceValue,
      coverImage:
        coverImage && vehicleImageValidator(coverImage) ? coverImage : null,
    };
  }, [
    vinDisplayValue,
    yearDisplayValue,
    make,
    model,
    trim,
    bodyType,
    colour,
    fuelType,
    odometerDisplayValue,
    purchaseDateValue,
    purchasePriceDisplayValue,
    listPriceValue,
    coverImage,
  ]);

  return vehicleInformation;
};

export default useVehicleInfoFromQuery;
