import { cleanParagraphTags } from "@common/helpers/cleanHtml";
import { FinancingCalculatorLayout } from "@modules/cms/types/FinancingCalculatorLayout";
import useConsumerFinancingFromQuery from "@modules/purchase/hooks/useConsumerFinancingFromQuery";
import useVehicleFinancePricingDefaults from "@modules/purchase/hooks/useVehicleFinancePricingDefaults";
import {
  getFirstStep,
  getStepPathname,
} from "@modules/purchase/types/PurchaseStep";
import { useRouter } from "next/router";
import GetBackgroundStyle from "@modules/cms/helpers/getBackgroundStyle";
import FinancingCalculator from "./FinancingCalculator";

type Props = {
  layout: FinancingCalculatorLayout;
};

const FinancingCalculatorWrapper = ({ layout }: Props) => {
  const { year } = useConsumerFinancingFromQuery();
  const { pricingDefaults, isFetched } = useVehicleFinancePricingDefaults(year);
  const { heading, subHeading, background } = layout;
  const {
    push,
    query: { source },
  } = useRouter();

  const onStartFinancing = () => {
    const firstStep = getFirstStep("financing");
    push({
      pathname: getStepPathname(firstStep, true),
      query: { source },
    });
  };

  return (
    <div
      id="cms-financing-calculator-section"
      className="bg-white overflow-visible no-scrollbar relative bg-cover bg-center"
      style={GetBackgroundStyle(background)}
    >
      <div className="relative max-w-page mx-auto flex flex-col items-center justify-center pt-10 pb-6 px-4 lg:px-5 lg:py-16">
        <div className="flex flex-col justify-center items-center gap-4">
          <h1
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: cleanParagraphTags(heading),
            }}
          />
          <h4
            className="body-2"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: cleanParagraphTags(subHeading),
            }}
          />
          {isFetched && (
            <FinancingCalculator
              pricingDefaults={pricingDefaults}
              onStartFinancing={onStartFinancing}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FinancingCalculatorWrapper;
