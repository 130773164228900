import Button from "@common/components/Button";
import { ArrowRightIcon } from "@common/components/icons";
import { ConsumerSellerHeadingLayout } from "@modules/cms/types/ConsumerSellerHeadingLayout";
import { Trans, useTranslation } from "next-i18next";
import LocalImage from "@common/components/LocalImage";
import { useRouter } from "next/router";
import { ImageWithTranslation } from "@common/types/ImageWithTranslation";
import {
  SellerHeroImageEn,
  SellerHeroImageFr,
} from "@public/images/purchase/consumer";
import useUser from "@modules/user/hooks/useUser";
import { useContext } from "react";
import AftermarketConsumerSellerPageContext from "@modules/purchase/AftermarketConsumerSellerPageContext";
import GetBackgroundStyle from "@modules/cms/helpers/getBackgroundStyle";

type Props = {
  layout: ConsumerSellerHeadingLayout;
};

const vehicleDefaultImage: ImageWithTranslation = {
  srcEn: SellerHeroImageEn,
  srcFr: SellerHeroImageFr,
  altKey: "purchase:alt_seller_hero_image",
};

const ConsumerSellerHeading = ({ layout }: Props) => {
  const { t } = useTranslation(["common", "purchase"]);
  const { background } = layout;
  const router = useRouter();
  const { locale } = router;
  const { source } = router.query;
  const { user } = useUser();
  const { setShowModal } = useContext(AftermarketConsumerSellerPageContext);

  const onCreateAccount = () => {
    setShowModal(true);
  };

  const onViewTaskList = () => {
    router.push({
      pathname: "/purchase/consumer/seller/checklist",
      query: { source },
    });
  };

  return (
    <div
      id="cms-seller-heading-section"
      className="bg-white overflow-visible no-scrollbar relative bg-cover bg-center"
      style={GetBackgroundStyle(background)}
    >
      <div className="relative max-w-page mx-auto flex items-center flex-col-reverse pt-10 pb-6 px-4 lg:px-5 lg:py-16 lg:flex-row-reverse">
        <div className="flex-1 lg:basis-[40%] lg:px-0 md:px-28">
          <div className="w-full items-center justify-center text-center lg:text-left lg:items-start lg:justify-start">
            <p className="text-text-light-100 font-semibold text-[2.25rem] md:text-[3.25rem] leading-[2.625rem] md:leading-[3.625rem]">
              <Trans
                t={t}
                i18nKey="purchase:c2c_seller_landing_title"
                components={{
                  bold: <span className="text-primary-bold" />,
                }}
              />
            </p>
          </div>

          <div className="mt-2 w-full items-center justify-center text-center lg:text-left lg:items-start lg:justify-start">
            <h4 className="mt-4 lg:mt-8">
              {t("purchase:c2c_seller_landing_description")}
            </h4>
          </div>

          <div className="flex flex-col gap-2 mt-8 justify-center text-center md:items-center lg:text-start lg:items-start lg:justify-start">
            {user?.isLoggedIn ? (
              <Button rightIcon={<ArrowRightIcon />} onClick={onViewTaskList}>
                {t("purchase:view_your_task_list")}
              </Button>
            ) : (
              <Button rightIcon={<ArrowRightIcon />} onClick={onCreateAccount}>
                {t("common:create_account_or_login")}
              </Button>
            )}
          </div>
        </div>
        <div className="flex-1 lg:basis-[60%] w-full relative">
          <div className="min-w-full h-auto relative inset-0">
            <div className="overflow-auto relative h-56 md:h-96 lg:mr-4">
              <LocalImage
                className="z-[2] w-full object-contain h-auto rounded-lg"
                src={
                  locale === "fr"
                    ? vehicleDefaultImage.srcFr
                    : vehicleDefaultImage.srcEn
                }
                alt={t(vehicleDefaultImage.altKey)}
                fill
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsumerSellerHeading;
