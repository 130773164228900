import CloudflareImagesImage from "@common/components/CloudflareImagesImage";
import { cleanParagraphTags } from "@common/helpers/cleanHtml";
import classNames from "classnames";
import { useState } from "react";
import { ArrowRightIcon } from "@common/components/icons";
import ActionButton from "../enum/ActionButtonEnum";
import { MultiCardSectionLayout } from "../types/MultiCardSectionLayout";
import CmsButtonModalContainer from "./ButtonModalContainer";
import CmsButton from "./CmsButton";
import GetBackgroundStyle from "../helpers/getBackgroundStyle";

const MultiCardSection = ({
  sectionTitle,
  cardLayout,
  button,
  background,
  htmlIdRef,
}: MultiCardSectionLayout) => {
  const [buttonModalState, setButtonModalState] = useState<{
    show: boolean;
    actionType: ActionButton;
  }>({ show: false, actionType: ActionButton.UnAssigned });

  return (
    <div
      id="cms-multi-card-section"
      className="bg-white overflow-visible no-scrollbar relative bg-cover bg-center"
      style={GetBackgroundStyle(background)}
    >
      <div className="max-w-page mx-auto pt-10 pb-6 px-4 lg:px-5 lg:py-16">
        <article>
          <h3
            className="text-primary-deep md:text-center text-2xl leading-8 lg:text-4xl lg:leading-[3rem] font-semibold"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: cleanParagraphTags(sectionTitle),
            }}
          />
        </article>
        <div
          className={classNames(
            "pt-12 max-w-xl mx-auto grid grid-cols-1 gap-y-4 xl:gap-y-10 gap-x-6 xl:gap-x-8 grid-flow-row text-center",
            {
              "sm:grid-cols-2 sm:max-w-3xl": cardLayout.length === 2,
              "sm:grid-cols-2 sm:max-w-3xl lg:max-w-none lg:grid-cols-3":
                cardLayout.length >= 3,
            }
          )}
        >
          {cardLayout.map((card) => (
            <div
              key={card.cardLabel + card.cardSubLabel}
              className={classNames(
                "group flex flex-col flex-1 gap-3 items-center p-4 md:p-8 rounded-lg w-full h-auto text-text-light-100 bg-white transition shadow-elevation-01"
              )}
            >
              <div className="text-gray-500 transition ease-in-out duration-300">
                {card?.cardIcon?.sourceUrl && (
                  <div
                    className="relative h-16 w-16 mt-2"
                    key={`${card.cardIcon.altText}`}
                  >
                    <CloudflareImagesImage
                      src={card.cardIcon.sourceUrl}
                      alt={card.cardIcon.altText}
                      fill
                      className="items-center justify-center text-center"
                    />
                  </div>
                )}
              </div>

              <div className="flex flex-col justify-center md:hidden px-3 w-full">
                <div
                  className="body-1"
                  key={card.cardLabel}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: cleanParagraphTags(card.cardLabel),
                  }}
                />
                <div
                  className="pt-2 caption-2 [&>ul]:list-disc [&>ul]:pl-4"
                  key={card.cardSubLabel}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: cleanParagraphTags(card.cardSubLabel),
                  }}
                />
              </div>

              <h5
                className="hidden md:block pt-8 transition ease-in-out duration-300"
                key={`${card.cardLabel}-md`}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: cleanParagraphTags(card.cardLabel),
                }}
              />
              <div
                className="hidden md:block pt-3 pb-8 body-4 [&>ul]:list-disc [&>ul]:text-start [&>ul]:px-5"
                key={`${card.cardSubLabel}-md`}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: cleanParagraphTags(card.cardSubLabel),
                }}
              />
              {card.button && !card.button.isHidden && (
                <CmsButton
                  button={card.button}
                  rightIcon={<ArrowRightIcon />}
                  setButtonModalState={setButtonModalState}
                />
              )}
            </div>
          ))}
        </div>
        <div className="flex justify-center">
          {!button.isHidden && (
            <div className="w-56 pt-8 md:pt-12">
              <CmsButton
                className="w-full"
                button={button}
                rightIcon={<ArrowRightIcon />}
                setButtonModalState={setButtonModalState}
                htmlIdRef={htmlIdRef}
              />
            </div>
          )}
        </div>
        {buttonModalState.show && (
          <CmsButtonModalContainer buttonModalState={buttonModalState} />
        )}
      </div>
    </div>
  );
};

export default MultiCardSection;
