import CloudflareImagesImage from "@common/components/CloudflareImagesImage";
import { ArrowRightIcon } from "@common/components/icons";
import classNames from "classnames";
import { useState } from "react";
import ActionButton from "../enum/ActionButtonEnum";
import { FullWidthCTABannerLayout } from "../types/FullWidthCTABannerLayout";
import CmsButtonModalContainer from "./ButtonModalContainer";
import CmsButton from "./CmsButton";
import GetBackgroundStyle from "../helpers/getBackgroundStyle";

const FullWidthCTABanner = ({
  ctaBannerList,
  background,
  htmlIdRef,
}: FullWidthCTABannerLayout) => {
  const [buttonModalState, setButtonModalState] = useState<{
    show: boolean;
    actionType: ActionButton;
  }>({ show: false, actionType: ActionButton.UnAssigned });

  return (
    <div
      id="cms-full-width-cta-banner"
      className="bg-white overflow-visible no-scrollbar relative bg-cover bg-center py-10"
      style={GetBackgroundStyle(background)}
    >
      <div className="flex flex-col max-w-page mx-auto md:flex-row gap-14 md:gap-4">
        {ctaBannerList &&
          ctaBannerList.map((banner, i) => {
            const { ctaIcon, ctaHeading, ctaDescription, button } = banner;
            return (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={`cta-banner-${i}`}
              >
                {/* Desktop */}
                <div
                  className={classNames(
                    "hidden md:grid relative max-w-page mx-auto grid-cols-2 gap-2 items-center justify-end text-center pb-6 px-4 md:py-4 lg:px-5"
                  )}
                >
                  <div className="col-span-2">
                    <div className="flex items-start gap-4 px-4 py-3 lg:py-5 caption-1 lg:leading-5">
                      {ctaIcon?.sourceUrl && (
                        <div className="inline-flex items-center justify-center">
                          <div className="relative h-16 w-16 mb-6">
                            <CloudflareImagesImage
                              src={ctaIcon.sourceUrl}
                              alt={ctaIcon.altText}
                              fill
                              className="items-center justify-center text-center"
                            />
                          </div>
                        </div>
                      )}
                      <div className="flex flex-col gap-2 text-left text-primary-deep">
                        <h3 className="text-text-light-100 text-2xl lg:text-4xl lg:tracking-tight text-left">
                          {ctaHeading}
                        </h3>
                        <p className="body-2">{ctaDescription}</p>
                        {!button.isHidden && (
                          <div className="w-56 pt-4">
                            <CmsButton
                              className="w-full"
                              button={button}
                              rightIcon={<ArrowRightIcon />}
                              setButtonModalState={setButtonModalState}
                              htmlIdRef={htmlIdRef}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/*  Mobile View */}
                <div
                  className={classNames(
                    "flex md:hidden max-w-page gap-4 items-start justify-center px-4"
                  )}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`cta-banner-mobile-${i}`}
                >
                  {ctaIcon?.sourceUrl && (
                    <div className="relative w-36">
                      <CloudflareImagesImage
                        src={ctaIcon.sourceUrl}
                        alt={ctaIcon.altText}
                        width={200}
                        height={200}
                        className="w-full object-contain h-auto"
                      />
                    </div>
                  )}
                  <div className="text-left">
                    <div className="flex flex-col">
                      <h3 className="flex-1 pb-4 text-text-light-100 text-2xl lg:text-4xl lg:tracking-tight">
                        {ctaHeading}
                      </h3>
                      <p className="body-2 pb-6">{ctaDescription}</p>
                      {!button.isHidden && (
                        <div className="w-56">
                          <CmsButton
                            className="w-full"
                            button={button}
                            rightIcon={<ArrowRightIcon />}
                            setButtonModalState={setButtonModalState}
                            htmlIdRef={htmlIdRef}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      {buttonModalState.show && (
        <CmsButtonModalContainer buttonModalState={buttonModalState} />
      )}
    </div>
  );
};

export default FullWidthCTABanner;
