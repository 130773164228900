import api from "@modules/api/api";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import purchaseQueryKeys from "../purchaseQueryKeys";
import { Purchase, PurchaseNotFoundResponse } from "../types/Purchase";

const getActiveConsumerFinancing = () => {
  return api.get<Purchase | PurchaseNotFoundResponse>(
    "api/purchases/active-consumer-financing"
  );
};

export const checkActiveConsumerFinancing = (
  consumerFinancing: Purchase | PurchaseNotFoundResponse | undefined
) => {
  const purchaseNotFoundResponse =
    consumerFinancing as PurchaseNotFoundResponse;
  const activePurchase = consumerFinancing as Purchase;

  return (
    !!(activePurchase && activePurchase.id) || purchaseNotFoundResponse?.exists
  );
};

export default function useConsumerFinancing() {
  const {
    data: consumerFinancing,
    isLoading,
    error,
    isFetching,
    isStale,
    refetch,
  } = useQuery({
    queryKey: purchaseQueryKeys.activeFinancingPurchase(),
    queryFn: () => getActiveConsumerFinancing(),
    placeholderData: keepPreviousData,
    meta: { overrideDefaultErrorHandling: true },
  });

  return {
    consumerFinancing,
    isLoading,
    error,
    isFetching,
    isStale,
    refetch,
  };
}
