const AddOpacityToBackground = (backgroundHex: string, opacity: number) => {
  // Convert opacity to alpha channel value between 0 and 255
  const alpha = Math.round(opacity * 255);
  // Convert alpha value to hexadecimal format
  const hexAlpha = alpha.toString(16).toUpperCase();
  // Ensure the hexadecimal value has two digits
  const paddedHexAlpha = hexAlpha.padStart(2, "0");
  // Append alpha channel value to the existing hexadecimal color code
  return backgroundHex + paddedHexAlpha;
};

export default AddOpacityToBackground;
