import { WarrantyGraphLayout } from "@modules/cms/types/WarrantyGraphLayout";
import GetBackgroundStyle from "@modules/cms/helpers/getBackgroundStyle";
import { useEffect, useRef } from "react";
import { useWarrantyQuotePageContext } from "@modules/purchase/WarrantyQuotePageContext";
import WarrantyGraphChart from "./WarrantyGraphChart";
import RemainingWarranty from "./RemainingWarranty";

type Props = {
  layout: WarrantyGraphLayout;
};

const WarrantyGraph = ({ layout }: Props) => {
  const { background } = layout;

  const warrantyGraphSectionRef = useRef<HTMLDivElement>(null);

  const { defaultRates, rangedRates } = useWarrantyQuotePageContext();

  const warrantyRates = rangedRates.data || defaultRates.data;

  const vehicleWarranty = warrantyRates?.vehicleWarranty;
  const isVehicleWarrantyFetched = !!vehicleWarranty;

  const scrollToWarrantyComponentsSection = () => {
    warrantyGraphSectionRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleCustomEvent = () => {
      scrollToWarrantyComponentsSection();
    };
    if (warrantyGraphSectionRef) {
      window.addEventListener(
        "scrollToWarrantyGraphSection",
        handleCustomEvent
      );
    }

    return () => {
      if (warrantyGraphSectionRef) {
        window.removeEventListener(
          "scrollToWarrantyGraphSection",
          handleCustomEvent
        );
      }
    };
  }, []);

  return vehicleWarranty ? (
    <div
      id="cms-warranty-graph-section"
      className="bg-white overflow-visible no-scrollbar relative bg-cover bg-center"
      style={GetBackgroundStyle(background)}
      ref={warrantyGraphSectionRef}
    >
      <div className="max-w-page mx-auto pt-10 pb-6 px-4 lg:px-5 lg:py-16 min-h-[300px]">
        <div className="py-8 mx-auto text-center">
          <RemainingWarranty vehicleWarranty={vehicleWarranty} />
        </div>
        <div className="flex justify-center">
          <div className="w-full lg:w-5/6">
            <WarrantyGraphChart
              vehicleWarranty={vehicleWarranty}
              redraw={isVehicleWarrantyFetched}
            />
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default WarrantyGraph;
