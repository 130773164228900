import { Controller, SubmitHandler, useFormContext } from "react-hook-form";
import {
  VehicleOwnershipStatus,
  vehicleOwnershipStatuses,
  VehicleOwnershipStatusId,
} from "@common/types/VehicleOwnershipStatus";
import DivCheckbox from "@common/components/DivCheckbox";
import { ChangeEvent } from "react";
import { useTranslation } from "next-i18next";
import Button from "@common/components/Button";
import { ArrowRightIcon } from "@common/components/icons";
import useAftermarketPurchase from "@modules/purchase/hooks/useAftermarketPurchase";
import { tryGetActiveAftermarketPurchase } from "@modules/purchase/types/Purchase";
import {
  aftermarketDetailsStep,
  showResumeForStep,
  getStepById,
} from "@modules/purchase/types/PurchaseStep";
import { useRouter } from "next/router";
import { WarrantySegmentationFormProps } from "./types/WarrantySegmentationFormProps";
import {
  WarrantySegmentationMode,
  approximateBuyingTimeframeMode,
  quoteFormMode,
} from "./types/WarrantySegmentationMode";

type Props = {
  onChangeMode(newMode: WarrantySegmentationMode): void;
};

const WarrantyVehicleOwnershipStatusQuestionnaireSegment = ({
  onChangeMode,
}: Props) => {
  const { t } = useTranslation(["common", "purchase"]);
  const router = useRouter();

  const { control, handleSubmit, setValue, watch } =
    useFormContext<WarrantySegmentationFormProps>();

  const selectedOwnershipStatus = watch("selectedVehicleOwnershipStatus");

  const onFormSubmit: SubmitHandler<WarrantySegmentationFormProps> = () => {
    // Re-watch the value to get the updated one
    const updatedOwnershipStatus = watch("selectedVehicleOwnershipStatus");

    if (
      updatedOwnershipStatus === VehicleOwnershipStatusId.JustStartedShopping ||
      updatedOwnershipStatus === VehicleOwnershipStatusId.WillBuyAVehicleSoon
    ) {
      onChangeMode(approximateBuyingTimeframeMode);
    } else {
      onChangeMode(quoteFormMode);

      if (
        updatedOwnershipStatus ===
          VehicleOwnershipStatusId.RecentlyBoughtVehicle ||
        updatedOwnershipStatus ===
          VehicleOwnershipStatusId.HaveAnExistingVehicle
      ) {
        // Set null when the selection is recently bought or have an existing vehicle
        setValue("selectedApproximateBuyingTimeframe", null);
      }
    }
  };

  const handleCheckboxChange = (
    _event: ChangeEvent<HTMLInputElement>,
    objectValue: VehicleOwnershipStatus
  ) => {
    setValue("selectedVehicleOwnershipStatus", objectValue.id);
    handleSubmit(onFormSubmit)();
  };

  const { purchase: purchaseResponse } = useAftermarketPurchase();
  const purchase = tryGetActiveAftermarketPurchase(purchaseResponse);

  const showResumeButton =
    !!purchase && showResumeForStep(purchase.currentStepId);

  const handleOnResumePurchaseClick = () => {
    if (!purchase) return;

    const purchaseStep = getStepById(
      "aftermarket",
      purchase?.currentStepId ?? aftermarketDetailsStep.id
    );

    if (purchaseStep) {
      router.push({
        pathname: purchaseStep.href,
      });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="flex flex-col gap-3">
          <ul className="body-3 grid grid-cols-2 gap-2 auto-rows-fr md:px-8">
            {vehicleOwnershipStatuses &&
              vehicleOwnershipStatuses.map((vos) => (
                <li key={vos.id}>
                  <Controller
                    name="selectedVehicleOwnershipStatus"
                    control={control}
                    render={() => (
                      <DivCheckbox
                        id={`vehicleOwnershipStatus-${vos.name}`}
                        readOnly
                        objectValue={{
                          id: vos.id,
                          name: vos.name,
                        }}
                        className="flex-col bg-white p-4"
                        onChangeEvent={handleCheckboxChange}
                        isChecked={selectedOwnershipStatus === vos.id}
                        paddingSize="medium"
                      >
                        {vos.icon}
                        <span className="mt-2 caption-1">{t(vos.name)}</span>
                      </DivCheckbox>
                    )}
                  />
                </li>
              ))}
          </ul>
        </div>
      </form>
      {showResumeButton && (
        <div className="flex flex-col items-center space-y-4 mt-6">
          <Button
            rightIcon={<ArrowRightIcon />}
            onClick={handleOnResumePurchaseClick}
            buttonStyle="borderless"
            fill="link"
            spacing="no-padding"
            className="text-primary-bold underline hover:no-underline"
          >
            {t("purchase:resume_my_purchase")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default WarrantyVehicleOwnershipStatusQuestionnaireSegment;
