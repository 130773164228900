import { useTranslation } from "next-i18next";
import { useEffect } from "react";
import { gtmPushData } from "@common/helpers/gtm";
import useUser from "@modules/user/hooks/useUser";
import { Event as HotjarEvent, hotjar } from "@modules/core/hotjar/types";
import UnexpectedErrorModal from "../UnexpectedErrorModal";

type Props = {
  showErrorModal: boolean;
  setShowErrorModal: (show: boolean) => void;
  onPrimaryClick: () => void;
  onSecondaryClick: () => void;
};

const InstantCashOfferNotFoundModal = ({
  showErrorModal,
  setShowErrorModal,
  onPrimaryClick,
  onSecondaryClick,
}: Props) => {
  const { t } = useTranslation(["common", "purchase"]);

  const { user } = useUser();

  useEffect(() => {
    if (showErrorModal) {
      gtmPushData({
        event: "ICO Not Found Modal",
        description: "ICO Not Found Modal Open",
        element: "ICO Landing Page",
        user_id: user?.id,
      });

      hotjar.event(HotjarEvent.IcoNotFoundModalOpen);
    }
  }, [showErrorModal, user?.id]);

  return (
    <UnexpectedErrorModal
      showUnexpectedErrorModal={showErrorModal}
      setShowUnexpectedErrorModal={(show) => {
        setShowErrorModal(show);

        if (!show) {
          gtmPushData({
            event: "ICO Not Found Modal",
            description: "ICO Not Found Modal Closed",
            element: "Close",
            user_id: user?.id,
          });

          hotjar.event(HotjarEvent.IcoNotFoundModalClose);
        }
      }}
      onPrimaryClick={() => {
        gtmPushData({
          event: "ICO Not Found Modal",
          description: "ICO Not Found Modal Contact Us Clicked",
          element: "Contact Us",
          user_id: user?.id,
        });
        hotjar.event(HotjarEvent.IcoNotFoundModalContactUsClicked);
        onPrimaryClick();
      }}
      onSecondaryClick={() => {
        gtmPushData({
          event: "ICO Not Found Modal",
          description: "ICO Not Found Modal Cancel Clicked",
          element: "Cancel",
          user_id: user?.id,
        });
        hotjar.event(HotjarEvent.IcoNotFoundModalCancelClicked);
        onSecondaryClick();
      }}
      title={t("purchase:no_ico_found")}
      heading={t("common:hmm")}
      subheading={t("purchase:account_not_associated_with_ico")}
      body={t("purchase:ico_please_contact_us")}
      primaryButtonText={t("common:contact_us")}
      secondaryButtonText={t("common:cancel")}
    />
  );
};

export default InstantCashOfferNotFoundModal;
