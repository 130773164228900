import Modal, { Props as ModalProps } from "@common/components/Modal";
import { useTranslation } from "next-i18next";

type Props = {
  onClose: () => void;
  primaryButtonText?: string;
} & Pick<
  ModalProps,
  "title" | "modalType" | "isOpen" | "children" | "buttonDisplay"
>;

const InfoModal = ({
  children,
  onClose,
  isOpen,
  title,
  modalType,
  primaryButtonText,
  buttonDisplay = "inline",
}: Props) => {
  const { t } = useTranslation(["common"]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      modalType={modalType}
      alignment="center"
      buttonDisplay={buttonDisplay}
    >
      {children}
      <Modal.Buttons
        primaryButtonText={primaryButtonText ?? t("common:confirm")}
        onPrimaryClick={onClose}
      />
    </Modal>
  );
};

export default InfoModal;
