import Button from "@common/components/Button";
import { ArrowUpIcon, ContractIcon } from "@common/components/icons";
import { IAExtendedWarrantyPrograms } from "@modules/purchase/types/IAProgram";
import useAftermarketPurchaseFromQuery from "@modules/purchase/hooks/useAftermarketPurchaseFromQuery";
import warrantyComponentsByProgram from "@modules/purchase/types/WarrantyComponent";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { WarrantyCoverageLayout } from "@modules/cms/types/WarrantyCoverageLayout";
import useBuyerInfoFromQuery from "@modules/query-string/hooks/useBuyerInfoFromQuery";
import useIsBuyerProvinceActiveForAftermarket from "@modules/purchase/hooks/useIsBuyerProvinceActiveForAftermarket";
import useAftermarketPurchase from "@modules/purchase/hooks/useAftermarketPurchase";
import { useWarrantyQuotePageContext } from "@modules/purchase/WarrantyQuotePageContext";
import GetBackgroundStyle from "@modules/cms/helpers/getBackgroundStyle";
import { getPrefixUrl } from "@modules/api/api";
import { Event as HotjarEvent, hotjar } from "@modules/core/hotjar/types";
import { gtmPushData } from "@common/helpers/gtm";
import { tryGetActiveAftermarketPurchase } from "@modules/purchase/types/Purchase";
import { useState } from "react";
import DealSegment from "@common/enums/DealSegment";
import WarrantyComponent from "./WarrantyComponent";
import {
  vehicleInfoMode,
  WarrantyInstantQuoteModalMode,
} from "./types/WarrantyInstantQuoteModalMode";
import WarrantyInstantQuoteModal from "./WarrantyInstantQuoteModal";

type CtaButtonsProps = {
  source: string;
  onStartPurchase: () => void;
  onOpenCoveragesModal: () => void;
};

const CtaButtons = ({
  source,
  onStartPurchase,
  onOpenCoveragesModal,
}: CtaButtonsProps) => {
  const { t } = useTranslation(["common", "purchase"]);
  const { vin } = useAftermarketPurchaseFromQuery();

  const { province } = useBuyerInfoFromQuery();
  const { isProvinceActive } = useIsBuyerProvinceActiveForAftermarket(
    source,
    province
  );

  return vin && isProvinceActive ? (
    <Button
      rightIcon={<ArrowUpIcon className="h-4" />}
      onClick={onStartPurchase}
    >
      {t("common:get_started")}
    </Button>
  ) : (
    <Button onClick={onOpenCoveragesModal}>{t("purchase:get_a_quote")}</Button>
  );
};

type Props = {
  layout: WarrantyCoverageLayout;
};

const getWarrantySampleContractLink = (
  locale: string,
  provincePostalAbbr?: string,
  programId?: string
) => {
  return `${getPrefixUrl()}/api/purchases/contracts/sample/${provincePostalAbbr}/${programId}?lang=${locale}`;
};

const WarrantyCoverage = ({ layout }: Props) => {
  const { t } = useTranslation(["common", "purchase"]);
  const { purchase: purchaseResponse, isLoading: isPurchaseLoading } =
    useAftermarketPurchase();
  const purchase = tryGetActiveAftermarketPurchase(purchaseResponse);
  const router = useRouter();
  const locale = router.locale || "en";
  const { background } = layout;
  const { province } = useBuyerInfoFromQuery();

  const { setShowCoveragesModal, defaultRates, rangedRates, dealSegment } =
    useWarrantyQuotePageContext();

  const warrantyRates = rangedRates.data || defaultRates.data;

  const iaProgram = IAExtendedWarrantyPrograms.find(
    (p) => p.id === warrantyRates?.productDetails?.programId
  );

  const [showInstantQuoteModal, setShowInstantQuoteModal] = useState(false);

  const handleOpenCoveragesModal = () => {
    if (!isPurchaseLoading && dealSegment === DealSegment.RangeQuote) {
      setShowInstantQuoteModal(true);
    } else {
      gtmPushData({
        event: "ManualQuoteLookup",
        element: "Get a Quote",
        description: "Get a Quote Button Clicked",
        source: purchase?.dealSource || "",
      });
      setShowCoveragesModal(true);
    }
  };

  const [
    warrantyInstantQuoteModalModeState,
    setWarrantyInstantQuoteModalModeState,
  ] = useState<WarrantyInstantQuoteModalMode>(vehicleInfoMode);

  const handleWarrantySampleContractClick = (
    e: React.MouseEvent<HTMLAnchorElement>
  ) => {
    e.preventDefault();

    const sampleContractUrl = getWarrantySampleContractLink(
      locale,
      province?.abbr,
      iaProgram?.id
    );

    window.open(sampleContractUrl, "_blank");

    gtmPushData({
      event: "Warranty-Sample-Contract",
      element: "View a sample contract",
      description: "Warranty Sample Contract Clicked",
    });

    hotjar.event(HotjarEvent.WarrantySampleContract);
  };

  const triggerScrollToSection = () => {
    const event = new CustomEvent("scrollToWarrantyOptionsSection", {
      detail: {},
    });
    window.dispatchEvent(event);
  };

  const distinctUsedCoverageTypeIds = warrantyRates?.coverages.reduce<string[]>(
    (acc, current) => {
      if (!acc.some((coverage) => coverage === current.type)) {
        acc.push(current.type);
      }
      return acc;
    },
    []
  );
  const warrantyComponents =
    iaProgram != null && iaProgram.id in warrantyComponentsByProgram
      ? warrantyComponentsByProgram[iaProgram.id]
      : [];

  // filter out components that match 0 returned coverage types
  const filteredWarrantyComponents = warrantyComponents.filter((wc) =>
    wc.coverages.some((c) => distinctUsedCoverageTypeIds?.includes(c))
  );

  if (!defaultRates.isSuccess && !rangedRates.isSuccess) {
    return null;
  }

  return (
    (filteredWarrantyComponents.length > 0 && (
      <div
        id="cms-warranty-coverage-section"
        className="bg-white overflow-visible no-scrollbar relative bg-cover bg-center"
        style={GetBackgroundStyle(background)}
      >
        <div className="max-w-page mx-auto pt-10 pb-6 px-2 sm:px-4 lg:px-5 lg:py-16">
          <div className="pb-8 text-center">
            <h3 className="text-text-light-100 text-2xl lg:text-4xl lg:tracking-tight">
              {t("purchase:get_warranty_coverage_fully_online_in_minutes")}
            </h3>
          </div>

          <div className="border border-gray-50 rounded-lg p-2 pb-6 sm:p-6 bg-white">
            {warrantyRates && (
              <div className="text-primary-deep overflow-x-auto">
                <WarrantyComponent
                  extendedWarrantyDefaultRates={warrantyRates}
                />
              </div>
            )}

            <div className="flex flex-col gap-2 items-center mt-8">
              <CtaButtons
                onStartPurchase={triggerScrollToSection}
                onOpenCoveragesModal={handleOpenCoveragesModal}
                source={purchase?.dealSource || ""}
              />
              {warrantyRates?.hasSampleContract && (
                <a
                  href="#"
                  rel="noreferrer"
                  onClick={handleWarrantySampleContractClick}
                >
                  <p className="flex flex-col items-center gap-y-2 pt-8">
                    <ContractIcon className="w-16 h-16 lg:w-24 lg:h-24 text-primary-deep" />
                    <span className="font-medium underline text-primary-bold">
                      {t("purchase:view_sample_contract")}
                    </span>
                  </p>
                </a>
              )}
            </div>
          </div>
        </div>
        {showInstantQuoteModal && (
          <WarrantyInstantQuoteModal
            showModal={showInstantQuoteModal}
            handleModalClose={() => setShowInstantQuoteModal(false)}
            modeState={[
              warrantyInstantQuoteModalModeState,
              setWarrantyInstantQuoteModalModeState,
            ]}
          />
        )}
      </div>
    )) || <div />
  );
};

export default WarrantyCoverage;
