import { Trans, useTranslation } from "react-i18next";
import PriceTagAltImage from "@public/images/pricetag-alternate.svg";
import LocalImage from "@common/components/LocalImage";
import { formatCurrency } from "@common/helpers/numberFormatting";
import { useRouter } from "next/router";

type WarrantyMessageBarProps = {
  isShow: boolean;
  discountValue: number;
};

const WarrantyDiscountBar = ({
  isShow,
  discountValue,
}: WarrantyMessageBarProps) => {
  const { t } = useTranslation(["common", "purchase"]);
  const locale = useRouter().locale || "en";

  if (!isShow) {
    return null;
  }
  return (
    <div className="caption-2 md:body-4 bg-blueZodiac text-white px-6 py-1 text-center md:flex md:items-center md:justify-center">
      <div className="inline-flex flex-wrap items-center justify-center space-x-2">
        <LocalImage
          className="h-auto"
          src={PriceTagAltImage}
          alt="PriceTag Icon"
          priority
        />
        <Trans
          t={t}
          i18nKey="purchase:theres_nothing_like_a_summer_special"
          values={{
            discountValue: formatCurrency(discountValue || 0, locale, {
              showCents: "never",
            }),
          }}
          components={{
            bold: <span className="font-semibold text-eggBlue" />,
          }}
        />
      </div>
    </div>
  );
};

export default WarrantyDiscountBar;
