import { ParsedUrlQuery } from "querystring";
import { translationContains } from "@common/helpers/translate";
import { getFuelEfficiencyFilterTranslationById } from "@modules/vehicle/helpers/getVehicleFilterDescription";
import { Translation } from "@common/types/Translation";
import {
  FilterKeys,
  VehicleFilter,
  VehicleFiltersData,
} from "../types/vehicleFilter";
import { multiItemSeparator } from "./convertToQueryParams";

const normalizeSlug = (slug: string | string[]) =>
  Array.isArray(slug) ? slug[0] : slug;

const cleanParam = (slug: string) =>
  slug ? slug.replace(/[^a-z0-9]/gi, "").toLowerCase() : "";

const translationMatches = (translation: Translation, candidate: string) =>
  translationContains(
    {
      english: cleanParam(translation.english),
      french: cleanParam(translation.french),
    },
    cleanParam(candidate)
  );

const valueMatches = (value: string, candidate: string) =>
  cleanParam(value) === cleanParam(candidate);

function convertBodyStyleToFilters(
  body: string | string[] | undefined,
  cleanedSlug: string,
  vehicleFilterData: VehicleFiltersData
): VehicleFilter[] {
  const vehicleFilters: VehicleFilter[] = [];

  if (body && typeof body === "string") {
    const bodyStyleFilter = body.split(multiItemSeparator);

    bodyStyleFilter.forEach((f) => {
      const bodyStyle = vehicleFilterData.bodyStyles.find((s) =>
        translationMatches(s.name, f)
      );

      if (bodyStyle) {
        vehicleFilters.push({
          key: FilterKeys.BodyStyle,
          state: {
            id: bodyStyle.id.toString(),
            name: bodyStyle.name,
          },
        });
      }
    });
  }

  if (cleanedSlug) {
    const slugBodyStyle = vehicleFilterData.bodyStyles.find((s) =>
      translationMatches(s.name, cleanedSlug)
    );

    if (
      slugBodyStyle &&
      !vehicleFilters.some(
        (bf) =>
          bf.key === FilterKeys.BodyStyle &&
          bf.state.id === slugBodyStyle.id.toString()
      )
    ) {
      vehicleFilters.push({
        key: FilterKeys.BodyStyle,
        state: {
          id: slugBodyStyle.id.toString(),
          name: slugBodyStyle.name,
        },
      });
    }
  }

  return vehicleFilters;
}

function convertMakeToFilters(
  make: string | string[] | undefined,
  cleanedSlug: string,
  vehicleFilterData: VehicleFiltersData
): VehicleFilter[] {
  const vehicleFilters: VehicleFilter[] = [];

  if (make && typeof make === "string") {
    const makeFilter = make.split(multiItemSeparator);

    makeFilter.forEach((m) => {
      const makeObject = vehicleFilterData.makesAndModels.find((mk) =>
        valueMatches(mk.name, m)
      );

      if (makeObject) {
        vehicleFilters.push({
          key: FilterKeys.Make,
          state: {
            make: makeObject.name,
          },
        });
      }
    });
  }

  if (cleanedSlug) {
    const slugMake = vehicleFilterData.makesAndModels.find((m) =>
      valueMatches(m.name, cleanedSlug)
    );

    if (
      slugMake &&
      !vehicleFilters.some(
        (mf) => mf.key === FilterKeys.Make && mf.state.make === slugMake.name
      )
    ) {
      vehicleFilters.push({
        key: FilterKeys.Make,
        state: {
          make: slugMake.name,
        },
      });
    }
  }

  return vehicleFilters;
}

function convertEfficiencyToFilters(
  efficiency: string | string[] | undefined
): VehicleFilter[] {
  const vehicleFilters: VehicleFilter[] = [];

  if (efficiency && typeof efficiency === "string") {
    const [consumptionType, maxLP100K] = efficiency.split("-");
    vehicleFilters.push({
      key: FilterKeys.FuelEfficiency,
      state: {
        consumptionType:
          getFuelEfficiencyFilterTranslationById(consumptionType),
        minLP100K: 0,
        maxLP100K: parseFloat(maxLP100K),
      },
    });
  }

  return vehicleFilters;
}

function convertYearToFilters(
  year: string | string[] | undefined
): VehicleFilter[] {
  const vehicleFilters: VehicleFilter[] = [];

  if (year && typeof year === "string") {
    const yearFilterValues = year.split("-");
    if (yearFilterValues.length > 1) {
      vehicleFilters.push({
        key: FilterKeys.Year,
        state: {
          minYear: {
            id: yearFilterValues[0] as string,
            value: +yearFilterValues[0],
          },
          maxYear: {
            id: yearFilterValues[1] as string,
            value: +yearFilterValues[1],
          },
        },
      });
    }
  }

  return vehicleFilters;
}

function convertMileageToFilters(
  mileage: string | string[] | undefined
): VehicleFilter[] {
  const vehicleFilters: VehicleFilter[] = [];

  if (mileage && typeof mileage === "string") {
    const mileageFilter = mileage.split("-");
    if (mileageFilter.length > 1) {
      vehicleFilters.push({
        key: FilterKeys.Mileage,
        state: {
          minMileage: +mileageFilter[0],
          maxMileage: +mileageFilter[1],
        },
      });
    }
  }

  return vehicleFilters;
}

function convertModelToFilters(
  model: string | string[] | undefined,
  vehicleFilterData: VehicleFiltersData
): VehicleFilter[] {
  const vehicleFilters: VehicleFilter[] = [];

  if (model && typeof model === "string") {
    const modelFilter = model.split(multiItemSeparator);

    const findModel = (makeSlashModel: string) => {
      for (let i = 0; i < vehicleFilterData.makesAndModels.length; i += 1) {
        const makeModel = vehicleFilterData.makesAndModels[i];

        const m = makeModel.models.find((mod) =>
          valueMatches(`${makeModel.name}-${mod.name}`, makeSlashModel)
        );

        if (m) {
          return {
            modelName: m.name,
            makeName: makeModel.name,
            id: m.id,
          };
        }
      }

      return undefined;
    };

    modelFilter.forEach((makeModel) => {
      const foundModel = findModel(makeModel);

      if (foundModel) {
        vehicleFilters.push({
          key: FilterKeys.Model,
          state: {
            id: foundModel.id,
            make: foundModel.makeName,
            model: foundModel.modelName,
          },
        });
      }
    });
  }

  return vehicleFilters;
}

function convertFeaturesToFilters(
  features: string | string[] | undefined,
  vehicleFilterData: VehicleFiltersData
): VehicleFilter[] {
  const vehicleFilters: VehicleFilter[] = [];

  if (features && typeof features === "string") {
    const featuresFilter = features.split(multiItemSeparator);

    featuresFilter.forEach((f) => {
      const featuresData = vehicleFilterData.featureTypes
        .map((ft) => ft.displayFeatures)
        .flat();

      const displayFeatures = featuresData.find((s) => {
        return translationMatches(s.name, f);
      });

      if (displayFeatures) {
        vehicleFilters.push({
          key: FilterKeys.KeyFeatures,
          state: {
            id: displayFeatures.id.toString(),
            name: displayFeatures.name,
          },
        });
      }
    });
  }

  return vehicleFilters;
}

function convertTransmissionToFilters(
  transmission: string | string[] | undefined,
  vehicleFilterData: VehicleFiltersData
): VehicleFilter[] {
  const vehicleFilters: VehicleFilter[] = [];

  if (transmission && typeof transmission === "string") {
    const transmissionFilter = transmission.split(multiItemSeparator);

    transmissionFilter.forEach((f) => {
      const transmissionObject = vehicleFilterData.transmissionTypes.find((s) =>
        translationMatches(s.name, f)
      );

      if (transmissionObject) {
        vehicleFilters.push({
          key: FilterKeys.Transmission,
          state: {
            id: transmissionObject.id.toString(),
            name: transmissionObject.name,
          },
        });
      }
    });
  }

  return vehicleFilters;
}

function convertColourToFilters(
  colour: string | string[] | undefined,
  vehicleFilterData: VehicleFiltersData
): VehicleFilter[] {
  const vehicleFilters: VehicleFilter[] = [];

  if (colour && typeof colour === "string") {
    const exteriorColourFilter = colour.split(multiItemSeparator);

    exteriorColourFilter.forEach((f) => {
      const colours = vehicleFilterData.exteriorColours.find((s) =>
        translationMatches(s.name, f)
      );

      if (colours) {
        vehicleFilters.push({
          key: FilterKeys.ExteriorColour,
          state: {
            id: colours.id.toString(),
            name: colours.name,
          },
        });
      }
    });
  }

  return vehicleFilters;
}

function convertFuelToFilters(
  fuel: string | string[] | undefined,
  vehicleFilterData: VehicleFiltersData
): VehicleFilter[] {
  const vehicleFilters: VehicleFilter[] = [];

  if (fuel && typeof fuel === "string") {
    const fuelTypeFilter = fuel.split(multiItemSeparator);

    fuelTypeFilter.forEach((f) => {
      const fuelTypeData = vehicleFilterData.fuelTypes.find((s) =>
        translationMatches(s.name, f)
      );

      if (fuelTypeData) {
        vehicleFilters.push({
          key: FilterKeys.FuelType,
          state: {
            id: fuelTypeData.id.toString(),
            name: fuelTypeData.name,
          },
        });
      }
    });
  }

  return vehicleFilters;
}

function convertSavedVehiclesToFilters(
  saved: string | string[] | undefined
): VehicleFilter[] {
  const vehicleFilters: VehicleFilter[] = [];

  if (saved && typeof saved === "string") {
    vehicleFilters.push({
      key: FilterKeys.SavedVehicles,
      state: saved === "true",
    });
  }

  return vehicleFilters;
}

function convertVehiclesWarrantyToFilters(
  warranty: string | string[] | undefined
): VehicleFilter[] {
  const vehicleFilters: VehicleFilter[] = [];

  if (warranty && typeof warranty === "string") {
    vehicleFilters.push({
      key: FilterKeys.Warranty,
      state: warranty === "true",
    });
  }

  return vehicleFilters;
}

function convertSearchToFilters(
  search: string | string[] | undefined
): VehicleFilter[] {
  const vehicleFilters: VehicleFilter[] = [];

  if (search && typeof search === "string") {
    vehicleFilters.push({ key: FilterKeys.Search, state: search });
  }

  return vehicleFilters;
}

function convertSortToFilters(
  sort: string | string[] | undefined,
  vehicleFilterData: VehicleFiltersData
): VehicleFilter[] {
  const vehicleFilters: VehicleFilter[] = [];

  if (sort && typeof sort === "string") {
    const sortByData = vehicleFilterData.sortBy.find((sb) =>
      valueMatches(sb.id, sort)
    );

    if (sortByData) {
      vehicleFilters.push({
        key: FilterKeys.SortBy,
        state: {
          id: sortByData.id,
          name: sortByData.name,
        },
      });
    }
  }

  return vehicleFilters;
}

function convertPaymentToFilters(
  payment: string | string[] | undefined,
  vehicleFilterData: VehicleFiltersData
): VehicleFilter[] {
  const vehicleFilters: VehicleFilter[] = [];

  if (payment && typeof payment === "string") {
    const paymentFilter = payment.split("-");
    if (paymentFilter.length > 4) {
      const paymentFrequency =
        vehicleFilterData.pricing.paymentFrequencies.find(
          (p) => p.id === +paymentFilter[2]
        );

      if (paymentFrequency) {
        vehicleFilters.push({
          key: FilterKeys.Payment,
          state: {
            minPayment: +paymentFilter[0],
            maxPayment: +paymentFilter[1],
            paymentFrequency,
            cashDown: +paymentFilter[3],
            tradeInValue: +paymentFilter[4],
          },
        });
      }
    }
  }

  return vehicleFilters;
}

function convertPriceToFilters(
  price: string | string[] | undefined
): VehicleFilter[] {
  const vehicleFilters: VehicleFilter[] = [];

  if (price && typeof price === "string") {
    const priceFilter = price.split("-");
    if (priceFilter.length > 1) {
      vehicleFilters.push({
        key: FilterKeys.Price,
        state: {
          minPrice: +priceFilter[0],
          maxPrice: +priceFilter[1],
        },
      });
    }
  }

  return vehicleFilters;
}

function convertLocationToFilters(
  location: string | string[] | undefined,
  vehicleFilterData: VehicleFiltersData
): VehicleFilter[] {
  const vehicleFilters: VehicleFilter[] = [];

  if (location && typeof location === "string") {
    const locationFilter = location.split(multiItemSeparator);

    locationFilter.forEach((f) => {
      const provinceData = vehicleFilterData.provincesAndLocations.find((x) =>
        x.locations.find((y) => valueMatches(y.displayName, f))
      );
      const locationData = provinceData?.locations.find((x) =>
        valueMatches(x.displayName, f)
      );

      if (provinceData && locationData) {
        vehicleFilters.push({
          key: FilterKeys.Location,
          state: {
            id: locationData.id,
            name: locationData.name,
            displayName: locationData.displayName,
            provinceId: provinceData.id,
          },
        });
      }
    });
  }

  return vehicleFilters;
}

function convertProvinceToFilters(
  province: string | string[] | undefined,
  vehicleFilterData: VehicleFiltersData
): VehicleFilter[] {
  const vehicleFilters: VehicleFilter[] = [];

  if (province && typeof province === "string") {
    const provinceFilter = province.split(multiItemSeparator);

    provinceFilter.forEach((f) => {
      const provinceData = vehicleFilterData.provincesAndLocations.find((x) =>
        valueMatches(x.name, f)
      );

      if (provinceData) {
        vehicleFilters.push({
          key: FilterKeys.Province,
          state: {
            id: provinceData.id.toString(),
            name: provinceData.name,
          },
        });
      }
    });
  }

  return vehicleFilters;
}

function convertPassengerCapacityToFilters(
  passengerCapacity: string | string[] | undefined,
  vehicleFilterData: VehicleFiltersData
): VehicleFilter[] {
  const vehicleFilters: VehicleFilter[] = [];

  if (passengerCapacity && typeof passengerCapacity === "string") {
    const passengerCapacityFilter = passengerCapacity.split(multiItemSeparator);

    passengerCapacityFilter.forEach((f) => {
      const passengerCapacityData = vehicleFilterData.passengerCapacities.find(
        (x) => valueMatches(x.toString(), f)
      );

      if (passengerCapacityData) {
        vehicleFilters.push({
          key: FilterKeys.PassengerCapacity,
          state: {
            id: passengerCapacityData.toString(),
            name: passengerCapacityData.toString(),
          },
        });
      }
    });
  }

  return vehicleFilters;
}

export default function convertToFilterState(
  query: ParsedUrlQuery,
  vehicleFilterData: VehicleFiltersData
): VehicleFilter[] {
  const {
    year,
    mileage,
    make,
    model,
    features,
    transmission,
    body,
    colour,
    saved,
    search,
    sort,
    efficiency,
    fuel,
    payment,
    price,
    location,
    province,
    slug,
    warranty,
    passengerCapacity,
  } = query;

  const cleanedSlug = slug ? normalizeSlug(slug) : "";

  return [
    ...convertBodyStyleToFilters(body, cleanedSlug, vehicleFilterData),
    ...convertMakeToFilters(make, cleanedSlug, vehicleFilterData),
    ...convertEfficiencyToFilters(efficiency),
    ...convertYearToFilters(year),
    ...convertMileageToFilters(mileage),
    ...convertModelToFilters(model, vehicleFilterData),
    ...convertFeaturesToFilters(features, vehicleFilterData),
    ...convertTransmissionToFilters(transmission, vehicleFilterData),
    ...convertColourToFilters(colour, vehicleFilterData),
    ...convertFuelToFilters(fuel, vehicleFilterData),
    ...convertSavedVehiclesToFilters(saved),
    ...convertVehiclesWarrantyToFilters(warranty),
    ...convertSearchToFilters(search),
    ...convertSortToFilters(sort, vehicleFilterData),
    ...convertPaymentToFilters(payment, vehicleFilterData),
    ...convertPriceToFilters(price),
    ...convertLocationToFilters(location, vehicleFilterData),
    ...convertProvinceToFilters(province, vehicleFilterData),
    ...convertPassengerCapacityToFilters(passengerCapacity, vehicleFilterData),
  ];
}
