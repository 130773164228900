import { useRouter } from "next/router";
import { useMemo } from "react";
import { UtmQueryParams } from "@modules/query-string/types";

const useUtmParamsFromQuery = (): UtmQueryParams => {
  const router = useRouter();
  const {
    utm_source: utmSource,
    utm_medium: utmMedium,
    utm_campaign: utmCampaign,
    utm_content: utmContent,
  } = router.query;

  const utmParamInfo: UtmQueryParams = useMemo(
    () => ({
      utmCampaign: utmCampaign ? (utmCampaign as string) : undefined,
      utmSource: utmSource ? (utmSource as string) : undefined,
      utmMedium: utmMedium ? (utmMedium as string) : undefined,
      utmContent: utmContent ? (utmContent as string) : undefined,
    }),
    [utmCampaign, utmSource, utmMedium, utmContent]
  );

  return utmParamInfo;
};

export const createQueryStringFromParams = (
  utmParams: UtmQueryParams
): string => {
  let utmParamsQueryString = "";

  if (utmParams.utmCampaign) {
    utmParamsQueryString += `&utm_campaign=${utmParams.utmCampaign}`;
  }

  if (utmParams.utmSource) {
    utmParamsQueryString += `&utm_source=${utmParams.utmSource}`;
  }

  if (utmParams.utmMedium) {
    utmParamsQueryString += `&utm_medium=${utmParams.utmMedium}`;
  }

  if (utmParams.utmContent) {
    utmParamsQueryString += `&utm_content=${utmParams.utmContent}`;
  }

  return utmParamsQueryString;
};

export const getSetUtmParams = (utmParams: UtmQueryParams) => {
  let existingUtmParams = {};

  if (utmParams.utmCampaign) {
    existingUtmParams = {
      utm_campaign: utmParams.utmCampaign,
    };
  }

  if (utmParams.utmSource) {
    existingUtmParams = {
      ...existingUtmParams,
      utm_source: utmParams.utmSource,
    };
  }

  if (utmParams.utmMedium) {
    existingUtmParams = {
      ...existingUtmParams,
      utm_medium: utmParams.utmMedium,
    };
  }

  if (utmParams.utmContent) {
    existingUtmParams = {
      ...existingUtmParams,
      utm_content: utmParams.utmContent,
    };
  }

  return existingUtmParams;
};

export default useUtmParamsFromQuery;
