import DotButton from "@common/components/DotButton";
import { ArrowRightIcon } from "@common/components/icons";
import { cleanParagraphTags } from "@common/helpers/cleanHtml";
import { MAX_PIXEL_WIDTH, MAX_PIXEL_HEIGHT } from "@common/constants";
import classNames from "classnames";
import useEmblaCarousel from "embla-carousel-react";
import { useCallback, useEffect, useState } from "react";
import CloudflareImagesImage from "@common/components/CloudflareImagesImage";
import ActionButton from "../enum/ActionButtonEnum";
import { GalleryAndContentLayout } from "../types/GalleryAndContentLayout";
import CmsButtonModalContainer from "./ButtonModalContainer";
import EmbeddedVideoPlayer from "./EmbeddedVideoPlayer";
import CmsButton from "./CmsButton";
import GetBackgroundStyle from "../helpers/getBackgroundStyle";

const GalleryAndContent = ({
  galleryAndContentGroup,
  isSubContent,
  htmlIdRef,
}: GalleryAndContentLayout) => {
  const [emblaRef, embla] = useEmblaCarousel({
    align: "start",
    active:
      galleryAndContentGroup.images && galleryAndContentGroup.images.length > 1,
  });
  const [buttonModalState, setButtonModalState] = useState<{
    show: boolean;
    actionType: ActionButton;
  }>({ show: false, actionType: ActionButton.UnAssigned });
  const [selectedIndex, setSelectedIndex] = useState(0);

  const hasButton =
    galleryAndContentGroup?.button && !galleryAndContentGroup?.button?.isHidden;

  const useVideo = !!(
    galleryAndContentGroup?.useVideo && galleryAndContentGroup?.contentVideo
  );

  // For videos, no option for fullHeight. Automatically true
  const isFullHeightImageClassname =
    galleryAndContentGroup?.isFullHeightImage || useVideo
      ? "relative"
      : "relative overflow-hidden h-60 md:h-80 lg:h-96";

  const isFullHeightImageProps = {
    ...(galleryAndContentGroup?.isFullHeightImage && {
      width: Number(MAX_PIXEL_WIDTH),
      height: Number(MAX_PIXEL_HEIGHT),
      color: "transparent",
    }),
    ...(!galleryAndContentGroup?.isFullHeightImage && {
      fill: true,
    }),
  };

  const scrollTo = useCallback(
    (index: number) => embla && embla.scrollTo(index),
    [embla]
  );

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on("select", onSelect);
  }, [embla, onSelect]);

  const renderButtonSection = () => {
    if (
      galleryAndContentGroup.button &&
      !galleryAndContentGroup.button.isHidden
    ) {
      return (
        <div className="mt-8 text-left items-start justify-start">
          <CmsButton
            button={galleryAndContentGroup.button}
            rightIcon={<ArrowRightIcon />}
            setButtonModalState={setButtonModalState}
            htmlIdRef={htmlIdRef}
          />
        </div>
      );
    }

    return null;
  };

  const renderVideoSection = () => {
    return (
      <div className="flex-1 w-full">
        <div className="relative">
          <div className="w-full overflow-hidden" ref={emblaRef}>
            <div className="flex select-none">
              <div className="relative min-w-full">
                <div className={isFullHeightImageClassname}>
                  <EmbeddedVideoPlayer
                    contentVideo={String(galleryAndContentGroup.contentVideo)}
                    roundEdges
                    fullWidth={
                      galleryAndContentGroup?.isFullWidthImage ?? false
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderImagesAndVideoSection = () => {
    if (useVideo) {
      return renderVideoSection();
    }
    if (galleryAndContentGroup.images && !useVideo) {
      return (
        <div className="flex-1 w-full">
          <div className="relative">
            <div className="w-full overflow-hidden" ref={emblaRef}>
              <div className="flex select-none">
                {galleryAndContentGroup.images.map((image) => (
                  <div className="relative min-w-full" key={image.sourceUrl}>
                    <div className={isFullHeightImageClassname}>
                      <CloudflareImagesImage
                        src={image.sourceUrl}
                        alt={image.altText}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...isFullHeightImageProps}
                        className={classNames(
                          "w-full object-cover h-auto rounded-lg",
                          {
                            "cursor-pointer":
                              galleryAndContentGroup.images.length > 1,
                          }
                        )}
                      />
                    </div>
                    {image.caption && (
                      <div
                        className="w-full caption-2 text-gray-700 pt-2"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: image.caption,
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>
              {galleryAndContentGroup.images.length > 1 && (
                <div className="absolute flex justify-center left-0 bottom-4 gap-x-3 w-full">
                  {galleryAndContentGroup.images.map((image, index) => (
                    <DotButton
                      key={image.sourceUrl}
                      selected={index === selectedIndex}
                      onClick={() => scrollTo(index)}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div
      id="cms-gallery-and-content"
      className="bg-white overflow-visible no-scrollbar relative bg-cover bg-center"
      style={GetBackgroundStyle(galleryAndContentGroup.background)}
    >
      <div>
        {galleryAndContentGroup.isFullWidthImage &&
          (galleryAndContentGroup.images || useVideo) && (
            <div
              className={classNames(
                "max-w-page mx-auto flex items-center flex-col gap-6",
                {
                  "px:0 py-2": isSubContent,
                  "px-4 lg:px-5 pt-10 pb-6 lg:py-16": !isSubContent,
                },
                {
                  "lg:gap-24": !hasButton,
                }
              )}
            >
              {renderImagesAndVideoSection()}
              {renderButtonSection()}
            </div>
          )}

        {!galleryAndContentGroup.isFullWidthImage && (
          <div
            className={classNames(
              "max-w-page mx-auto flex items-center flex-col gap-6 lg:gap-24",
              {
                "md:flex-row": galleryAndContentGroup.imagePosition === "Right",
                "md:flex-row-reverse":
                  galleryAndContentGroup.imagePosition === "Left",
                "px:0 py-2": isSubContent,
                "px-4 lg:px-5 pt-10 pb-6 lg:py-16": !isSubContent,
              }
            )}
          >
            <div className="flex-1">
              {galleryAndContentGroup.title && (
                <h3
                  className="text-text-light-100 text-2xl lg:text-4xl lg:tracking-tight"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: cleanParagraphTags(galleryAndContentGroup.title),
                  }}
                />
              )}
              <div
                className="mt-4 lg:mt-8 text-base font-normal lg:text-lg lg:leading-6 [&>ul]:list-disc [&>ul]:list-inside"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: galleryAndContentGroup.content,
                }}
              />
              {renderButtonSection()}
            </div>
            {renderImagesAndVideoSection()}
          </div>
        )}
      </div>
      {buttonModalState.show && (
        <CmsButtonModalContainer buttonModalState={buttonModalState} />
      )}
    </div>
  );
};

export default GalleryAndContent;
