import Modal, { Props as ModalProps } from "@common/components/Modal";
import { formatCurrency } from "@common/helpers/numberFormatting";
import { useRouter } from "next/router";
import { Trans, useTranslation } from "react-i18next";
import { PriceTagIcon } from "@common/components/icons";

type Props = {
  discountValue: number;
} & Pick<ModalProps, "isOpen" | "onClose">;

const WarrantyDiscountModal = ({ discountValue, isOpen, onClose }: Props) => {
  const { t } = useTranslation(["common", "purchase"]);
  const locale = useRouter().locale || "en";

  const handleButtonClick = () => {
    onClose();
  };

  return isOpen ? (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t("purchase:summer_fun_days")}
      alignment="center"
      customIcon={<PriceTagIcon className="w-24 h-24" />}
    >
      <div className="flex flex-col items-center justify-center">
        <h3 className="py-5 text-blueZodiac font-semibold text-center">
          {t("purchase:its_your_lucky_day")}
        </h3>
        <p className="body-2 text-center">
          <Trans
            t={t}
            i18nKey="purchase:to_kick_off_beginning_of_summer"
            values={{
              discountValue: formatCurrency(discountValue || 0, locale, {
                showCents: "never",
              }),
            }}
            components={{
              bold: <span className="font-semibold text-persianBlue" />,
            }}
          />
        </p>
        <p className="mt-4 body-2 text-center">
          {t("purchase:get_a_free_warranty_quote_and_see_discount")}
        </p>
        <Modal.Buttons
          primaryButtonText={t("common:get_started")}
          onPrimaryClick={handleButtonClick}
        />
      </div>
    </Modal>
  ) : null;
};
export default WarrantyDiscountModal;
