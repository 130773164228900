export enum PreQualificationModeName {
  introduction = "introduction",
  personalInfo = "creditAppPersonalInfo",
  residenceInfo = "residenceInfo",
  financialInfo = "financialInfo",
}

export type FinancingPreQualificationMode = {
  name: PreQualificationModeName;
};

export const introductionMode: FinancingPreQualificationMode = {
  name: PreQualificationModeName.introduction,
};

export const personalInfoMode: FinancingPreQualificationMode = {
  name: PreQualificationModeName.personalInfo,
};

export const residenceinfoMode: FinancingPreQualificationMode = {
  name: PreQualificationModeName.residenceInfo,
};

export const financialInfoMode: FinancingPreQualificationMode = {
  name: PreQualificationModeName.financialInfo,
};
