import Modal from "@common/components/Modal";
import { gtmPushData } from "@common/helpers/gtm";
import useUser from "@modules/user/hooks/useUser";
import { useTranslation } from "next-i18next";
import { useEffect } from "react";

type Props = {
  showUnexpectedErrorModal: boolean;
  setShowUnexpectedErrorModal: (show: boolean) => void;
  onPrimaryClick: () => void;
  onSecondaryClick?: () => void;
  primaryButtonText: string;
  secondaryButtonText?: string;
  title?: string;
  heading?: string;
  subheading?: string;
  body?: string;
};

const UnexpectedErrorModal = ({
  showUnexpectedErrorModal,
  setShowUnexpectedErrorModal,
  onPrimaryClick,
  onSecondaryClick,
  primaryButtonText,
  secondaryButtonText,
  title,
  heading,
  subheading,
  body,
}: Props) => {
  const { t } = useTranslation(["common", "purchase"]);
  const { user } = useUser();

  useEffect(() => {
    if (showUnexpectedErrorModal) {
      gtmPushData({
        event: "ICO",
        description: "ICO Unexpected Error",
        element: "ICO Landing Page",
        user_id: user?.id,
      });
    }
  }, [showUnexpectedErrorModal, user?.id]);

  return (
    <Modal
      isOpen={showUnexpectedErrorModal}
      onClose={() => setShowUnexpectedErrorModal(false)}
      title={title ?? t("purchase:something_went_wrong_error")}
      alignment="center"
      buttonDisplay="block"
    >
      <h1 className="text-primary-bold">{heading ?? t("common:oops")}</h1>
      <h3 className="mt-2 text-primary-deep">
        {subheading ?? t("purchase:unexpected_error_message")}
      </h3>
      <div className="b-2 mt-4">{body ?? t("purchase:please_come_back")}</div>

      <Modal.Buttons
        primaryButtonText={primaryButtonText}
        onPrimaryClick={onPrimaryClick}
        secondaryButtonText={secondaryButtonText}
        secondaryButtonFill="link"
        onSecondaryClick={onSecondaryClick}
        stretchButtonToContent
      />
    </Modal>
  );
};

export default UnexpectedErrorModal;
