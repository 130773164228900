import { ArrowRightIcon } from "@common/components/icons";
import { cleanHtml, cleanParagraphTags } from "@common/helpers/cleanHtml";
import classNames from "classnames";
import { useState } from "react";
import VehicleSearch from "@layouts/public/components/VehicleSearch";
import useUser from "@modules/user/hooks/useUser";
import CloudflareImagesImage from "@common/components/CloudflareImagesImage";
import { HeroHeadingLayout } from "../types/HeroHeadingLayout";
import ActionButton from "../enum/ActionButtonEnum";
import CmsButtonModalContainer from "./ButtonModalContainer";
import CmsButton from "./CmsButton";
import GetBackgroundStyle from "../helpers/getBackgroundStyle";

const HeroHeading = ({
  heroLabel,
  heroSubLabel,
  heroImage,
  imagePosition,
  button,
  background,
  search,
  htmlIdRef,
}: HeroHeadingLayout) => {
  const [buttonModalState, setButtonModalState] = useState<{
    show: boolean;
    actionType: ActionButton;
  }>({ show: false, actionType: ActionButton.UnAssigned });
  const { user } = useUser();

  return (
    <div
      id="cms-hero-heading"
      className="bg-white overflow-visible no-scrollbar relative bg-cover bg-center"
      style={GetBackgroundStyle(background)}
    >
      <div
        className={classNames(
          "relative max-w-page mx-auto flex items-center flex-col-reverse pt-10 pb-6 px-4 lg:px-5 lg:py-16",
          {
            "lg:flex-row": imagePosition === "Right",
            "lg:flex-row-reverse": imagePosition === "Left",
          }
        )}
      >
        <div className="flex-1 lg:basis-[40%] lg:px-0 md:px-28">
          <div>
            <div className="w-full items-center justify-center text-center sm:text-left sm:items-start sm:justify-start">
              <h1
                className="text-text-light-100 text-[2.25rem] md:text-[3.25rem] leading-[2.625rem] md:leading-[3.625rem]"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: cleanParagraphTags(heroLabel),
                }}
              />
            </div>

            <div className="mt-2 w-full items-center justify-center text-center sm:text-left sm:items-start sm:justify-start">
              <h4
                className="mt-4 lg:mt-8"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: cleanHtml(heroSubLabel),
                }}
              />
            </div>

            {button && !button.isHidden && !search.isActive && (
              <div className="mt-8 items-center justify-center text-center sm:text-left sm:items-start sm:justify-start">
                <CmsButton
                  button={button}
                  rightIcon={<ArrowRightIcon />}
                  setButtonModalState={setButtonModalState}
                  htmlIdRef={htmlIdRef}
                />
              </div>
            )}
            {search && search.isActive && (
              <VehicleSearch
                recentSearches={user?.recentSearches}
                savedSearches={user?.savedSearches}
                search={search}
              />
            )}
          </div>
        </div>
        <div className="flex-1 lg:basis-[60%] w-full relative">
          <div className="min-w-full h-auto relative inset-0">
            <div className="overflow-auto relative h-56 md:h-96">
              {heroImage && heroImage.sourceUrl && (
                <CloudflareImagesImage
                  src={heroImage.sourceUrl}
                  alt={heroImage.altText}
                  fill
                  className="z-[2] w-full object-contain h-auto rounded-lg"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {buttonModalState.show && (
        <CmsButtonModalContainer buttonModalState={buttonModalState} />
      )}
    </div>
  );
};

export default HeroHeading;
