import api from "@modules/api/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import purchaseQueryKeys from "../purchaseQueryKeys";

const postAbandonConsumerFinancing = async () => {
  return api.rawPost("api/purchases/abandon-consumer-financing");
};

export default function useAbandonConsumerFinancingMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postAbandonConsumerFinancing,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: purchaseQueryKeys.activeFinancingPurchase(),
      });
    },
  });
}
