import useEmblaCarousel from "embla-carousel-react";
import { useCallback, useEffect, useRef, useState } from "react";
import CarouselNavigation from "@common/components/CarouselNavigation";
import { GalleryAndContentCarouselLayout } from "../types/GalleryAndContentLayout";
import GalleryAndContent from "./GalleryAndContent";

const GalleryAndContentCarousel = ({
  galleryAndContentRepeater,
  isSubContent,
  htmlIdRef,
  urlHtmlId,
}: GalleryAndContentCarouselLayout & { urlHtmlId: string | null }) => {
  const [emblaRef, embla] = useEmblaCarousel({
    align: "start",
    active: galleryAndContentRepeater && galleryAndContentRepeater.length > 1,
    loop: true,
  });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isAutoScrolling, setIsAutoScrolling] = useState(true);
  const galleryContentCarouselRef = useRef<HTMLDivElement>(null);

  const scrollPrev = useCallback(() => {
    return embla && embla.scrollPrev();
  }, [embla]);
  const scrollNext = useCallback(() => {
    return embla && embla.scrollNext();
  }, [embla]);
  const scrollTo = useCallback(
    (index: number) => embla && embla.scrollTo(index),
    [embla]
  );

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on("select", onSelect);
  }, [embla, onSelect]);

  useEffect(() => {
    if (!embla) return undefined;
    if (isAutoScrolling) {
      const timer = setInterval(() => {
        if (embla.canScrollNext()) {
          embla.scrollNext();
        } else {
          embla.scrollTo(0);
        }
      }, 5000); // Change slides every 5 seconds
      return () => clearInterval(timer);
    }

    return () => undefined;
  }, [embla, isAutoScrolling]);

  const handleInteractionStart = useCallback(
    () => setIsAutoScrolling(false),
    []
  );
  const handleInteractionEnd = useCallback(() => setIsAutoScrolling(true), []);

  return (
    <div id="cms-gallery-and-content-repeater">
      <div
        className="max-w-page mx-auto flex items-center flex-col gap-6"
        ref={
          galleryAndContentRepeater[0]?.galleryAndContentGroup?.htmlId ===
          urlHtmlId
            ? htmlIdRef
            : undefined
        }
      >
        <div className="flex-1 w-full">
          <div
            className="relative"
            ref={galleryContentCarouselRef}
            role="button"
            tabIndex={0}
            onMouseDown={handleInteractionStart}
            onMouseUp={handleInteractionEnd}
            onMouseLeave={handleInteractionEnd}
          >
            <div className="w-full overflow-hidden" ref={emblaRef}>
              <div className="flex select-none">
                {galleryAndContentRepeater.map((content, index) => (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${content.galleryAndContentGroup.content}-${index}`}
                    className="flex-none w-full"
                  >
                    <GalleryAndContent
                      galleryAndContentGroup={content?.galleryAndContentGroup}
                      htmlIdRef={undefined}
                      isSubContent={isSubContent}
                    />
                  </div>
                ))}
              </div>
            </div>
            {galleryAndContentRepeater.length > 1 && (
              <div className="absolute flex justify-center left-0 bottom-4 gap-x-3 w-full">
                <CarouselNavigation
                  buttonColours="secondary"
                  contentList={galleryAndContentRepeater}
                  scrollNext={scrollNext}
                  scrollPrev={scrollPrev}
                  scrollTo={scrollTo}
                  selectedIndex={selectedIndex}
                  hiddenOnMobile
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GalleryAndContentCarousel;
