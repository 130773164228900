import api from "@modules/api/api";
import { useMutation } from "@tanstack/react-query";
import { QuoteMethodEnum } from "@common/enums/QuoteMethod";

export const decodeErrorResponse = {
  invalidVin: "Invalid VIN",
  ratesFailed: "Rates Failed",
};

type PostBody = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  year?: number | null;
  make?: string | null;
  model?: string | null;
  trim?: string | null;
  vin?: string | null;
  fuelType?: string | null;
  odometer: number;
  province: string;
  isConsentGiven: boolean;
  quoteMethod: QuoteMethodEnum | null;
  locale: string;
};

type CreateAftermarketInstantQuoteResponse = {
  success: boolean;
  message: string;
  decodedVin?: string;
};

const performPost = async ({ locale, ...postBody }: PostBody) => {
  const response = await api.post<CreateAftermarketInstantQuoteResponse>(
    "api/purchases/aftermarket-instant-quote",
    {
      json: postBody,
      headers: { "Accept-Language": locale },
    }
  );
  return response;
};

export default function useCreateAftermarketInstantQuoteMutation() {
  return useMutation({ mutationFn: performPost });
}
