import GetBackgroundStyle from "../helpers/getBackgroundStyle";
import {
  BodyStylesLayoutFields,
  BrandsLayoutFields,
  ButtonGroupsContentFields,
} from "../types/ButtonGroupsLayout";
import BodyStylesLayout from "./BodyStylesLayout";
import BrandsLayout from "./BrandsLayout";

type Props = {
  locale?: string;
} & ButtonGroupsContentFields;

const ButtonGroupsSection = ({
  locale = "en",
  buttonGroupsFlex,
  heading,
  headingAlignment,
  background,
}: Props) => {
  return (
    <div
      className="bg-white overflow-visible no-scrollbar relative bg-cover bg-center"
      style={GetBackgroundStyle(background)}
    >
      <div className="max-w-page mx-auto pt-10 pb-6 px-4 lg:px-5 lg:py-16">
        <h2
          className="text-primary-deep text-2xl leading-8 lg:text-4xl lg:leading-[3rem] font-semibold pb-8"
          style={{ textAlign: headingAlignment }}
        >
          {heading}
        </h2>
        {buttonGroupsFlex?.map((b, i) => {
          switch (b.typeName) {
            case "Page_Default_Flex_ButtonGroups_ButtonGroupsFlex_BodyStylesLayout": {
              const fields = b as unknown as BodyStylesLayoutFields;

              return (
                <BodyStylesLayout
                  // eslint-disable-next-line react/no-array-index-key
                  key={`body-styles-layout-${i}`}
                  locale={locale}
                  subheading={fields.subheading}
                  bodyStyles={fields.bodyStyles}
                />
              );
            }
            case "Page_Default_Flex_ButtonGroups_ButtonGroupsFlex_BrandsLayout": {
              const fields = b as unknown as BrandsLayoutFields;

              return (
                <BrandsLayout
                  // eslint-disable-next-line react/no-array-index-key
                  key={`brands-layout-${i}`}
                  subheading={fields.subheading}
                  brands={fields.brands}
                />
              );
            }

            case "Post_Default_Flex_ButtonGroups_ButtonGroupsFlex_BodyStylesLayout": {
              const fields = b as unknown as BodyStylesLayoutFields;

              return (
                <BodyStylesLayout
                  // eslint-disable-next-line react/no-array-index-key
                  key={`body-styles-layout-${i}`}
                  locale={locale}
                  subheading={fields.subheading}
                  bodyStyles={fields.bodyStyles}
                />
              );
            }
            case "Post_Default_Flex_ButtonGroups_ButtonGroupsFlex_BrandsLayout": {
              const fields = b as unknown as BrandsLayoutFields;

              return (
                <BrandsLayout
                  // eslint-disable-next-line react/no-array-index-key
                  key={`brands-layout-${i}`}
                  subheading={fields.subheading}
                  brands={fields.brands}
                />
              );
            }

            default:
              return null;
          }
        })}
      </div>
    </div>
  );
};

export default ButtonGroupsSection;
