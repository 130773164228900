import { useRouter } from "next/router";
import { useMemo } from "react";
import { SourceInformation, SourceQueryParams } from "../types";

const useSourceInfoFromQuery = (): SourceInformation => {
  const router = useRouter();
  const { adId, userId, sessionId, appId, locationId, ctaId, email, source } =
    router.query as SourceQueryParams;

  const sourceInformation: SourceInformation = useMemo(
    () => ({
      sourceAdId: adId || null,
      sourceUserId: userId || null,
      sourceSessionId: sessionId || null,
      sourceAppId: appId || null,
      sourceLocationId: locationId || null,
      sourceCtaId: ctaId || null,
      email: email || null,
      source: source || null,
    }),
    [adId, appId, ctaId, email, locationId, sessionId, source, userId]
  );

  return sourceInformation;
};

export default useSourceInfoFromQuery;
