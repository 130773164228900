import { usePhoneNumberMask } from "@common/hooks/useMask";
import { ChangeEvent, forwardRef } from "react";
import { BaseInputProps as InputProps } from "@common/types/FormInputProps";
import Form from ".";

type Props = Omit<InputProps, "maxLength" | "minLength" | "suppressHotjar">;

const PhoneInput = forwardRef<HTMLInputElement, Props>(
  ({ id = "phoneNumber", defaultValue, onChange, ...props }: Props, ref) => {
    const { maskPipe: maskPhoneNumberPipe, unMaskPipe: unMaskPhoneNumberPipe } =
      usePhoneNumberMask();

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
      const unmasked = unMaskPhoneNumberPipe(e.target.value);
      const masked = maskPhoneNumberPipe(unmasked);
      e.target.value = masked;

      if (onChange) onChange(e);
    };

    return (
      <Form.Input
        id={id}
        autoComplete="tel"
        minLength={16}
        maxLength={16}
        defaultValue={maskPhoneNumberPipe(defaultValue ?? "")}
        onChange={handleOnChange}
        suppressHotjar
        ref={ref}
        {...props}
      />
    );
  }
);

export default PhoneInput;
