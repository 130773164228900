import api from "@modules/api/api";
import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useRouter } from "next/router";
import purchaseQueryKeys from "../purchaseQueryKeys";
import { Purchase, PurchaseNotFoundResponse } from "../types/Purchase";

const getActiveAftermarketPurchase = (
  locale: string,
  token: string | undefined
) => {
  return api.get<Purchase | PurchaseNotFoundResponse>(
    `api/purchases/active-aftermarket${token ? `?token=${token}` : ""}`,
    {
      headers: { "Accept-Language": locale },
    }
  );
};

export default function useAftermarketPurchase() {
  const router = useRouter();
  const queryClient = useQueryClient();
  const token = router.query.token as string | undefined;

  const locale = router.locale || "en";
  const {
    data: purchase,
    isLoading,
    isFetching,
    isStale,
    refetch,
  } = useQuery({
    queryKey: purchaseQueryKeys.activeAftermarketPurchase(token),
    queryFn: () => getActiveAftermarketPurchase(locale, token),
    placeholderData: keepPreviousData,
    meta: { overrideDefaultErrorHandling: true },
    enabled: router.isReady,
  });

  if (token) {
    // invalidate the cache when a token is passed in.
    // when the user is redirected to the proper step, the activeAftermarketPurchase query will be refetched without the token
    queryClient.invalidateQueries({
      queryKey: purchaseQueryKeys.activeAftermarketPurchase(),
    });
  }

  return {
    purchase,
    isLoading,
    isFetching,
    isStale,
    refetch,
  };
}
