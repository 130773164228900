import { createContext, useContext } from "react";
import DealSegment from "@common/enums/DealSegment";
import {
  ExtendedWarrantyCoverageDetails,
  ExtendedWarrantyRangedRatesResponse,
  ExtendedWarrantyRatesResponse,
  ProductCoverage,
} from "./types/ExtendedWarrantyRates";
import { CoverageSearchBy } from "./components/aftermarket/AvailableCoveragesModal";

export type WarrantyQuotePageContextState = {
  onResumePurchase: () => void;
  onSelectCoverage: (
    selectedCoverage: ProductCoverage,
    selectedCoverageDetails: ExtendedWarrantyCoverageDetails
  ) => void;
  setShowCoveragesModal: (show: boolean) => void;
  setCoveragesModalSearchType: (searchType?: CoverageSearchBy) => void;
  setShowLinkBackEmailModal: (show: boolean) => void;
  purchasedVehicle: string;
  isSubmitted: boolean;
  defaultRates: {
    data: ExtendedWarrantyRatesResponse | undefined;
    isSuccess: boolean;
  };
  rangedRates: {
    data: ExtendedWarrantyRangedRatesResponse | undefined;
    isSuccess: boolean;
    isLoading: boolean;
  };
  dealSegment: DealSegment;
};

const WarrantyQuotePageContext = createContext<WarrantyQuotePageContextState>(
  {} as WarrantyQuotePageContextState
);

export const useWarrantyQuotePageContext = () =>
  useContext(WarrantyQuotePageContext);

export default WarrantyQuotePageContext;
