import { useTranslation } from "next-i18next";
import { ReactNode, useMemo } from "react";
import {
  ArrowRightIcon,
  ClockIcon,
  GaugeIcon,
  StackIcon,
  StarOnIcon,
} from "@common/components/icons";
import Button from "@common/components/Button";
import {
  FinancingPreQualificationMode,
  personalInfoMode,
} from "./types/FinancingPreQualificationMode";

type CreditAppIntroProps = {
  onChangeMode(newMode: FinancingPreQualificationMode): void;
};

type Labels = {
  label: string;
  subLabel: string;
  icon: ReactNode;
  id: number;
};

const FinancingPreQualificationIntroduction = ({
  onChangeMode,
}: CreditAppIntroProps) => {
  const { t } = useTranslation(["common", "purchase"]);

  const onStartPreQualForm = () => {
    onChangeMode(personalInfoMode);
  };

  const labelList: Labels[] = useMemo(
    () => [
      {
        label: t("common:take_less_than", {
          minutes: 2,
        }),
        subLabel: t(
          "purchase:get_started_by_providing_basic_info_and_location_details"
        ),
        icon: <ClockIcon className="block m-auto w-7 h-7 text-gray-500" />,
        id: 1,
      },
      {
        label: t("purchase:doesnt_affect_credit_score"),
        subLabel: t("purchase:dont_worry_pre_qual_doesnt_affect_credit_score"),
        icon: <GaugeIcon className="block m-auto w-7 h-7 text-gray-500" />,
        id: 2,
      },
      {
        label: t("purchase:get_sense_of_budget"),
        subLabel: t("purchase:we_will_help_give_sense_of_what_can_afford"),
        icon: <StarOnIcon className="block m-auto w-7 h-7 text-gray-500" />,
        id: 3,
      },
      {
        label: t("purchase:save_time_shopping_for_loans"),
        subLabel: t("purchase:right_ride_partners_with_most_major_banks"),
        icon: <StackIcon className="block m-auto w-7 h-7 text-gray-500" />,
        id: 4,
      },
    ],
    [t]
  );

  return (
    <div className="mx-auto mb-5 lg:w-5/6 md:w-3/4 sm:w-4/5 flex flex-col items-center">
      <div className="block w-full">
        {labelList.map((item) => (
          <div
            key={item.id}
            className="flex items-center gap-4 px-4 py-3 lg:py-5 caption-1 lg:leading-5"
          >
            <div className="bg-gray-25 inline-flex items-center justify-center rounded-full h-10 w-10 p-1">
              {item.icon}
            </div>
            <div className="flex-1">
              <p className="body-3 text-gray-900 text-left">{item.label}</p>
              <p className="caption-2 text-gray-900 text-left my-2">
                {item.subLabel}
              </p>
            </div>
          </div>
        ))}
      </div>
      <Button
        className="mt-6"
        spacing="tight-hug"
        rightIcon={<ArrowRightIcon className="h-5 w-5" />}
        onClick={onStartPreQualForm}
      >
        {t("purchase:get_pre_approved")}
      </Button>
    </div>
  );
};

export default FinancingPreQualificationIntroduction;
