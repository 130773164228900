import { SVGProps, Ref, forwardRef } from "react";

const ArrowThickRightIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Arrow Thick Right Icon"
    {...props}
  >
    <path d="M22.7 12.1 10.9 5.2v5.6H1.3v2.3h1.2l8.4.1v5.6z" />
  </svg>
);

const ForwardRef = forwardRef(ArrowThickRightIcon);
export default ForwardRef;
