import { Controller, SubmitHandler, useFormContext } from "react-hook-form";
import DivCheckbox from "@common/components/DivCheckbox";
import { ChangeEvent } from "react";
import {
  ApproximateBuyingTimeframe,
  approximateBuyingTimeframes,
  ApproximateBuyingTimeframeId,
} from "@common/types/ApproximateBuyingTimeframe";
import { useTranslation } from "react-i18next";
import Button from "@common/components/Button";
import ArrowLeftIcon from "@common/components/icons/ArrowLeftIcon";
import { ArrowRightIcon } from "@common/components/icons";
import useAftermarketPurchase from "@modules/purchase/hooks/useAftermarketPurchase";
import { tryGetActiveAftermarketPurchase } from "@modules/purchase/types/Purchase";
import {
  aftermarketDetailsStep,
  showResumeForStep,
  getStepById,
} from "@modules/purchase/types/PurchaseStep";
import { useRouter } from "next/router";
import useFeatureFlags from "@modules/feature-flags/hooks/useFeatureFlags";
import { FeatureFlags } from "@modules/feature-flags/types/FeatureFlag";
import { WarrantySegmentationFormProps } from "./types/WarrantySegmentationFormProps";
import {
  WarrantySegmentationMode,
  estimatedCoverageOptionMode,
  quoteFormMode,
  vehicleOwnershipStatusMode,
} from "./types/WarrantySegmentationMode";

type Props = {
  onChangeMode(newMode: WarrantySegmentationMode): void;
};

const WarrantyApproximateBuyingTimeframeQuestionnaireSegment = ({
  onChangeMode,
}: Props) => {
  const { t } = useTranslation(["common", "purchase"]);
  const router = useRouter();
  const { isEnabled } = useFeatureFlags();

  const { control, handleSubmit, setValue, watch } =
    useFormContext<WarrantySegmentationFormProps>();

  const selectedApproximateBuyingTimeframe = watch(
    "selectedApproximateBuyingTimeframe"
  );

  const onFormSubmit: SubmitHandler<WarrantySegmentationFormProps> = () => {
    onChangeMode(quoteFormMode);

    // Re-watch the value to get the updated one
    const updatedApproximateBuyingTimeframe = watch(
      "selectedApproximateBuyingTimeframe"
    );

    if (
      !isEnabled(FeatureFlags.AftermarketCostRange) ||
      updatedApproximateBuyingTimeframe ===
        ApproximateBuyingTimeframeId.InTheNextFewDays
    ) {
      onChangeMode(quoteFormMode);
    } else {
      onChangeMode(estimatedCoverageOptionMode);
    }
  };

  const handleCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>,
    objectValue: ApproximateBuyingTimeframe
  ) => {
    setValue("selectedApproximateBuyingTimeframe", objectValue.id);
    handleSubmit(onFormSubmit)();
  };

  const onClickBackButton = () => {
    onChangeMode(vehicleOwnershipStatusMode);
  };

  const { purchase: purchaseResponse } = useAftermarketPurchase();
  const purchase = tryGetActiveAftermarketPurchase(purchaseResponse);

  const showResumeButton =
    !!purchase && showResumeForStep(purchase.currentStepId);

  const handleOnResumePurchaseClick = () => {
    if (!purchase) return;

    const purchaseStep = getStepById(
      "aftermarket",
      purchase?.currentStepId ?? aftermarketDetailsStep.id
    );

    if (purchaseStep) {
      router.push({
        pathname: purchaseStep.href,
      });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="flex flex-col gap-3">
          <ul className="body-3 grid grid-cols-2 gap-2 auto-rows-fr md:px-8">
            {approximateBuyingTimeframes &&
              approximateBuyingTimeframes.map((vos) => (
                <li key={vos.id}>
                  <Controller
                    name="selectedApproximateBuyingTimeframe"
                    control={control}
                    render={() => (
                      <DivCheckbox
                        id={`selectedApproximateBuyingTimeframe-${vos.name}`}
                        readOnly
                        objectValue={{
                          id: vos.id,
                          name: vos.name,
                        }}
                        className="flex-col bg-white p-4"
                        onChangeEvent={handleCheckboxChange}
                        isChecked={
                          selectedApproximateBuyingTimeframe === vos.id
                        }
                        paddingSize="medium"
                      >
                        {vos.icon}
                        <span className="mt-2 caption-1">{t(vos.name)}</span>
                      </DivCheckbox>
                    )}
                  />
                </li>
              ))}
          </ul>
        </div>
      </form>
      {showResumeButton && (
        <div className="flex flex-col items-center space-y-4 mt-6">
          <Button
            rightIcon={<ArrowRightIcon />}
            onClick={handleOnResumePurchaseClick}
            buttonStyle="borderless"
            fill="link"
            spacing="no-padding"
            className="text-primary-bold underline hover:no-underline"
          >
            {t("purchase:resume_my_purchase")}
          </Button>
        </div>
      )}
      <div className="flex justify-center mt-6">
        <Button
          fill="link"
          buttonStyle="borderless"
          spacing="tight-hug"
          leftIcon={<ArrowLeftIcon />}
          className="text-primary-bold underline hover:no-underline"
          onClick={() => onClickBackButton()}
        >
          {t("common:go_back")}
        </Button>
      </div>
    </div>
  );
};

export default WarrantyApproximateBuyingTimeframeQuestionnaireSegment;
