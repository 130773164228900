import { useTranslation } from "next-i18next";
import classNames from "classnames";
import Button, { ButtonProps } from "../Button";
import { ArrowLeftIcon, ArrowRightIcon } from "../icons";

type Props = {
  hideBackButton?: boolean;
  hideNextButton?: boolean;
  backButton?: ButtonProps;
  nextButton?: ButtonProps;
  nextButtonText?: string;
  alignment?: "justify-start" | "justify-between";
};

const SubStepButtons = ({
  hideBackButton = false,
  hideNextButton = false,
  backButton,
  nextButton,
  nextButtonText,
  alignment = "justify-start",
}: Props) => {
  const { t } = useTranslation(["common"]);

  const fullWidthStyle = alignment === "justify-between" ? "w-1/2" : "";

  return (
    <div className={`flex ${alignment} gap-4`}>
      {!hideBackButton && (
        <Button
          buttonStyle="primary"
          size="large"
          spacing="hug"
          fill="outline"
          border="none"
          className={classNames(
            `${fullWidthStyle} disabled:border-2`,
            backButton?.className
          )}
          leftIcon={<ArrowLeftIcon />}
          isSubmit={false}
          disabled={backButton?.disabled}
          onClick={backButton?.onClick}
        >
          {t("common:back")}
        </Button>
      )}

      {!hideNextButton && (
        <Button
          buttonStyle="primary"
          size="large"
          spacing="hug"
          fill="filled"
          className={classNames(
            `${fullWidthStyle} inline-block`,
            nextButton?.className
          )}
          rightIcon={<ArrowRightIcon />}
          isSubmit={
            nextButton?.isSubmit !== undefined ? nextButton.isSubmit : true
          }
          disabled={nextButton?.disabled}
          onClick={nextButton?.onClick}
        >
          {nextButtonText ?? t("common:next")}
        </Button>
      )}
    </div>
  );
};

export default SubStepButtons;
