import { gtmPushData } from "@common/helpers/gtm";
import Button from "@common/components/Button";
import Form from "@common/components/form";
import Listbox from "@common/components/form/Listbox";
import { ArrowRightIcon } from "@common/components/icons";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import useCreateLeadMutation from "@modules/leads/hooks/useCreateLeadMutation";
import useWindowDimensions from "@common/hooks/useWindowDimensions";
import { Trans, useTranslation } from "next-i18next";
import TextLink from "@common/components/TextLink";
import Feedback from "@common/components/form/Feedback";
import { cleanParagraphTags } from "@common/helpers/cleanHtml";
import classNames from "classnames";
import cloudflareImagesLoader from "@common/helpers/imageLoaders/cloudflareImagesLoader";
import LoadingSpinner from "@common/components/LoadingSpinner";
import customerValidation from "@common/helpers/validations/customerValidation";
import { BackgroundImageGroup } from "../types/BackgroundImageGroup";
import { sizeClass } from "../types/SizeClass";
import FormSelectorBackgroundImage from "./FormSelectorBackgroundImage";
import { DEFAULT_FORM_SELECTOR_BACKGROUND_WIDTH } from "../constants/BackgroundImages";
import FormSubmissionModal from "./FormSubmissionModal";
import { CmsBackgroundType } from "../types/CmsBackground";
import GetBackgroundStyle from "../helpers/getBackgroundStyle";

type Props = {
  title: string;
  description: string;
  campaignTag: string;
  dealerId: string;
  backgroundImageGroup?: BackgroundImageGroup;
  background: CmsBackgroundType;
};

type ContactFormInput = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  message: string;
  purpose: { id: string; name: string };
  consent: boolean;
};

const LocationsContactForm = ({
  title,
  description,
  campaignTag,
  dealerId,
  backgroundImageGroup,
  background,
}: Props) => {
  const { t } = useTranslation(["common"]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<ContactFormInput>({
    mode: "onSubmit",
  });

  const options = [
    { id: "general", name: t("common:locations_contact_form_options_general") },
    { id: "sales", name: t("common:locations_contact_form_options_sales") },
    { id: "finance", name: t("common:locations_contact_form_options_finance") },
    { id: "parts", name: t("common:locations_contact_form_options_parts") },
    { id: "service", name: t("common:locations_contact_form_options_service") },
  ];

  const defaultOption = options[0];

  const [purpose, setPurpose] = useState(defaultOption);

  const createLeadMutation = useCreateLeadMutation();
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState<boolean>(false);

  const handleFormSubmit: SubmitHandler<ContactFormInput> = (
    formInput: ContactFormInput
  ) => {
    createLeadMutation.mutate(
      {
        lastName: formInput.lastName,
        firstName: formInput.firstName,
        phoneNumber: formInput.phoneNumber,
        email: formInput.email,
        originUrl: document.location.href,
        leadDescription: campaignTag,
        inquiry: formInput.purpose.name,
        message: formInput.message,
        dealerId,
        userDataConsentGiven: true, // Form has a disclaimer, so we can assume consent is given.
      },
      {
        onSuccess: () => {
          gtmPushData({
            event: "Locations",
            element: "Form",
            location: dealerId,
            descriptor: "Submit",
            firstName: formInput.firstName,
            lastName: formInput.lastName,
            email: formInput.email,
            phone: formInput.phoneNumber,
          });
          reset({
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            message: "",
            purpose: defaultOption,
            consent: false,
          });
          setIsSubmitModalOpen(true);
        },
      }
    );
  };

  const [width, setWidth] = useState(0);
  const { width: w } = useWindowDimensions();

  useEffect(() => {
    setWidth(w);
  }, [w]);

  return (
    <div
      className="bg-white overflow-visible no-scrollbar relative bg-cover bg-center"
      style={GetBackgroundStyle(background)}
    >
      <LoadingSpinner isLoading={createLeadMutation.isPending} isOverlay />
      {isSubmitModalOpen && (
        <FormSubmissionModal
          isOpen={isSubmitModalOpen}
          setState={setIsSubmitModalOpen}
        />
      )}
      <div
        className={classNames({
          "bg-no-repeat": backgroundImageGroup?.image,
          "bg-[center_right_-6rem]":
            backgroundImageGroup?.imagePosition === "Right" &&
            width < sizeClass["2xl"] &&
            width >= sizeClass.xl,
          "bg-[center_right_2rem]":
            backgroundImageGroup?.imagePosition === "Right" &&
            width < sizeClass["3xl"] &&
            width >= sizeClass["2xl"],
          "bg-[center_right_8rem]":
            backgroundImageGroup?.imagePosition === "Right" &&
            width < sizeClass["4xl"] &&
            width >= sizeClass["3xl"],
          "bg-[center_right_20rem]":
            backgroundImageGroup?.imagePosition === "Right" &&
            width >= sizeClass["4xl"],

          "bg-[center_left_-6rem]":
            backgroundImageGroup?.imagePosition === "Left" &&
            width < sizeClass["2xl"] &&
            width >= sizeClass.xl,
          "bg-[center_left_2rem]":
            backgroundImageGroup?.imagePosition === "Left" &&
            width < sizeClass["3xl"] &&
            width >= sizeClass["2xl"],
          "bg-[center_left_8rem]":
            backgroundImageGroup?.imagePosition === "Left" &&
            width < sizeClass["4xl"] &&
            width >= sizeClass["3xl"],
          "bg-[center_left_20rem]":
            backgroundImageGroup?.imagePosition === "Left" &&
            width >= sizeClass["4xl"],
        })}
        style={
          width >= sizeClass.xl &&
          backgroundImageGroup?.image &&
          backgroundImageGroup.image.length > 0
            ? {
                backgroundImage: `url(${cloudflareImagesLoader({
                  src: backgroundImageGroup?.image[0].sourceUrl || "",
                  width: DEFAULT_FORM_SELECTOR_BACKGROUND_WIDTH,
                })})`,
              }
            : {}
        }
      >
        <div
          className={classNames(
            "relative max-w-page mx-auto lg:mx-0 xl:mx-auto flex items-center py-10",
            {
              "flex-col": backgroundImageGroup,
              "px-5 items-center lg:items-stretch":
                !backgroundImageGroup ||
                !backgroundImageGroup.image ||
                (backgroundImageGroup.image &&
                  backgroundImageGroup.image.length === 0),
              "lg:flex-row px-5 lg:pl-5 lg:pr-0 xl:px-5":
                backgroundImageGroup?.imagePosition === "Right" &&
                backgroundImageGroup.image &&
                backgroundImageGroup.image.length > 0,
              "lg:flex-row-reverse px-5 lg:pl-0 lg:pr-5 xl:px-5":
                backgroundImageGroup?.imagePosition === "Left" &&
                backgroundImageGroup.image &&
                backgroundImageGroup.image.length > 0,
            }
          )}
        >
          <div
            className={classNames("", {
              "flex-1 lg:basis-[60%] xl:basis-[50%]":
                backgroundImageGroup?.image,
            })}
          >
            {/* eslint-disable react/no-danger */}
            <h5
              className="text-text-light-100 text-2xl lg:text-4xl lg:tracking-tight"
              dangerouslySetInnerHTML={{ __html: cleanParagraphTags(title) }}
            />
            <p
              className="body-2"
              dangerouslySetInnerHTML={{
                __html: cleanParagraphTags(description),
              }}
            />
            {/* eslint-enable */}
            <form
              onSubmit={handleSubmit(handleFormSubmit)}
              className="my-5 flex flex-col gap-5 md:grid md:grid-cols-2"
            >
              <Form.FirstName
                fieldError={errors.firstName}
                {...register("firstName", customerValidation.firstName)}
              />
              <Form.LastName
                fieldError={errors.lastName}
                {...register("lastName", customerValidation.lastName)}
              />
              <Form.Email
                fieldError={errors.email}
                {...register("email", customerValidation.email)}
              />
              <Form.Phone
                fieldError={errors.phoneNumber}
                {...register("phoneNumber", customerValidation.phoneNumber)}
              />
              <div className="col-span-2">
                <Controller
                  control={control}
                  rules={{
                    onChange: (e) => setPurpose(e.target.value),
                    required: {
                      value: true,
                      message: t("common:required"),
                    },
                  }}
                  defaultValue={defaultOption}
                  name="purpose"
                  render={({ field: { onChange } }) => (
                    <Listbox
                      status={errors.purpose ? "error" : "default"}
                      feedback={errors.purpose?.message}
                      displayButton={(i) => i?.name || ""}
                      displayOption={(i) => i?.name || ""}
                      valueOption={(i) => i}
                      keyOption={(i) => i.id}
                      label={t("common:locations_contact_form_inquiring_about")}
                      items={options}
                      selectedItem={purpose}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <Form.TextArea
                label="Message"
                feedback={errors.message?.message}
                status={errors.message ? "error" : "default"}
                className="col-span-2"
                maxLength={200}
                {...register("message", {
                  required: {
                    value: true,
                    message: t("common:required"),
                  },
                })}
              />
              <Form.Checkbox
                label={t("common:general_form_agreement")}
                className="col-span-2"
                {...register("consent", { required: true })}
              />
              {errors.consent && (
                <Feedback status="error">
                  {t("common:form_error_required_consent")}
                </Feedback>
              )}
              <div className="flex flex-col gap-5 md:flex-row md:items-center md:col-span-2">
                <Button
                  isSubmit
                  buttonStyle="primary"
                  rightIcon={<ArrowRightIcon />}
                  className="flex-shrink-0"
                >
                  {t("common:get_in_touch")}
                </Button>
              </div>
              <div className="flex flex-col justify-center md:justify-start gap-5 md:flex-row md:items-center md:col-span-2">
                <Trans t={t} i18nKey="common:general_form_disclaimer">
                  <p className="italic text-xs">
                    <TextLink href="/privacy-policy">
                      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                      <a target="_blank" />
                    </TextLink>
                    <TextLink href="/terms-conditions">
                      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                      <a target="_blank" />
                    </TextLink>
                  </p>
                </Trans>
              </div>
            </form>
          </div>
          {backgroundImageGroup &&
            backgroundImageGroup.image &&
            backgroundImageGroup.image.length > 0 && (
              <FormSelectorBackgroundImage
                backgroundImageGroup={backgroundImageGroup}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default LocationsContactForm;
