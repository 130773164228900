import { EmailIcon, PhoneIcon } from "@common/components/icons";
import React, { ReactNode } from "react";
import { TranslationKeys } from "src/@types/i18next";

export enum QuoteMethodEnum {
  Email = 1,
  Sms = 2,
}

export type QuoteMethod = {
  id: number;
  name: TranslationKeys["common"];
  icon?: ReactNode;
};

export const quoteMethods: QuoteMethod[] = [
  {
    id: QuoteMethodEnum.Email,
    name: "common:email",
    icon: React.createElement(EmailIcon, {
      className: "block text-blueZodiac m-auto w-6 h-6",
    }),
  },
  {
    id: QuoteMethodEnum.Sms,
    name: "common:phone",
    icon: React.createElement(PhoneIcon, {
      className: "block text-blueZodiac m-auto w-6 h-6",
    }),
  },
];

export function getQuoteMethods(id: number) {
  return quoteMethods.find((s) => s.id === id);
}

export default QuoteMethod;
