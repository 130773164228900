import { formatCurrency } from "@common/helpers/numberFormatting";
import translate from "@common/helpers/translate";
import { FinancingHeadingLayout } from "@modules/cms/types/FinancingHeadingLayout";
import FinancingPaymentAmountContext, {
  FinancingPageState,
} from "@modules/purchase/ConsumerFinancingPageContext";
import { useTranslation, Trans } from "next-i18next";
import { useRouter } from "next/router";
import { useContext } from "react";
import useConsumerFinancingQuery from "@modules/purchase/hooks/useConsumerFinancingFromQuery";
import Button from "@common/components/Button";
import LocalImage from "@common/components/LocalImage";
import { ImageWithTranslation } from "@common/types/ImageWithTranslation";
import {
  BubblesFinancingEn,
  BubblesFinancingFr,
  FinancingHeroEn,
  FinancingHeroFr,
} from "public/images/purchase/consumer";
import { Purchase } from "@modules/purchase/types/Purchase";
import classNames from "classnames";
import { ArrowRightIcon } from "@common/components/icons";
import useConsumerFinancing, {
  checkActiveConsumerFinancing,
} from "@modules/purchase/hooks/useConsumerFinancing";
import OverlayLoader from "@common/components/OverlayLoader";
import {
  getFirstStep,
  getStepById,
  getStepPathname,
} from "@modules/purchase/types/PurchaseStep";
import useStartConsumerFinancingMutation from "@modules/purchase/hooks/useStartConsumerFinancingMutation";
import useAbandonConsumerFinancingMutation from "@modules/purchase/hooks/useAbandonConsumerFinancingMutation";
import { useRawBuyerInfoFromQuery } from "@modules/query-string/hooks/useBuyerInfoFromQuery";
import GetBackgroundStyle from "@modules/cms/helpers/getBackgroundStyle";
import useUtmParamsFromQuery, {
  getSetUtmParams,
} from "@modules/query-string/hooks/useUtmParamsFromQuery";
import PurchaseSourceVehicleImage from "../PurchaseSourceVehicleImage";

type Props = {
  layout: FinancingHeadingLayout;
};

// TODO: Swap image assets for financing when it becomes available.
const vehicleDefaultImage: ImageWithTranslation = {
  srcEn: FinancingHeroEn,
  srcFr: FinancingHeroFr,
  altKey: "purchase:alt_c2c_vehicle_img",
};

const bubblesImage: ImageWithTranslation = {
  srcEn: BubblesFinancingEn,
  srcFr: BubblesFinancingFr,
  altKey: "purchase:alt_c2c_info_img",
};

const FinancingHeading = ({ layout }: Props) => {
  const { t } = useTranslation(["common", "purchase"]);
  const router = useRouter();
  const { locale } = router;
  const { source } = router.query;
  const { background } = layout;

  const { financingState } = useContext(FinancingPaymentAmountContext);

  const [state] = financingState ?? [{}];

  const data: FinancingPageState = {
    paymentAmount: state.paymentAmount ?? 0,
    paymentFrequency: state.paymentFrequency ?? null,
  };

  const {
    consumerFinancing: consumerFinancingOrNotFound,
    isLoading: isConsumerFinancingLoading,
  } = useConsumerFinancing();
  const consumerFinancingQuery = useConsumerFinancingQuery();
  const consumerFinancing = consumerFinancingOrNotFound as Purchase;
  const { year, make, model, coverImage } = consumerFinancingQuery;
  const vehicleCoverImage = coverImage || consumerFinancing?.vehicle?.image;
  const startConsumerFinancingMutation = useStartConsumerFinancingMutation();
  const abandonConsumerFinancingMutation =
    useAbandonConsumerFinancingMutation();
  const buyerInfoParams = useRawBuyerInfoFromQuery();
  const utmParams = useUtmParamsFromQuery();

  const handleResumeClick = () => {
    if (consumerFinancing && consumerFinancing.currentStepId) {
      const resumeStep = getStepById(
        "financing",
        consumerFinancing.currentStepId
      );

      if (resumeStep) {
        router.push({
          pathname: getStepPathname(resumeStep, true),
        });
      }
    }
  };

  const startFinancingApplication = () => {
    startConsumerFinancingMutation.mutate(
      { ...consumerFinancingQuery, ...utmParams },
      {
        onSuccess: () => {
          router.push({
            pathname: getFirstStep("financing").introHref,
            query: {
              source,
              ...buyerInfoParams,
              ...getSetUtmParams(utmParams),
            },
          });
        },
      }
    );
  };

  const handleOverwriteClick = () => {
    abandonConsumerFinancingMutation.mutate(undefined, {
      onSuccess: startFinancingApplication,
    });
  };

  const isConsumerFinancingFound = checkActiveConsumerFinancing(
    consumerFinancingOrNotFound
  );

  return (
    <OverlayLoader
      isLoading={
        isConsumerFinancingLoading || startConsumerFinancingMutation.isPending
      }
    >
      <div
        id="cms-financing-heading-section"
        className="bg-white overflow-visible no-scrollbar relative bg-cover bg-center"
        style={GetBackgroundStyle(background)}
      >
        <div
          className={classNames(
            "relative max-w-page mx-auto flex items-center flex-col-reverse pt-10 pb-6 px-4 lg:px-5 lg:py-16 lg:flex-row-reverse",
            {
              "gap-4": !!coverImage,
            }
          )}
        >
          <div className="flex-1 lg:basis-[40%] lg:px-0 md:px-28">
            <div className="w-full items-center justify-center text-center lg:text-left lg:items-start lg:justify-start">
              <p className="text-text-light-100 font-semibold text-[2.25rem] md:text-[3.25rem] leading-[2.625rem] md:leading-[3.625rem]">
                {year &&
                make &&
                model &&
                data.paymentAmount !== 0 &&
                data.paymentFrequency ? (
                  <Trans
                    t={t}
                    i18nKey="purchase:interested_in_financing_specific_vehicle"
                    values={{
                      vehicle: `${year} ${make} ${model}`,
                      payment: formatCurrency(
                        Math.round(data.paymentAmount),
                        locale || "en",
                        { showCents: "never" }
                      ),
                      frequency: translate(
                        data.paymentFrequency.name,
                        locale || "en"
                      ),
                    }}
                    components={{
                      bold: <span className="text-primary-bold" />,
                    }}
                  />
                ) : (
                  <Trans
                    t={t}
                    i18nKey="purchase:interested_in_financing_a_vehicle"
                    components={{
                      bold: <span className="text-primary-bold" />,
                    }}
                  />
                )}
              </p>
            </div>

            <div className="mt-2 w-full items-center justify-center text-center lg:text-left lg:items-start lg:justify-start">
              <h4 className="mt-4 lg:mt-8">
                <Trans
                  t={t}
                  i18nKey="purchase:purchasing_a_car_description"
                  components={{
                    bold: <span className="font-semibold" />,
                  }}
                />
              </h4>
            </div>

            <div className="flex flex-col gap-2 mt-8 justify-center text-center md:items-center lg:text-start lg:items-start lg:justify-start">
              {isConsumerFinancingFound ? (
                <>
                  <Button
                    rightIcon={<ArrowRightIcon />}
                    onClick={handleResumeClick}
                  >
                    {t("purchase:resume_my_application")}
                  </Button>
                  <Button
                    fill="link"
                    className="underline lg:pl-0"
                    rightIcon={<ArrowRightIcon />}
                    onClick={handleOverwriteClick}
                  >
                    {t("purchase:start_new_application")}
                  </Button>
                </>
              ) : (
                <Button
                  rightIcon={<ArrowRightIcon />}
                  onClick={startFinancingApplication}
                >
                  {t("purchase:apply_for_financing")}
                </Button>
              )}
            </div>
          </div>
          <div className="flex-1 lg:basis-[60%] w-full relative">
            <div className="min-w-full h-auto relative inset-0">
              {vehicleCoverImage ? (
                <div>
                  <div className="absolute -right-4 md:-right-1 lg:-right-14 xl:-right-10 z-[2]">
                    <LocalImage
                      className="h-48 w-40 md:h-96 md:w-96 object-contain rounded-lg"
                      src={
                        locale === "fr"
                          ? bubblesImage.srcFr
                          : bubblesImage.srcEn
                      }
                      alt={t(bubblesImage.altKey)}
                    />
                  </div>

                  <div className="flex justify-center overflow-hidden relative md:h-full">
                    <div className="rounded-full border-white border-[.5rem] mr-9 md:mr-32 lg:mr-44 h-80 w-80 md:border-[1rem] md:h-[27rem] md:w-[27rem] lg:h-[30rem] lg:w-[30rem]">
                      <PurchaseSourceVehicleImage
                        className="rounded-full w-full h-full object-cover"
                        src={vehicleCoverImage}
                        alt={t(vehicleDefaultImage.altKey)}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="overflow-auto relative h-56 md:h-96 lg:mr-4">
                  <LocalImage
                    className="z-[2] w-full object-contain h-auto rounded-lg"
                    src={
                      locale === "fr"
                        ? vehicleDefaultImage.srcFr
                        : vehicleDefaultImage.srcEn
                    }
                    alt={t(vehicleDefaultImage.altKey)}
                    fill
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </OverlayLoader>
  );
};

export default FinancingHeading;
