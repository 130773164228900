import { cleanHtml } from "@common/helpers/cleanHtml";
import { WarrantyVehicleComponentLayout } from "@modules/cms/types/WarrantyVehicleComponentLayout";
import {
  SedanEn,
  SedanFr,
  SuvEn,
  SuvFr,
  TruckEn,
  TruckFr,
} from "@public/images/purchase/coverage/repair-cost";
import { useRouter } from "next/router";
import { MAX_PIXEL_HEIGHT, MAX_PIXEL_WIDTH } from "@common/constants";
import BodyStyle from "@common/enums/BodyStyle";
import LocalImage from "@common/components/LocalImage";
import { ImageWithTranslation } from "@common/types/ImageWithTranslation";
import { useTranslation } from "next-i18next";
import GetBackgroundStyle from "@modules/cms/helpers/getBackgroundStyle";
import { useWarrantyQuotePageContext } from "@modules/purchase/WarrantyQuotePageContext";

type Props = { layout: WarrantyVehicleComponentLayout };

const getRepairCostImage = (bodyTypeId: number): ImageWithTranslation => {
  const sedan: ImageWithTranslation = {
    srcEn: SedanEn,
    srcFr: SedanFr,
    altKey: "purchase:alt_repair_cost_sedan",
  };

  const suv: ImageWithTranslation = {
    srcEn: SuvEn,
    srcFr: SuvFr,
    altKey: "purchase:alt_repair_cost_suv",
  };

  const truck: ImageWithTranslation = {
    srcEn: TruckEn,
    srcFr: TruckFr,
    altKey: "purchase:alt_repair_cost_truck",
  };

  switch (bodyTypeId) {
    case BodyStyle.Sedan:
    case BodyStyle.Coupe:
    case BodyStyle.Convertible:
    case BodyStyle.Hatchback:
      return sedan;
    case BodyStyle.SUV:
    case BodyStyle.Minivan:
    case BodyStyle.Wagon:
      return suv;
    case BodyStyle.Truck:
      return truck;
    default:
      return sedan;
  }
};

const WarrantyVehicleComponent = ({ layout }: Props) => {
  const { t } = useTranslation(["common", "purchase"]);
  const { locale } = useRouter();
  const { heading, background } = layout;

  const { defaultRates, rangedRates } = useWarrantyQuotePageContext();
  const warrantyRates = rangedRates.data || defaultRates.data;

  const repairImage = getRepairCostImage(
    layout.isDynamicVehicleImage ? warrantyRates?.asset?.bodyStyleId ?? 0 : 0
  );

  return (
    <div
      id="cms-warranty-vehicle-repair-section"
      className="bg-white overflow-visible no-scrollbar relative bg-cover bg-center "
      style={GetBackgroundStyle(background)}
    >
      <div className="max-w-page mx-auto pt-10 pb-6 px-2 sm:px-4 lg:px-5 lg:py-16">
        <div className="flex justify-center pb-16">
          <h3
            className="text-text-light-100 text-2xl lg:text-4xl lg:tracking-tight"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: cleanHtml(heading),
            }}
          />
        </div>

        <LocalImage
          src={locale === "fr" ? repairImage.srcFr : repairImage.srcEn}
          alt={t(repairImage.altKey)}
          width={Number(MAX_PIXEL_WIDTH)}
          height={Number(MAX_PIXEL_HEIGHT)}
          color="transparent"
          className="w-full object-cover h-auto rounded-lg"
        />

        <p className="text-xs md:text-base text-center pt-6 md:pt-12">
          {t("purchase:vehicle_repair_cost_disclaimer")}
        </p>
      </div>
    </div>
  );
};

export default WarrantyVehicleComponent;
