import { provinces } from "@common/types/Province";
import { useMemo } from "react";
import useEligibleProvinces, {
  GetResponseProvince,
} from "./useEligibleProvinces";

export default function useProvincesStatusBySourceList(
  dealSource?: string | null,
  provinceFilter?: (value: GetResponseProvince) => boolean
) {
  const { provinces: enabledProvinces } = useEligibleProvinces(
    dealSource ?? undefined,
    provinceFilter
  );

  const enabledProvincesList = useMemo(
    () => enabledProvinces.map((p) => ({ ...p, isEnabled: true })),
    [enabledProvinces]
  );

  const disabledProvincesList = useMemo(
    () =>
      provinces
        .filter((p) => !enabledProvinces.includes(p))
        .map((p) => ({ ...p, isEnabled: false })),
    [enabledProvinces]
  );

  return [...enabledProvincesList, ...disabledProvincesList];
}
