import Button from "@common/components/Button";
import { cleanParagraphTags } from "@common/helpers/cleanHtml";
import { VLP_PATH } from "@common/helpers/isVehicleListingPage";
import cleanVlpQueryParam from "@modules/vehicle/helpers/cleanVlpQueryParam";
import getMakeIcon from "@modules/vehicle/helpers/getMakeIcon";
import useGetVehicleFilterData from "@modules/vehicle/hooks/useVehicleFilterData";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useMemo, useState } from "react";
import { BrandsLayoutFields } from "../types/ButtonGroupsLayout";

type Props = Omit<BrandsLayoutFields, "typeName">;

const iconClassName = "m-auto w-20 h-16";
const initialMakesToShow = 10;

const BrandsLayout = ({ subheading = "", brands }: Props) => {
  const { t } = useTranslation(["common"]);
  const [viewAllMakes, setViewAllMakes] = useState<boolean>(false);
  const { vehicleFilters: vehicleFiltersData } = useGetVehicleFilterData();

  const makes = useMemo(() => {
    return brands?.filter((brand) =>
      vehicleFiltersData?.makesAndModels?.find((mm) => mm.name === brand)
    );
  }, [brands, vehicleFiltersData?.makesAndModels]);

  return (
    <div>
      {subheading && (
        <h3
          className="pt-6 pb-4"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: cleanParagraphTags(subheading),
          }}
        />
      )}
      <div className="body-3 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-2">
        {makes?.map((make, i) => {
          const showMake = i <= initialMakesToShow;
          const BrandIcon = getMakeIcon(make);

          return (
            make && (
              <Link
                href={`/${VLP_PATH}/${cleanVlpQueryParam(make)}`}
                key={`link-body-style-${make}`}
                className={classNames({
                  block: showMake && viewAllMakes,
                  hidden: !showMake && !viewAllMakes,
                })}
              >
                <Button
                  buttonStyle="filter"
                  fill="outline"
                  className="rounded-[4px] active:bg-gray-25"
                >
                  <div className="py-1">
                    <BrandIcon className={`${iconClassName} text-gray-100`} />
                    {make}
                  </div>
                </Button>
              </Link>
            )
          );
        })}
        {!viewAllMakes && (
          <Button
            buttonStyle="filter"
            fill="outline"
            className="rounded-[4px] active:bg-gray-25"
            onClick={() => setViewAllMakes(true)}
          >
            <div className="py-1 relative">
              <span className="absolute top-1/2 text-primary-bold translate-y-[-50%]">
                {t("common:view_all")}
              </span>
              <span className="invisible">{t("common:view_all")}</span>
            </div>
          </Button>
        )}
      </div>
    </div>
  );
};

export default BrandsLayout;
