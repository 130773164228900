import { ReplaceEmbedSources } from "@common/helpers/frameHelper";
import classNames from "classnames";
import { EmbeddedVideoPlayerLayout } from "../types/EmbeddedVideoPlayerLayout";

const EmbeddedVideoPlayer = ({
  contentVideo,
  roundEdges = true,
  fullWidth = true,
}: EmbeddedVideoPlayerLayout) => {
  if (!contentVideo) {
    return null;
  }
  const embedSource = ReplaceEmbedSources(contentVideo);

  return (
    <div id="cms-embedded-video-player">
      <div
        className={classNames({
          "aspect-w-16 aspect-h-9": fullWidth,
          "aspect-w-16 h-96": !fullWidth,
        })}
      >
        <iframe
          className={classNames({ "rounded-lg overflow:hidden": roundEdges })}
          src={embedSource}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          title="video"
        />{" "}
      </div>
    </div>
  );
};

export default EmbeddedVideoPlayer;
