import { ImageWithTranslation } from "@common/types/ImageWithTranslation";
import LocalImage from "@common/components/LocalImage";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import PurchaseSourceVehicleImage from "./PurchaseSourceVehicleImage";

type Props = {
  vehicleCoverImage?: string | null;
  vehicleDefaultImage: ImageWithTranslation;
  bubblesImage: ImageWithTranslation;
};

const VehicleCoverImageFrame = ({
  vehicleCoverImage,
  vehicleDefaultImage,
  bubblesImage,
}: Props) => {
  const { t } = useTranslation(["purchase", "common"]);
  const locale = useRouter().locale || "en";

  return (
    <div className="relative min-w-full inset-0">
      {vehicleCoverImage ? (
        <div className="flex justify-center overflow-hidden md:h-full">
          <div className="relative w-full max-w-[343px] md:max-w-xl lg:max-w-2xl xl:max-w-[45rem]">
            <div className="rounded-full border-white border-[.4rem] md:border-[1rem] lg:ml-4 xl:ml-9 h-64 w-64 md:h-[27rem] md:w-[27rem] lg:h-[30rem] lg:w-[30rem]">
              <PurchaseSourceVehicleImage
                className="rounded-full w-full h-full object-cover"
                src={vehicleCoverImage}
                alt={t(vehicleDefaultImage.altKey)}
              />
            </div>
            <div className="absolute -right-4 md:-right-10 lg:-right-4 xl:-right-9 2xl:-right-7 top-4 z-[2]">
              <LocalImage
                className="h-48 w-40 md:h-80 md:w-80 xl:h-96 xl:w-96 object-contain rounded-lg"
                src={locale === "fr" ? bubblesImage.srcFr : bubblesImage.srcEn}
                alt={t(bubblesImage.altKey)}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="overflow-auto relative h-56 md:h-96">
          <LocalImage
            className="z-[2] w-full object-contain h-auto rounded-lg"
            src={
              locale === "fr"
                ? vehicleDefaultImage.srcFr
                : vehicleDefaultImage.srcEn
            }
            alt={t(vehicleDefaultImage.altKey)}
            fill
          />
        </div>
      )}
    </div>
  );
};

export default VehicleCoverImageFrame;
