import IcoBubblesEn from "./ico-bubbles-en.png";
import IcoBubblesFr from "./ico-bubbles-fr.png";
import InstantCashOfferHeroEn from "./ico-hero-en.png";
import InstantCashOfferHeroFr from "./ico-hero-fr.png";

export {
  IcoBubblesEn,
  IcoBubblesFr,
  InstantCashOfferHeroEn,
  InstantCashOfferHeroFr,
};
