import { formatCurrency } from "@common/helpers/numberFormatting";
import useFeatureFlags from "@modules/feature-flags/hooks/useFeatureFlags";
import { FeatureFlags } from "@modules/feature-flags/types/FeatureFlag";
import Link from "next/link";
import { useRouter } from "next/router";
import { Trans, useTranslation } from "react-i18next";

type WithValueOfAndAffirmProps = {
  valuePerMonth: number;
};

type WithPaymentTermProps = {
  payment: number;
  paymentTerm: number;
  downPayment: number;
};

type Props = WithValueOfAndAffirmProps & WithPaymentTermProps;

const WithPaymentTerm = ({
  payment,
  paymentTerm,
  downPayment,
}: WithPaymentTermProps) => {
  const { t } = useTranslation(["common", "purchase"]);
  const locale = useRouter().locale || "en";

  return (
    <div className="w-full rounded-md mb-2 bg-gray-25 p-6 pb-4 text-primary-deep">
      <div className="flex items-baseline mb-1 gap-x-1.5">
        <span className="text-center text-6xl font-semibold ">
          {formatCurrency(payment, locale, {
            showCents: "never",
          })}
        </span>
        <span className="text-2xl font-semibold">
          {t("purchase:per_month", { amount: "" })}
        </span>
      </div>
      <div className="caption-2 leading-5">
        {t("purchase:based_on_a_term", {
          term: paymentTerm,
          downPayment: formatCurrency(downPayment, locale, {
            showCents: "never",
          }),
        })}
      </div>
    </div>
  );
};

const WithValueOfAndAffirm = ({ valuePerMonth }: WithValueOfAndAffirmProps) => {
  const { t } = useTranslation(["common", "purchase"]);
  const locale = useRouter().locale || "en";

  return (
    <div className="w-full rounded-md mb-2 bg-gray-25 p-4">
      <div className="flex items-center mb-2">
        <Trans
          t={t}
          i18nKey="common:a_value_of"
          values={{
            value: formatCurrency(valuePerMonth, locale, {
              showCents: "never",
            }),
          }}
          components={{
            label: <span className="body-3 text-blueZodiac" />,
            bold: (
              <span className="ml-2 text-center text-4xl font-semibold leading-10" />
            ),
          }}
        />
      </div>
      <div className="caption-1 font-medium">
        <Trans
          t={t}
          i18nKey="purchase:affirm_payment_plans_are_available"
          components={{
            affirmLink: (
              <Link
                className="text-primary-bold underline"
                href="https://www.affirm.com/en-ca/"
                target="_blank"
              />
            ),
          }}
        />
      </div>
    </div>
  );
};

const CostPerMonthCoverage = ({
  valuePerMonth,
  payment,
  downPayment,
  paymentTerm,
}: Props) => {
  const { isEnabled } = useFeatureFlags();
  if (isEnabled(FeatureFlags.AftermarketMonthlyPayment)) {
    return (
      <WithPaymentTerm
        payment={payment}
        downPayment={downPayment}
        paymentTerm={paymentTerm}
      />
    );
  }

  return <WithValueOfAndAffirm valuePerMonth={valuePerMonth} />;
};

export default CostPerMonthCoverage;
