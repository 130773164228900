import { useNumberMask } from "@common/hooks/useMask";
import useBuyerInfoFromQuery from "@modules/query-string/hooks/useBuyerInfoFromQuery";
import useVehicleInfoFromQuery from "@modules/query-string/hooks/useVehicleInfoFromQuery";
import useSourceInfoFromQuery from "@modules/query-string/hooks/useSourceInfoFromQuery";
import { StartPurchaseRequest } from "../types/Purchase";

const useAftermarketPurchaseFromQuery = () => {
  const { unMaskPipe: unMaskNumberPipe } = useNumberMask();
  const vehicleInformation = useVehicleInfoFromQuery();
  const buyerInformation = useBuyerInfoFromQuery();
  const {
    sourceAdId,
    sourceAppId,
    sourceLocationId,
    sourceSessionId,
    sourceUserId,
    sourceCtaId,
    source,
  } = useSourceInfoFromQuery();

  const mileageValue = vehicleInformation.odometer
    ? unMaskNumberPipe(vehicleInformation.odometer)
    : null;

  return {
    vin: vehicleInformation.vin,
    year: vehicleInformation.year,
    make: vehicleInformation.make,
    model: vehicleInformation.model,
    trim: vehicleInformation.trim,
    mileage: mileageValue,
    purchaseDate: vehicleInformation.purchaseDate,
    purchasePrice: vehicleInformation.purchasePrice
      ? unMaskNumberPipe(vehicleInformation.purchasePrice)
      : null,
    firstName: buyerInformation.firstName,
    lastName: buyerInformation.lastName,
    email: buyerInformation.email,
    phone: buyerInformation.phoneNumber,
    address: buyerInformation.streetAddress,
    city: buyerInformation.city,
    province: buyerInformation.province?.abbr,
    postalCode: buyerInformation.postalCode,
    coverImage: vehicleInformation.coverImage,
    fuelType: vehicleInformation.fuelType,
    sourceAdId,
    sourceAppId,
    sourceLocationId,
    sourceSessionId,
    sourceUserId,
    sourceCtaId,
    source,
  } as StartPurchaseRequest;
};

export default useAftermarketPurchaseFromQuery;
