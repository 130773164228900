import { gtmPushData } from "@common/helpers/gtm";
import CarouselNavigation from "@common/components/CarouselNavigation";
import {
  StarOffIcon,
  StarOnIcon,
  AppGoogleIcon,
  AppFacebookIcon,
} from "@common/components/icons";
import { cleanHtml } from "@common/helpers/cleanHtml";
import classNames from "classnames";
import useEmblaCarousel from "embla-carousel-react";
import { useCallback, useEffect, useState } from "react";
import {
  CarouselContent,
  ReviewsCarouselLayout,
} from "../types/ReviewsCarouselLayout";
import GetBackgroundStyle from "../helpers/getBackgroundStyle";

const ReviewsCarousel = ({
  carouselTitle,
  carouselContentList,
  background,
}: ReviewsCarouselLayout) => {
  const [emblaRef, embla] = useEmblaCarousel({
    align: "start",
    active: carouselContentList ? carouselContentList.length > 1 : false,
  });
  const [selectedIndex, setSelectedIndex] = useState(0);

  const scrollPrev = useCallback(() => {
    gtmPushData({
      event: "Review",
      element: "Slider",
      descriptor: "Previous-Customer-Review",
    });
    return embla && embla.scrollPrev();
  }, [embla]);
  const scrollNext = useCallback(() => {
    gtmPushData({
      event: "Review",
      element: "Slider",
      descriptor: "Next-Customer-Review",
    });
    return embla && embla.scrollNext();
  }, [embla]);
  const scrollTo = useCallback(
    (index: number) => embla && embla.scrollTo(index),
    [embla]
  );

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on("select", onSelect);
  }, [embla, onSelect]);

  const renderStarRating = (content: CarouselContent) => {
    return Array.from(Array(5)).map((value, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <div className="inline" key={`star-rating-${i}`}>
        {i !== 4 && <StarOnIcon className="inline-block text-primary-bright" />}
        {content.reviewRating === "4 Stars" && i === 4 && (
          <StarOffIcon className="inline-block text-primary-bright" />
        )}
        {content.reviewRating === "5 Stars" && i === 4 && (
          <StarOnIcon className="inline-block text-primary-bright" />
        )}
      </div>
    ));
  };

  const renderReviewSource = (content: CarouselContent) => {
    if (content.reviewSource === "Google") {
      return <AppGoogleIcon className="text-primary-bright" />;
    }
    if (content.reviewSource === "Facebook") {
      return <AppFacebookIcon className="text-primary-bright" />;
    }

    return null;
  };

  if (carouselContentList == null || carouselContentList.length === 0) {
    return null;
  }

  return (
    <div
      id="cms-reviews-carousel"
      className="bg-white overflow-visible no-scrollbar relative bg-cover bg-center"
      style={GetBackgroundStyle(background)}
    >
      <div className="max-w-page mx-auto items-center gap-6 lg:gap-24 pt-10 pb-6 px-4 lg:px-5 lg:py-16">
        <div>
          <div className="items-center justify-center text-center pb-6">
            {carouselTitle && (
              <h3
                className="text-text-light-100 text-2xl lg:text-4xl lg:tracking-tight"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: cleanHtml(carouselTitle),
                }}
              />
            )}
          </div>

          <div className="items-center justify-center text-center">
            <div
              className="w-full overflow-hidden max-h-[28rem] md:max-h-72"
              ref={emblaRef}
            >
              <div className="flex select-none">
                {carouselContentList.map((content) => (
                  <div
                    className={classNames("relative min-w-full", {
                      "cursor-pointer": carouselContentList.length > 1,
                    })}
                    key={content.contentDetail}
                  >
                    <div className="overflow-hidden h-[28rem] sm:h-80 md:h-72 xl:h-64 p-6 md:p-12 bg-white rounded-2xl">
                      <h4 className="hidden sm:block text-text-light-100 leading-8">
                        {content.contentDetail}
                      </h4>
                      <h6 className="block sm:hidden text-text-light-100 text-ellipsis leading-8">
                        {content.contentDetail}
                      </h6>
                      <div className="my-4 inline-flex items-center justify-center">
                        <span className="text-text-light-200 body-2 font-semibold tracking-[0.125rem] leading-6 border-r-2 p-2">
                          {content.reviewerName}
                        </span>
                        <div className="pl-2 mb-2">
                          {renderStarRating(content)}
                        </div>
                        <div className="mx-2 mb-1">
                          {renderReviewSource(content)}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="items-center justify-center text-center my-9">
            {carouselContentList && (
              <CarouselNavigation
                buttonColours="secondary"
                contentList={carouselContentList}
                scrollNext={scrollNext}
                scrollPrev={scrollPrev}
                scrollTo={scrollTo}
                selectedIndex={selectedIndex}
                hiddenOnMobile
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewsCarousel;
