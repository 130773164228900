import { ChevronUpIcon } from "@common/components/icons";
import { cleanParagraphTags } from "@common/helpers/cleanHtml";
import { Disclosure, Transition } from "@headlessui/react";
import classNames from "classnames";
import { FaqLayout } from "../types/FaqLayout";
import GetBackgroundStyle from "../helpers/getBackgroundStyle";

const Faq = ({ faqLabel, faqSections, background }: FaqLayout) => {
  return (
    <div
      id="cms-frequently-asked-questions"
      className="bg-white overflow-visible no-scrollbar relative bg-cover bg-center"
      style={GetBackgroundStyle(background)}
    >
      <div
        className="max-w-page mx-auto items-center gap-6 lg:gap-24 pt-10 pb-6 px-4 lg:px-5 lg:py-16"
        itemScope
        itemType="https://schema.org/FAQPage"
      >
        <div className="items-center justify-center text-center pb-6">
          {faqLabel && (
            <h3 className="text-text-light-100 text-2xl lg:text-4xl lg:tracking-tight">
              {faqLabel}
            </h3>
          )}
        </div>
        {faqSections && faqSections.length > 0 && (
          <div>
            {faqSections.map((section, index) => {
              return (
                <div
                  itemScope
                  itemProp="mainEntity"
                  itemType="https://schema.org/Question"
                  tabIndex={index}
                  key={`${section.faqSectionHeading}-container`}
                  className="pb-4"
                >
                  <Disclosure>
                    {({ open }) => (
                      <div
                        key={section.faqSectionHeading}
                        className="bg-white rounded-lg"
                      >
                        <Disclosure.Button
                          className={classNames(
                            "flex w-full justify-between bg-white text-left px-6 py-4 focus:outline-none rounded-lg",
                            { "rounded-b-none": open }
                          )}
                        >
                          <h5
                            itemProp="name"
                            className={classNames("inline-block", {
                              "text-primary-bold": open,
                            })}
                          >
                            {section.faqSectionHeading}
                          </h5>
                          <ChevronUpIcon
                            className={`${
                              open
                                ? "text-persianBlue"
                                : "rotate-180 transform text-blueZodiac"
                            } h-6 w-6 `}
                          />
                        </Disclosure.Button>
                        <Transition
                          unmount={false}
                          enter="transition duration-100 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          <Disclosure.Panel
                            unmount={false}
                            className="px-6 text-md bg-white focus:outline-none rounded-b-lg"
                          >
                            <div
                              itemScope
                              itemProp="acceptedAnswer"
                              itemType="https://schema.org/Answer"
                            >
                              <div
                                itemProp="text"
                                key={`${section.faqSectionHeading}-content`}
                                className="pb-2 [&>ul]:list-disc [&>ul]:list-inside"
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                  __html: cleanParagraphTags(
                                    section.faqSectionContent
                                  ),
                                }}
                              />
                            </div>
                          </Disclosure.Panel>
                        </Transition>
                      </div>
                    )}
                  </Disclosure>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Faq;
