enum BodyStyle {
  Sedan = 1,
  Coupe = 2,
  Hatchback = 3,
  SUV = 4,
  Minivan = 5,
  Truck = 6,
  Unassigned = 7,
  Wagon = 8,
  Convertible = 9,
}

export default BodyStyle;
