import CloudflareImagesImage from "@common/components/CloudflareImagesImage";
import ArrowThickRightIcon from "@common/components/icons/ArrowThickRightIcon";
import { cleanParagraphTags } from "@common/helpers/cleanHtml";
import classNames from "classnames";
import React, { useState } from "react";
import ActionButton from "../enum/ActionButtonEnum";
import { MultiStepLayout } from "../types/MultiStepLayout";
import CmsButton from "./CmsButton";
import CmsButtonModalContainer from "./ButtonModalContainer";
import GetBackgroundStyle from "../helpers/getBackgroundStyle";

const MultiStepSection = ({
  sectionTitle,
  stepLayout,
  button,
  background,
  htmlIdRef,
}: MultiStepLayout) => {
  const [buttonModalState, setButtonModalState] = useState<{
    show: boolean;
    actionType: ActionButton;
  }>({ show: false, actionType: ActionButton.UnAssigned });

  return (
    <div
      id="cms-multi-step-section"
      className="bg-white overflow-visible no-scrollbar relative bg-cover bg-center"
      style={GetBackgroundStyle(background)}
    >
      <div className="max-w-page mx-auto pt-10 pb-6 px-4 lg:px-5 lg:py-16">
        <article>
          <h3
            className="text-primary-deep text-center text-2xl leading-8 lg:text-4xl lg:leading-[3rem] font-semibold"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: cleanParagraphTags(sectionTitle),
            }}
          />
        </article>
        <div className="flex flex-col  xl:flex-row flex-nowrap content-evenly xl:gap-5 pb-4 pt-12 text-center">
          {stepLayout.map((step, index) => (
            <React.Fragment key={step.stepLabel}>
              <div
                key={step.stepLabel}
                className={classNames(
                  "group flex flex-row xl:flex-col flex-1 min-w-0 items-center mx-auto rounded-lg w-full md:w-80 h-32 lg:h-auto text-text-light-100 transition",
                  {
                    "xl:pt-20": index === 0 || index === 2 || index === 4,
                    "xl:pt-6": index === 1,
                    "xl:pt-[7.5rem]": index === 3,
                  }
                )}
              >
                <div
                  key={step.stepSubLabel}
                  className="flex text-gray-500 transition ease-in-out duration-300"
                >
                  {step?.stepIcon?.sourceUrl && (
                    <>
                      <div
                        key={`${step.stepLabel}-image`}
                        className="relative h-[4.47rem] w-[4.5rem] xl:h-[8.78rem] xl:w-[8.78rem] mt-2"
                      >
                        <CloudflareImagesImage
                          src={step.stepIcon.sourceUrl}
                          alt={step.stepIcon.altText}
                          fill
                          className="items-center justify-center text-center"
                        />
                      </div>
                      {stepLayout.length === 5 && (
                        <div
                          key={`${step.stepLabel}-rightIcon-xl-5`}
                          className={classNames(
                            "hidden absolute text-gray-100",
                            {
                              "xl:block -rotate-[21deg] pl-[10rem] pt-10":
                                index === 0,
                              "xl:block rotate-[21deg] pl-[11rem] pt-10":
                                index === 1,
                              "xl:block rotate-[19deg] pl-[11rem] pt-14":
                                index === 2,
                              "xl:block -rotate-[19deg] pl-[10rem] pt-14":
                                index === 3,
                            }
                          )}
                        >
                          <ArrowThickRightIcon width={52} height={52} />
                        </div>
                      )}
                      {stepLayout.length === 4 && (
                        <div
                          key={`${step.stepLabel}-rightIcon-xl-4`}
                          className={classNames(
                            "hidden absolute text-gray-100",
                            {
                              "xl:block -rotate-[21deg] pl-[12rem] pt-7":
                                index === 0,
                              "xl:block rotate-[21deg] pl-[12rem] pt-14":
                                index === 1,
                              "xl:block rotate-[13deg] pl-[12rem] pt-16":
                                index === 2,
                            }
                          )}
                        >
                          <ArrowThickRightIcon width={65} height={65} />
                        </div>
                      )}
                      {stepLayout.length === 3 && (
                        <div
                          key={`${step.stepLabel}-rightIcon-xl-3`}
                          className={classNames(
                            "hidden absolute text-gray-100",
                            {
                              "xl:block -rotate-[21deg] pl-[14rem] pt-7":
                                index === 0,
                              "xl:block rotate-[21deg] pl-[15rem] pt-8":
                                index === 1,
                            }
                          )}
                        >
                          <ArrowThickRightIcon width={85} height={85} />
                        </div>
                      )}
                    </>
                  )}
                </div>

                <div
                  key={step.stepLabel + step.stepSubLabel}
                  className="flex flex-col justify-center xl:hidden text-left px-3"
                >
                  <p
                    className="body-1"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: cleanParagraphTags(step.stepLabel),
                    }}
                  />
                  <p
                    className="pt-2 body-4"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: cleanParagraphTags(step.stepSubLabel),
                    }}
                  />
                </div>

                <h5
                  className="hidden xl:block pt-8 transition ease-in-out duration-300"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: cleanParagraphTags(step.stepLabel),
                  }}
                />
                <p
                  className="hidden xl:block pt-3 pb-8 body-4"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: cleanParagraphTags(step.stepSubLabel),
                  }}
                />
              </div>
              <div
                key={`${step.stepLabel}-rightIcon`}
                className="last:hidden xl:hidden text-gray-100 mx-auto my-3 rotate-90"
              >
                <ArrowThickRightIcon width={50} height={25} />
              </div>
            </React.Fragment>
          ))}
        </div>
        <div className="flex justify-center">
          {!button.isHidden && (
            <div className="w-56 pt-8 xl:pt-0">
              <CmsButton
                className="w-full"
                button={button}
                setButtonModalState={setButtonModalState}
                htmlIdRef={htmlIdRef}
              />
            </div>
          )}
        </div>
      </div>
      {buttonModalState.show && (
        <CmsButtonModalContainer buttonModalState={buttonModalState} />
      )}
    </div>
  );
};

export default MultiStepSection;
