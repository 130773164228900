import api from "@modules/api/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import purchaseQueryKeys from "../purchaseQueryKeys";
import { StartPurchaseRequest } from "../types/Purchase";

const postStartConsumerFinancing = async (req: StartPurchaseRequest) => {
  return api.rawPost(`api/purchases/start-consumer-financing`, {
    json: req,
  });
};

export default function useStartConsumerFinancingMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postStartConsumerFinancing,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: purchaseQueryKeys.activeFinancingPurchase(),
      });
    },
  });
}
