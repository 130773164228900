import { cleanHtml } from "@common/helpers/cleanHtml";
import { ContentHeaderLayout } from "../types/ContentHeaderLayout";

const ContentHeader = ({ header, headerSize }: ContentHeaderLayout) => {
  const renderContentHeader = () => {
    switch (headerSize) {
      case "h1":
        return (
          <h3
            className="text-text-light-100 text-2xl lg:text-4xl lg:tracking-tight"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: cleanHtml(header),
            }}
          />
        );
      case "h2":
        return (
          <h2
            className="text-text-light-100"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: cleanHtml(header),
            }}
          />
        );
      case "h3":
        return (
          <h3
            className="text-text-light-100"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: cleanHtml(header),
            }}
          />
        );
      case "h4":
        return (
          <h4
            className="text-text-light-100"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: cleanHtml(header),
            }}
          />
        );
      default:
        throw Error(`Header Size: (${headerSize}) not supported`);
    }
  };

  if (!header) {
    return null;
  }

  return (
    <div className="max-w-page mx-auto items-center gap-6 lg:gap-24 py-2">
      {renderContentHeader()}
    </div>
  );
};

export default ContentHeader;
