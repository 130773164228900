type Props<TLeft extends string, TRight extends string> = {
  leftLabel: string;
  rightLabel?: string;
  leftValue: TLeft;
  rightValue?: TRight;
  value?: TLeft | TRight;
  onChange?(selected: TLeft | TRight): void;
};

const SlideToggle = <TLeft extends string, TRight extends string>({
  leftLabel,
  rightLabel,
  leftValue,
  rightValue,
  value,
  onChange,
}: Props<TLeft, TRight>) => {
  const isSingleToggle = !rightLabel || !rightValue;

  if (isSingleToggle) {
    return (
      <div className="flex items-center bg-gray-50 rounded-full px-1 text-center relative h-10 w-1/2 overflow-hidden lg:mb-6">
        <div className="relative py-1 h-8 block select-none w-full before:bg-white before:shadow-[0_3px_14px_rgba(41,62,221,0.17)] before:h-full before:w-full before:absolute before:left-0 before:top-0 before:rounded-full before:duration-200" />

        <span className="body-3 absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center pointer-events-none text-primary-bold">
          {leftLabel}
        </span>
      </div>
    );
  }
  return (
    <div className="h-full min-h-[2rem] py-1 sm:flex items-center bg-gray-50 rounded-full pl-1 text-center relative pr-[50%] w-full overflow-hidden mb-6">
      <input
        className="peer cursor-pointer absolute top-0 left-0 bottom-0 w-full h-full opacity-0 z-0"
        type="checkbox"
        checked={value === rightValue}
        onChange={(e) => onChange?.(e.target.checked ? rightValue : leftValue)}
        aria-label={`Click to toggle between ${leftLabel} and ${rightLabel}`}
      />
      <div className="p-4 flex-1 relative h-full min-h-[1.75rem] select-none pointer-events-none peer-checked:before:translate-x-full peer-checked:before:duration-200 peer-checked:before:ease-linear before:bg-white before:shadow-[0_3px_14px_rgba(41,62,221,0.17)] before:h-full before:w-full before:absolute before:left-0 before:top-0 before:rounded-full before:translate-x-0 before:duration-200" />

      <span className="p-2 body-3 absolute top-0 bottom-0 left-0 w-1/2 flex items-center justify-center pointer-events-none text-primary-bold peer-checked:text-text-light-200">
        {leftLabel}
      </span>

      <span className="body-3 absolute top-0 bottom-0 right-0 w-1/2 flex items-center justify-center pointer-events-none text-text-light-200 peer-checked:text-primary-bold">
        {rightLabel}
      </span>
    </div>
  );
};

export default SlideToggle;
