import classNames from "classnames";
import DotButton from "./DotButton";
import { ChevronLeftIcon, ChevronRightIcon } from "./icons";

type CarouselNavigationProps = {
  buttonColours?: "primary" | "secondary";
  contentList: unknown[] | [];
  scrollNext: () => void | undefined;
  scrollPrev: () => void | undefined;
  scrollTo: (index: number) => void | undefined;
  selectedIndex: number;
  hiddenOnMobile: boolean;
  showArrows?: boolean;
};

const CarouselNavigation = ({
  buttonColours = "primary",
  contentList,
  scrollNext,
  scrollPrev,
  scrollTo,
  selectedIndex = 0,
  hiddenOnMobile,
  showArrows = true,
}: CarouselNavigationProps) => {
  if (contentList && contentList.length <= 1) {
    return null;
  }

  return (
    <div className="inline-flex items-center justify-center gap-x-3 w-full">
      <div
        role="presentation"
        className={classNames(
          "bg-gray-50  inline-flex items-center justify-center rounded-full h-10 w-10 p-1 mx-4 cursor-pointer",
          {
            "hidden md:inline-flex": hiddenOnMobile,
            hidden: !showArrows,
          }
        )}
        onClick={scrollPrev}
      >
        <ChevronLeftIcon
          className={classNames("h-4 w-4", {
            "cursor-pointer": contentList.length > 1,
          })}
        />
      </div>

      {contentList.map((content, index) => (
        <DotButton
          selected={index === selectedIndex}
          onClick={() => scrollTo(index)}
          buttonColours={buttonColours}
          // eslint-disable-next-line react/no-array-index-key
          key={`dot-button-${index}`}
        />
      ))}

      <div
        role="presentation"
        className={classNames(
          "bg-gray-50 inline-flex items-center justify-center rounded-full h-10 w-10 p-1 mx-4 cursor-pointer",
          {
            "hidden md:inline-flex": hiddenOnMobile,
            hidden: !showArrows,
          }
        )}
        onClick={scrollNext}
      >
        <ChevronRightIcon
          className={classNames("h-4 w-4", {
            "cursor-pointer": contentList.length > 1,
          })}
        />
      </div>
    </div>
  );
};

export default CarouselNavigation;
