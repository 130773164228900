interface Source {
  GivenSource: string;
  EmbeddedSource: string;
}

const knownEmbedSourcesToAlter: Source[] = [
  {
    GivenSource: "https://youtu.be/",
    EmbeddedSource: "https://www.youtube.com/embed/",
  },
];

export const ReplaceEmbedSources = (source: string) => {
  let updatedSource = source;

  knownEmbedSourcesToAlter.forEach((value) => {
    updatedSource = updatedSource?.replace(
      value.GivenSource,
      value.EmbeddedSource
    );
  });

  return updatedSource;
};

export default {};
